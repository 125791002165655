import { postConfirmationMail } from "@/api/mail";
import { sendNotification } from "@/api/tv-message";
import axios from "axios";

export default {
  postConfirmationmail(
    { commit, dispatch, rootState },
    {
      sender,
      content,
      subject,
      emailTo,
      room = rootState.session.mode === "demo" ? "demo" : undefined,
    }
  ) {
    commit("POST_MAIL");
    try {
      postConfirmationMail({ sender, content, subject, emailTo });
      commit("SUCCESS_POST_MAIL");
    } catch (e) {
      commit("FAILURE_POST_MAIL");
    }

    dispatch("postConfirmationTvMessage", { content, room });
  },
  async postConfirmationTvMessage({ commit }, { content, room }) {
    commit("POST_TV_MESSAGE");
    try {
      commit(
        "SUCCESS_POST_TV_MESSAGE",
        await sendNotification({ message: content, room: room })
      );
    } catch (e) {
      commit("FAILURE_POST_TV_MESSAGE");
    }
  },
};
