export default {
  setVideoUrl: function ({ dispatch, commit, state }, { url }) {
    if (state.url !== url) {
      dispatch("setResumeTime", { time: 0 });
    }
    commit("SET_VIDEO_URL", url);
  },
  setPlayState: function ({ commit }, { state }) {
    commit("SET_PLAY_STATE", state);
  },
  setResumeTime: function ({ commit }, { time }) {
    commit("SET_RESUME_TIME", time);
  },
};
