import Vue from "vue";

export function getChannels(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getTVChannels",
      module: "SQMOD_LiveTV",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root.channels);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-tv", "Failed to fetch channels", error);
      },
    });
  });
}

export function addToFavourites(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "addFavouriteTVChannel",
      module: "SQMOD_LiveTV",
      data: { ...options },
      onMessage: (data) => {
        resolve(data.payload.root);
      },
      onError: (error) => {
        reject(error);
        Vue.logger.error(
          "api-tv",
          "Failed to add Channel to Favourites",
          error
        );
      },
    });
  });
}

export function removeFromFavourites(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "delFavouriteTVChannel",
      module: "SQMOD_LiveTV",
      data: { ...options },
      onMessage: (data) => {
        resolve(data.payload.root);
      },
      onError: (error) => {
        reject(error);
        Vue.logger.error(
          "api-tv",
          "Failed to remove Channel from Favourites",
          error
        );
      },
    });
  });
}
