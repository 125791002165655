export default () => ({
  allMovies: [],
  statusMovies: undefined,
  isFetchingMovies: false,
  filterList: [],
  onlyFreeMovies: false,
  showFilter: false,
  allGenres: [],
  statusGenres: undefined,
  isFetchingGenres: false,
  allPackages: [],
  statusPackages: undefined,
  isFetchingPackages: false,
  isFetchingPackageMovies: false,
  statusPackageMovies: undefined,
  moviesOfPackage: [],
  statusMovieDetails: undefined,
  isFetchingMovieDetails: false,
  movieDetails: {},
  statusBuyProcess: undefined,
  statusResumeTime: undefined,
  statusVouchers: undefined,
  vouchers: [],
  statusPackageById: undefined,
  packageById: {},
  buyDialogState: false,
});
