import isFunction from "lodash/isFunction";

export default {
  closeDialog({ state, commit }) {
    if (isFunction(state.onClose)) {
      state.onClose();
    }
    commit("SET_ACTIVE", false);
    commit("RESET");
  },
  openDialog(
    { commit },
    {
      title = "",
      text = "",
      confirmation = false,
      confirmationFn = function () {},
      onClose = function () {},
      actions = [],
    }
  ) {
    commit("SET_ACTIVE", true);
    commit("SET_TITLE", title);
    commit("SET_TEXT", text);
    commit("SET_ACTIONS", actions);
    commit("SET_CONFIRMATION", confirmation);
    commit("SET_CONFIRMATION_FN", confirmationFn);
    commit("SET_ON_CLOSE", onClose);
  },
};
