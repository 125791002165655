import {
  getStations,
  getFavourites,
  addFavourite,
  removeFavourite,
  playRadioChannel,
} from "@/api/webradio";
import countries from "i18n-iso-countries";

export default {
  setStations({ commit }, value) {
    commit("SET_STATIONS", value);
  },
  async getItems({ commit, dispatch, rootState }) {
    commit("REQUEST_CHANNELS");
    commit("SET_LOADING", true, { root: true });
    try {
      let stations = await getStations();
      stations.countries = stations.countries.map((item) => {
        item.i18nName =
          countries.getName(item.iso2 || item.iso3, rootState.language.key) ||
          item.name;
        return item;
      });
      commit("SUCCESS_REQUEST_CHANNELS", stations);
      dispatch("getFavorites");
    } catch (e) {
      commit("FAILURE_REQUEST_CHANNELS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getFavorites({ commit }) {
    commit("REQUEST_FAVORITES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_FAVORITES", await getFavourites());
    } catch (e) {
      commit("FAILURE_REQUEST_FAVORITES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async addFavorite({ dispatch, commit }, { stationID }) {
    try {
      await addFavourite({ stationID });
      dispatch("getItems");
      dispatch("getFavorites");
    } catch (e) {
      commit("FAILURE_REQUEST_FAVORITES");
    }
  },
  async removeFavorite({ dispatch, commit }, { stationID }) {
    try {
      await removeFavourite({ stationID });
      dispatch("getItems");
      dispatch("getFavorites");
    } catch (e) {
      commit("FAILURE_REQUEST_FAVORITES");
    }
  },
  async playRadioChannel(
    { commit },
    { room_ip_id = "", item_id, category_id = "0" }
  ) {
    commit("PLAY_CHANNEL");
    try {
      commit(
        "SUCCESS_PLAY_CHANNEL",
        await playRadioChannel({ room_ip_id, item_id, category_id })
      );
    } catch (e) {
      commit("FAILURE_PLAY_CHANNEL");
    }
  },
};
