// TODO: add on screen debug console/window
import Vue from "vue";
import Logger from "js-logger";
import each from "lodash/each";
import isEmpty from "lodash/isEmpty";
export default new Vue({
  data: function () {
    return {
      logToServer: false,
      logToGui: false,
      logList: [],
    };
  },
  created: function () {
    var _this = this;
    window.onerror = function () {
      _this.$logger.error("logger", [].slice.call(arguments).join(" "));
      // TODO: show error Ui bar, if ui-debug enabled
    };
  },
  methods: {
    install: function () {
      const _this = this;
      Object.defineProperty(Vue.prototype, "$logger", {
        get() {
          return _this;
        },
      });
      Vue.logger = this;
    },
    init: function () {
      return new Promise((resolve, reject) => {
        const _this = this;
        Logger.useDefaults({
          defaultLevel: Logger.INFO,
          formatter: function (messages, context) {
            messages.unshift(new Date().toUTCString());
          },
        });
        var defaultHandler = Logger.createDefaultHandler();
        Logger.setHandler(function (messages, context) {
          defaultHandler(messages, context);
          _this.logToServerHandler(messages, context);
        });
        window.onerror = (message, filename, lineno) => {
          var msg = message;
          if (filename) {
            msg.push(", file: " + filename);
          }

          if (lineno) {
            msg.push(":" + lineno);
          }

          this.$http.post(
            "http://" +
              Vue.http.options.root +
              "/ONEapp/api/php_helpers/log.php",
            {
              lvl: "ONERROR",
              msg: [].slice.call(messages).join(" "),
              component: "WINDOW",
            }
          );
        };
        if (_this.$config.debug) {
          this.logToServer = _this.$config.log_to_server_error_log === true;
          this.logToGui = _this.$config.log_to_debug_console === true;
          switch (_this.$config.debug_level) {
            case "10":
              Logger.setLevel(Logger.ERROR);
              break;
            case "20":
              Logger.setLevel(Logger.WARN);
              break;
            case "30":
              Logger.setLevel(Logger.TIME);
              break;
            case "40":
              Logger.setLevel(Logger.INFO);
              break;
            case "50":
              Logger.setLevel(Logger.DEBUG);
              break;
            default:
              Logger.setLevel(Logger.OFF);
              break;
          }
        } else {
          Logger.setLevel(Logger.OFF);
        }
        // log every message before logger-init
        if (!isEmpty(this.logList)) {
          var logList = JSON.parse(JSON.stringify(this.logList));
          each(logList, function (el) {
            switch (el.level.name) {
              case Logger.ERROR.name:
                _this.$logger.error(el.message);
                break;
              case Logger.WARN.name:
                _this.$logger.warn(el.message);
                break;
              case Logger.INFO.name:
                _this.$logger.info(el.message);
                break;
              case Logger.DEBUG.name:
                _this.$logger.debug(el.message);
                break;
            }
          });
        }
        resolve();
      });
    },
    initLogger: function () {},
    logToServerHandler: function (messages, context) {
      if (
        this.logToServer &&
        context.level.value >= context.filterLevel.value
      ) {
        this.$http.post(
          "http://" + Vue.http.options.root + "/ONEapp/api/php_helpers/log.php",
          {
            lvl: context.level.name,
            msg: [].slice.call(messages).join(" "),
            component: "",
          }
        );
      }
    },
    error: function () {
      this.logList.push({
        level: Logger.ERROR,
        message: [].slice.call(arguments).join(" "),
      });
      Logger.error.apply(this, arguments);
    },
    warn: function () {
      this.logList.push({
        level: Logger.WARN,
        message: [].slice.call(arguments).join(" "),
      });
      Logger.warn.apply(this, arguments);
    },
    info: function () {
      this.logList.push({
        level: Logger.INFO,
        message: [].slice.call(arguments).join(" "),
      });
      Logger.info.apply(this, arguments);
    },
    debug: function () {
      this.logList.push({
        level: Logger.DEBUG,
        message: [].slice.call(arguments).join(" "),
      });
      Logger.debug.apply(this, arguments);
    },
    time: function (timeLabel) {
      Logger.time(timeLabel);
    },
    timeEnd: function (timeLabel) {
      Logger.timeEnd(timeLabel);
    },
  },
});
