import Vue from "vue";

export function getMovies(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getMovies",
      module: "SQMOD_VideoOnDemand",
      data: { ...options, content_type: "movie" },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-vod", "Failed to fetch vod movies", error);
      },
    });
  });
}

export function getGenres(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getGenres",
      module: "SQMOD_VideoOnDemand",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-vod", "Failed to fetch vod genres", error);
      },
    });
  });
}

export function getPackages(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getPackages",
      module: "SQMOD_VideoOnDemand",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-vod", "Failed to fetch vod packages", error);
      },
    });
  });
}

export function buyMovieOrPackage(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getBuyMovie",
      module: "SQMOD_VideoOnDemand",
      data: { ...options },
      onMessage: function (data) {
        if (data.payload.status === "OK") {
          resolve(data.payload.root);
        } else {
          reject(data);
        }
      },
      onFailure: function (error) {
        reject(error);
        Vue.logger.error("api-vod", "Failed to buy package", error);
      },
    });
  });
}

export function addResumeTime(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "addResumeTime",
      module: "SQMOD_VideoOnDemand",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onFailure: function (error) {
        reject(error);
        Vue.logger.error("api-vod", "Failed to add resume time", error);
      },
    });
  });
}

export function getVouchers(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getCurrentVoucher",
      module: "SQMOD_VideoOnDemand",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onFailure: function (error) {
        reject(error);
        Vue.logger.error("api-vod", "Failed to get vouchers", error);
      },
    });
  });
}
