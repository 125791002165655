import chunk from "lodash/chunk";
import Vue from "vue";
import filter from "lodash/filter";
import findIndex from "lodash/findIndex";

export default {
  pagingList: (state) => {
    return chunk(state.resultList, 5);
  },
  items: (state) => {
    return state.result[state.type] || [];
  },
  tvItems: (state, getters) => {
    return filter(state.allTvItems, (item) => {
      return findIndex(getters.items, { id: item.id.toString() }) > -1;
    });
  },
  totalTypeCount: (state, getters) => {
    let count = 0;
    getters.types.forEach((item) => {
      count += item.count;
    });
    return count;
  },
  types: (state) => {
    return [
      {
        text: Vue.i18n.translate("search.types.contentpage"),
        type: "contentpage",
        count: state.result.contentpage ? state.result.contentpage.length : 0,
      },
      {
        text: Vue.i18n.translate("search.types.film"),
        type: "film",
        count: state.result.film ? state.result.film.length : 0,
      },
      {
        text: Vue.i18n.translate("search.types.tv"),
        type: "tv",
        count: state.result.tv ? state.result.tv.length : 0,
      },
      /*{
        text: Vue.i18n.translate("search.types.epg"),
        type: "epg",
        count: state.result.epg ? state.result.epg.length : 0
      },*/
      {
        text: Vue.i18n.translate("search.types.radio"),
        type: "radio",
        count: state.result.radio ? state.result.radio.length : 0,
      },
      /* not implemented in ONEsquareX yet
        {
          text: Vue.i18n.translate("search.types.music_song"),
          type: "music_song",
          count: state.result.music_song ? state.result.music_song.length : 0
        },
        {
          text: Vue.i18n.translate("search.types.music_album"),
          type: "music_album",
          count: state.result.music_album ? state.result.music_album.length : 0
        },
        {
          text: Vue.i18n.translate("search.types.music_artist"),
          type: "music_artist",
          count: state.result.music_artist
            ? state.result.music_artist.length
            : 0
        },*/
    ];
  },
};
