import find from "lodash/find";
import map from "lodash/map";
import uniqBy from "lodash/uniqBy";

export default {
  REQUEST_CHANNELS(state) {
    state.statusChannels = "fetching";
  },
  SUCCESS_REQUEST_CHANNELS(state, value) {
    state.allChannels = value;
    state.statusChannels = "success";
  },
  FAILURE_REQUEST_CHANNELS(state) {
    state.allChannels = [];
    state.statusChannels = "failure";
  },
  REQUEST_EPG_DATA(state, { item_id }) {
    item_id = parseInt(item_id);
    find(state.allChannels, { id: item_id }).isFetchingEvents = true;
  },
  SUCCESS_REQUEST_EPG_DATA(state, { item_id, value, start_time, end_time }) {
    item_id = parseInt(item_id);
    const channel = find(state.allChannels, { id: item_id });
    channel.events = value.events;
    channel.isFetchingEvents = false;
    channel.fetchedStartTime = start_time;
    channel.fetchedEndTime = end_time;
    state.availableDates = uniqBy(
      map(channel.events, (v) => {
        return { startDate: v.startDate, id: v.id };
      }),
      "startDate"
    );
  },
  FAILURE_REQUEST_EPG_DATA(state, { item_id }) {
    state.availableDates = [];
    item_id = parseInt(item_id);
    const channel = find(state.allChannels, { id: item_id });
    channel.events = [];
    channel.isFetchingEvents = false;
    channel.fetchedStartTime = 0;
    channel.fetchedEndTime = 0;
  },
  REQUEST_EPG_DATA_FROM_NOW(state, { item_id }) {
    item_id = parseInt(item_id);
    const channel = find(state.allChannels, {
      id: item_id,
    });
    channel.isFetchingCurrentEvent = true;
    channel.status = "fetching";
  },
  SUCCESS_REQUEST_EPG_DATA_FROM_NOW(state, { item_id, value }) {
    item_id = parseInt(item_id);
    const channel = find(state.allChannels, { id: item_id });
    channel.currentEvent =
      value.events && value.events.length > 0 ? value.events[0] : null;
    channel.status = "success";
    channel.isFetchingCurrentEvent = false;
  },
  FAILURE_REQUEST_EPG_DATA_FROM_NOW(state, { item_id }) {
    item_id = parseInt(item_id);
    const channel = find(state.allChannels, { id: item_id });
    channel.currentEvent = null;
    channel.status = "failure";
    channel.isFetchingCurrentEvent = false;
  },
  RESET_EPG_EVENTS_DATA(state) {
    state.allChannels.map((item) => {
      item.events = [];
      item.isFetchingEvents = false;
      item.fetchedStartTime = 0;
      item.fetchedEndTime = 0;
      item.isFetchingCurrentEvent = false;
      item.status = null;
      item.currentEvent = null;
    });
  },
  SET_LIMIT(state, value) {
    state.limit = parseInt(value);
  },
  SET_TIMEZONE(state, value) {
    state.timezone = value;
  },
  PLAY_CHANNEL(state) {
    state.playChannel = "fetching";
  },
  SUCCESS_PLAY_CHANNEL(state) {
    state.playChannel = "success";
  },
  FAILURE_PLAY_CHANNEL(state) {
    state.playChannel = "failure";
  },
};
