export default {
  RESET(state) {
    state.active = false;
    state.title = "";
    state.text = "";
    state.confirmation = false;
    state.confirmationFn = function () {};
    state.onClose = function () {};
    state.actions = [];
  },
  SET_ACTIVE(state, value) {
    state.active = value;
  },
  SET_TITLE(state, value) {
    state.title = value;
  },
  SET_TEXT(state, value) {
    state.text = value;
  },
  SET_ACTIONS(state, value) {
    state.actions = value;
  },
  SET_CONFIRMATION(state, value) {
    state.confirmation = value;
  },
  SET_CONFIRMATION_FN(state, value) {
    state.confirmationFn = value;
  },
  SET_ON_CLOSE(state, value) {
    state.onClose = value;
  },
};
