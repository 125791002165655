<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <v-card>
      <v-card-title class="headline">{{ title }}</v-card-title>
      <v-card-text>{{ text }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :key="action.text"
          :to="action.to"
          color="primary"
          text
          @click="dialog = false"
          v-for="action in actions"
        >
          {{ action.text }}
        </v-btn>
        <v-btn
          v-if="confirmation"
          @click="
            confirmationFn();
            dialog = false;
          "
          color="primary"
          text
          >{{ $t("general.ok") }}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState } from "vuex";
export default {
  methods: {
    ...mapActions("dialog", ["closeDialog"]),
  },
  computed: {
    ...mapState({
      title: ({ dialog }) => dialog.title,
      text: ({ dialog }) => dialog.text,
      actions: ({ dialog }) => dialog.actions,
      confirmation: ({ dialog }) => dialog.confirmation,
      confirmationFn: ({ dialog }) => dialog.confirmationFn,
    }),
    dialog: {
      get() {
        return this.$store.state.dialog.active;
      },
      set(val) {
        if (!val) {
          this.closeDialog();
        }
      },
    },
  },
};
</script>
