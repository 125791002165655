export default () => ({
  status,
  canceled: false,
  messageStatus: "",
  showSchedule: false,
  weekdays: [],
  temp: {},
  fanspeed: {},
  light: {},
  marquise: {},
  additionalControls: false,
  items: [],
  statusGet: undefined,
  statusSet: undefined,
});
