import Color from "color";
import trim from "lodash/trim";

export default {
  REQUEST_THEME(state) {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_THEME(state, payload) {
    state.status = "success";
    state.theme = payload.body;
    if (state.theme.toolbarBackground) {
      state.theme.toolbarDark = Color(
        trim(state.theme.toolbarBackground)
      ).isDark();
    }
  },
  FAILURE_REQUEST_THEME(state) {
    state.status = "failure";
  },
};
