import Vue from "vue";

export function getTvMessages(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "loadTvMessages",
      module: "SQMOD_Tv_Messages",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger("api-tvmessages", "failed loading tv-messages", error);
      },
    });
  });
}

export function mark(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "markAsTvMessagesByMessageId",
      module: "SQMOD_Tv_Messages",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger("api-tvmessages", "failed to mark message", error);
      },
    });
  });
}

export function deleteMessage(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "deleteTvMessages",
      module: "SQMOD_Tv_Messages",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger("api-tvmessages", "Deleting message failed", error);
      },
    });
  });
}

export function sendNotification(options) {
  // changed from sendCancelCleanup()
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "sendTvMessage",
      module: "SQMOD_Tv_Messages",
      data: { ...options },
      onMessage: (data) => {
        resolve(data.payload.root);
      },
      onError: (error) => {
        reject(error);
        Vue.logger("api-tvmessages", "Sending notification failed", error);
      },
    });
  });
}
