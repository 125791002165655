import Vue from "vue";

export function getRoomsByRoomnumber(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getRoomsByRoomnumber",
      module: "SQMOD_RoomInformation",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
      },
    });
  });
}

export function getPowerStatus({ room_id }) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getPowerStatus",
      module: "SQMOD_RoomInformation",
      data: {
        room_id,
      },
      onMessage: (data) => {
        resolve(data.payload.root);
      },
      onError: (error) => {
        reject(error);
      },
    });
  });
}
