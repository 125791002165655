import { getArrivals, getDepartures, getAirports } from "@/api/flight-info";

export default {
  async getArrivals({ commit, state }) {
    commit("REQUEST_ARRIVALS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_ARRIVALS",
        await getArrivals({
          options: {
            airport_id: state.selectedAirport.id,
            term: state.searchTerm || "",
            offset: state.limit,
            start: (state.page - 1) * state.limit,
          },
        })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_ARRIVALS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getDepartures({ commit, state }) {
    commit("REQUEST_DEPARTURES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_DEPARTURES",
        await getDepartures({
          options: {
            airport_id: state.selectedAirport.id,
            term: state.searchTerm || "",
            offset: state.limit,
            start: (state.page - 1) * state.limit,
          },
        })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_DEPARTURES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async getAirports({ commit, dispatch }) {
    commit("REQUEST_AIRPORTS");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_AIRPORTS", await getAirports());
      dispatch("getDepartures");
      dispatch("getArrivals");
    } catch (e) {
      commit("FAILURE_REQUEST_AIRPORTS");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
