import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import isUndefined from "lodash/isUndefined";
import isFinite from "lodash/isFinite";
import findIndex from "lodash/findIndex";
import router from "@/router";
import Vue from "vue";
import { DateTime } from "luxon";

const addPurchaseFlowQuantity = function (list) {
  if (isUndefined(list)) {
    return [];
  }
  each(list, function (element) {
    if (element.type === "group") {
      addPurchaseFlowQuantity(element.retail_items);
    } else {
      each(element.purchase_flow, function (flowGroup) {
        each(flowGroup.sub_items, function (flowItem) {
          flowItem.selected = false;
        });
      });
      element.purchase_flow_selected = [];
    }
  });
};

const findRetailGroup = function (list, groupId) {
  if (isUndefined(list)) {
    return [];
  }
  var foundGroup = undefined;
  each(list, function (element) {
    if (element.type === "group") {
      if (element.id === groupId) {
        foundGroup = element;
        return false;
      } else {
        foundGroup = findRetailGroup(element.retail_items, groupId);
        if (!isUndefined(foundGroup)) {
          return false;
        }
      }
    }
  });
  return foundGroup;
};

const findRetailItem = function (list, itemId) {
  if (isUndefined(list)) {
    return undefined;
  }
  var foundItem = undefined;
  each(list, function (element) {
    if (element.id === itemId) {
      foundItem = element;
      return false;
    } else {
      foundItem = findRetailItem(element.retail_items, itemId);
      if (!isUndefined(foundItem)) {
        return false;
      }
    }
  });
  return foundItem;
};

const indexInCartList = function (cartList, cartItem) {
  let foundIndex;
  each(cartList, function (cartListItem, index) {
    if (
      cartListItem.id === cartItem.id &&
      cartListItem.cartId !== cartItem.cartId &&
      cartListItem.purchase_flow_selected.length ===
        cartItem.purchase_flow_selected.length
    ) {
      var foundCount = cartItem.purchase_flow_selected.length;
      each(cartItem.purchase_flow_selected, function (cartItemFlowSub) {
        each(
          cartListItem.purchase_flow_selected,
          function (cartListItemFlowSub) {
            if (
              cartListItemFlowSub.sub_item_id === cartItemFlowSub.sub_item_id
            ) {
              foundCount--;
            }
          }
        );
      });
      if (foundCount === 0) {
        foundIndex = index;
        return false;
      }
    }
  });
  return isFinite(foundIndex) ? foundIndex : -1;
};

const getCartQuantity = function (cartList) {
  var quantity = 0;
  each(cartList, function (item) {
    quantity += item.quantity;
  });
  return quantity;
};

const getHighestCartItemIdIterator = function (cartList) {
  var i = 0;
  each(cartList, function (item) {
    if (item.cartId > i) {
      i = item.cartId;
    }
  });
  return i;
};

export default {
  getInfo: function ({ commit }, retailId) {
    commit("REQUEST_INFO");
    Vue.onesocket.send({
      function: "getRetailInfo",
      module: "SQMOD_RetailSystem",
      data: {
        id: retailId.toString(),
      },
      onMessage: function (data) {
        if (data.payload.status === "OK") {
          commit("SET_INFO", data.payload.root.retailInfo);
          commit("SUCCESS_REQUEST_INFO");
        } else {
          commit("FAILED_REQUEST_INFO");
        }
      },
      onError: function (event) {
        commit("FAILED_REQUEST_INFO");
      },
    });
  },
  getContent: function ({ commit, rootState }, retailId) {
    const useAuthLanguage =
      rootState.session.isCheckedIn &&
      rootState.session.authResult &&
      rootState.session.authResult.checkin &&
      rootState.session.authResult.checkin[0] &&
      rootState.session.mode !== "walkin" &&
      rootState.session.mode !== "walkin-only";

    commit("REQUEST_CONTENT");
    Vue.onesocket.send({
      function: "getRetailContent",
      module: "SQMOD_RetailSystem",
      data: {
        id: retailId.toString(),
        parent_id: "root",
        lang: useAuthLanguage
          ? rootState.session.authResult.language.language
          : rootState.language.selectedLanguage.language,
      },
      onMessage: function (data) {
        if (
          data.payload.status === "OK" &&
          !isEmpty(data.payload.root.retailContent.retail_items)
        ) {
          addPurchaseFlowQuantity(data.payload.root.retailContent.retail_items);
          commit("SET_CONTENT", data.payload.root.retailContent);
          commit("SUCCESS_REQUEST_CONTENT");
        } else {
          commit("FAILED_REQUEST_CONTENT");
        }
      },
      onError: function (event) {
        commit("FAILED_REQUEST_CONTENT");
      },
    });
  },
  transmitOrder: function ({ commit, state, rootState }, info) {
    commit("REQUEST_TRANSMIT");
    const orderList = [];
    const { additionalMessage = "" } = info;
    const { cartItemList = [] } = info;
    each(cartItemList, function (element) {
      const subItems = [];
      each(element.purchase_flow_selected, function (flowElement) {
        var price =
          parseFloat(flowElement.sub_item_price) +
          parseFloat(flowElement.sub_item_price_diff);
        if (!isFinite(price)) {
          price = flowElement.sub_item_price;
        }
        subItems.push({
          amount: element.quantity.toString(),
          item_id: flowElement.sub_item_id,
          name: flowElement.sub_item_title,
          price: price.toString(),
        });
      });
      orderList.push({
        main_item: {
          amount: element.quantity.toString(),
          item_id: element.item_id,
          name: element.shop_item_title,
          price: element.shop_item_price,
          sub_items: subItems,
        },
      });
    });

    let date;
    try {
      date = DateTime.fromJSDate(info.deliveryTime).toFormat(
        "yyyy-MM-dd HH:mm:ss"
      );
    } catch (ignore) {}

    if (
      !rootState.session.authResult.checkin ||
      !rootState.session.authResult.checkin[0] ||
      !rootState.session.authResult.checkin[0].name
    ) {
      commit("FAILED_REQUEST_TRANSMIT");
      return;
    }
    const transmitOrder = Vue.onesocket.send({
      function: "transmitOrder",
      module: "SQMOD_RetailSystem",
      data: {
        guestName: rootState.session.authResult.checkin[0].name,
        shop_id: info.retailId,
        delivery_time: date,
        additional_text: additionalMessage,
        location: router.currentRoute.query.location,
        cart: orderList,
      },
      onMessage: function (data) {
        clearTimeout(transmitTimeout);
        if (data.payload.status === "OK") {
          commit("SUCCESS_REQUEST_TRANSMIT");
        } else {
          commit("FAILED_REQUEST_TRANSMIT");
        }
      },
      onError: function (event) {
        clearTimeout(transmitTimeout);
        commit("FAILED_REQUEST_TRANSMIT");
      },
    });
    const transmitTimeout = setTimeout(function () {
      Vue.onesocket.clearMessages(transmitOrder);
      commit("FAILED_REQUEST_TRANSMIT");
    }, 30000);
  },
  setActiveGroup: function ({ commit, state }, groupId) {
    if (isUndefined(groupId) || groupId === "1") {
      commit("SET_ACTIVE_GROUP", state.content);
      commit("SET_PARENT_GROUP", {});
    } else {
      const foundGroup = findRetailGroup(state.content.retail_items, groupId);
      if (isUndefined(foundGroup)) {
        commit("SET_ACTIVE_GROUP", {});
        commit("SET_PARENT_GROUP", {});
      } else {
        commit("SET_ACTIVE_GROUP", foundGroup);
        commit(
          "SET_PARENT_GROUP",
          findRetailGroup(state.content.retail_items, foundGroup.parent_id)
        );
      }
    }
  },
  setActiveItemListData: function ({ commit, state }, groupId) {
    const foundItem = findRetailGroup(state.content.retail_items, groupId);
    commit(
      "SET_ACTIVE_ITEM_LIST_DATA",
      isUndefined(foundItem) ? {} : foundItem
    );
  },
  setPurchaseFlowCache: function (
    { commit, state },
    { itemId, cartId, quantity, copy = false }
  ) {
    let foundItem;
    if (isFinite(parseInt(itemId))) {
      foundItem = findRetailItem(state.content.retail_items, itemId);
    } else if (isFinite(parseInt(cartId))) {
      foundItem = find(state.cartItemList, {
        cartId: cartId,
      });
    }
    if (isUndefined(foundItem)) {
      return;
    }
    const clonedItem = copy ? JSON.parse(JSON.stringify(foundItem)) : foundItem;
    clonedItem.quantity = quantity;
    commit("SET_PURCHASE_FLOW_CACHE", clonedItem);
  },
  modifyPurchaseFlowSubitemSelected: function (
    { commit, state },
    { subItemId, isSelected }
  ) {
    let foundGroupIndex;
    let foundSubIndex;
    let foundItem;
    each(
      state.purchaseFlowCacheItem.purchase_flow,
      function (group, groupIndex) {
        each(group.sub_items, function (item, subItemIndex) {
          if (item.sub_item_id !== subItemId) {
            return;
          }
          foundGroupIndex = groupIndex;
          foundSubIndex = subItemIndex;
          foundItem = item;
          return false;
        });
        if (isFinite(foundSubIndex)) {
          return false;
        }
      }
    );
    if (isFinite(foundGroupIndex)) {
      commit("MODIFY_PURCHASE_FLOW_SUBITEM_SELECTED", {
        groupIndex: foundGroupIndex,
        subIndex: foundSubIndex,
        isSelected: isSelected,
      });
      if (isSelected) {
        commit("PURCHASE_FLOW_ADD_TO_SELECTED_LIST", foundItem);
      } else {
        const flowSelectedListIndex = findIndex(
          state.purchaseFlowCacheItem.purchase_flow_selected,
          {
            sub_item_id: foundItem.sub_item_id,
          }
        );
        if (flowSelectedListIndex !== -1) {
          commit(
            "PURCHASE_FLOW_REMOVE_FROM_SELECTED_LIST",
            flowSelectedListIndex
          );
        }
      }
    }
  },
  setCartItemListFromLocalStorage({ commit, state }) {
    commit(
      "SET_CART_ITEM_LIST",
      JSON.parse(localStorage.getItem("shop-cart-item-list"))
    );
    commit("SET_CART_QUANTITY", getCartQuantity(state.cartItemList));
    commit(
      "SET_CART_ITEM_ID_ITERATOR",
      getHighestCartItemIdIterator(state.cartItemList)
    );
  },
  addToCartList: function ({ commit, state, dispatch }, { itemId, quantity }) {
    if (!isFinite(quantity)) {
      return;
    }
    const foundItem = findRetailItem(state.content.retail_items, itemId);
    if (isUndefined(foundItem)) {
      return;
    }
    const foundCartItem = findRetailItem(state.cartItemList, itemId);
    if (isUndefined(foundCartItem)) {
      const clonedItem = JSON.parse(JSON.stringify(foundItem));
      clonedItem.quantity = quantity;
      clonedItem.shopId = router.app.$route.params.id;
      commit("ADD_TO_CART_LIST", clonedItem);
      var cnt = 0;
      each(state.cartItemList, function (element) {
        if (element.id === itemId) {
          cnt += element.quantity;
        }
      });
      commit("SET_ITEM_QUANTITY", {
        item: foundItem,
        quantity: cnt,
      });
    } else {
      commit("MODIFY_CART_ITEM_QUANTITY", {
        item: foundCartItem,
        quantity: foundCartItem.quantity + quantity,
      });
      commit("SET_ITEM_QUANTITY", {
        item: foundItem,
        quantity: foundCartItem.quantity,
      });
    }
    dispatch(
      "dialog/openDialog",
      {
        title: Vue.i18n.translate("shop.addedToCartTitle"),
        text: Vue.i18n.translate("shop.addedToCartMessage", {
          quantity,
          title: foundItem.shop_item_title,
        }),
        confirmation: true,
        actions: [
          {
            text: Vue.i18n.translate("shop.goToCart"),
            to: { name: "shopCart" },
          },
        ],
      },
      { root: true }
    );
    commit("SET_CART_QUANTITY", getCartQuantity(state.cartItemList));
  },
  modifyCartItemQuantity: function ({ commit, state }, { cartId, quantity }) {
    const cartItemIndex = findIndex(state.cartItemList, {
      cartId: cartId,
    });
    if (cartItemIndex !== -1) {
      const foundItem = findRetailItem(
        state.content.retail_items,
        state.cartItemList[cartItemIndex].id
      );
      if (quantity === 0) {
        commit("REMOVE_CART_ITEM", cartItemIndex);
      } else {
        commit("MODIFY_CART_ITEM_QUANTITY", {
          item: state.cartItemList[cartItemIndex],
          quantity: quantity,
        });
      }
      if (!isUndefined(foundItem)) {
        var cnt = 0;
        each(state.cartItemList, function (element) {
          if (element.id === foundItem.id) {
            cnt += element.quantity;
          }
        });
        commit("SET_ITEM_QUANTITY", {
          item: foundItem,
          quantity: cnt,
        });
      }
    }
    commit("SET_CART_QUANTITY", getCartQuantity(state.cartItemList));
  },
  addPurchaseFlowItemToCart: function ({ state, commit, dispatch }) {
    const cartItemIndex = indexInCartList(
      state.cartItemList,
      state.purchaseFlowCacheItem
    );
    if (cartItemIndex !== -1) {
      commit("MODIFY_CART_ITEM_QUANTITY", {
        item: state.cartItemList[cartItemIndex],
        quantity:
          state.cartItemList[cartItemIndex].quantity +
          state.purchaseFlowCacheItem.quantity,
      });
    } else {
      state.purchaseFlowCacheItem.shopId = router.app.$route.params.id;
      commit("ADD_TO_CART_LIST", state.purchaseFlowCacheItem);
    }
    const foundItem = findRetailItem(
      state.content.retail_items,
      state.purchaseFlowCacheItem.id
    );
    if (!isUndefined(foundItem)) {
      var cnt = 0;
      each(state.cartItemList, function (element) {
        if (element.id === state.purchaseFlowCacheItem.id) {
          cnt += element.quantity;
        }
      });
      commit("SET_ITEM_QUANTITY", {
        item: foundItem,
        quantity: cnt,
      });
    }
    let additionals = "";
    state.purchaseFlowCacheItem.purchase_flow_selected.map((item) => {
      additionals += ` + ${item.sub_item_title}`;
    });
    dispatch(
      "dialog/openDialog",
      {
        title: Vue.i18n.translate("shop.addedToCartTitle"),
        text: Vue.i18n.translate("shop.addedToCartMessage", {
          quantity: state.purchaseFlowCacheItem.quantity,
          title: foundItem.shop_item_title + additionals,
        }),
        confirmation: true,
        actions: [
          {
            text: Vue.i18n.translate("shop.goToCart"),
            to: { name: "shopCart" },
          },
        ],
      },
      { root: true }
    );
    commit("SET_CART_QUANTITY", getCartQuantity(state.cartItemList));
  },
  resetCartList: function ({ state, commit }, { cartItemList }) {
    each(cartItemList, function (element) {
      const found = findRetailItem(state.content.retail_items, element.id);
      if (!isEmpty(found)) {
        commit("SET_ITEM_QUANTITY", {
          item: found,
          quantity: 0,
        });
      }
    });
    commit("RESET_CART_LIST", { cartItemsToRemove: cartItemList });
    commit("SET_CART_QUANTITY", getCartQuantity(state.cartItemList));
  },
  reduceCartListDuplicates: function ({ state, commit }) {
    const cartItemIndex = indexInCartList(
      state.cartItemList,
      state.purchaseFlowCacheItem
    );
    if (
      cartItemIndex !== -1 &&
      state.cartItemList[cartItemIndex].cartId !==
        state.purchaseFlowCacheItem.cartId
    ) {
      commit("MODIFY_CART_ITEM_QUANTITY", {
        item: state.cartItemList[cartItemIndex],
        quantity:
          state.cartItemList[cartItemIndex].quantity +
          state.purchaseFlowCacheItem.quantity,
      });
      const purchaseFlowCacheItemInCartListIndex = findIndex(
        state.cartItemList,
        {
          cartId: state.purchaseFlowCacheItem.cartId,
        }
      );
      commit("REMOVE_CART_ITEM", purchaseFlowCacheItemInCartListIndex);
    }
    commit("SET_CART_QUANTITY", getCartQuantity(state.cartItemList));
  },
};
