import "@babel/polyfill";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./registerServiceWorker";
import "@/i18n";
import "./registerVueGoogleMaps";
import VueResource from "vue-resource";
import ONEsocket from "@/util/onesocket";
import Logger from "@/util/logger";
import Config from "@/util/config";
import VueCookies from "vue-cookies";
import * as routes from "@/util/routes";
import SessionPlugin from "@/plugins/session";
import * as helpers from "@/util/helpers";
import ResolvePageIdPlugin from "@/plugins/resolvePageId";
import "@/util/vue-filters";
import "@/css/line_clamp.css";
import "@/css/line_clamp_vuetify.css";
import VuePageTransition from "vue-page-transition";
import VueAnalytics from "vue-analytics";
import vuetify from "./plugins/vuetify";
import "roboto-fontface/css/roboto/roboto-fontface.css";
import "@mdi/font/css/materialdesignicons.css";
import VueTour from "vue-tour";
import VueCurrencyFilter from "vue-currency-filter";

require("vue-tour/dist/vue-tour.css");

Vue.use(VueResource);
Vue.use(ONEsocket);
Vue.use(Logger);
Vue.use(Config);
Vue.use(VueCookies);
Vue.use(VuePageTransition);
Vue.use(SessionPlugin);
Vue.use(ResolvePageIdPlugin);
Vue.use(VueTour);
Vue.use(VueCurrencyFilter);

Vue.use(VueAnalytics, {
  id: "UA-131772189-2",
  router,
  autoTracking: {
    exception: true,
  },
  // Uncomment to test google analytics events
  // debug: {
  //   enabled: true, // default value
  //   trace: true, // default value
  //   sendHitTask: true // default value
  // },
});

Vue.prototype.$helpers = helpers;
const $app = {};
Vue.util.defineReactive($app, "app", {
  clock: {
    date: new Date(),
    minute: 0,
  },
  util: {
    routes,
  },
  theme: {},
});

Vue.prototype.$app = $app.app;
Vue.http.options.emulateJSON = true;
Vue.http.options.root = location.host;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
  created() {
    this.$store.dispatch("startup");
  },
}).$mount("#app");
