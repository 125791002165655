import Vue from "vue";
import isUndefined from "lodash/isUndefined";

export function getStations() {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getAllRadioStationsWithFavourites",
      module: "SQMOD_Radio",
      data: {},
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger("api-webradio", "failed loading radio stations", error);
      },
    });
  });
}

export function getFavourites() {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getMyFavourites",
      module: "SQMOD_Radio",
      data: {},
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error(
          "api-webradio",
          "Failed to fetch getMyFavorites",
          error
        );
      },
    });
  });
}

export function addFavourite(options) {
  return new Promise(function (resolve, reject) {
    if (isUndefined(options.stationID)) {
      Vue.logger.info(
        "webradio-store",
        "addFavorite failed, given stationID id invalid"
      );
      reject();
      return;
    }
    Vue.onesocket.send({
      function: "insertMyFavouritesManually",
      module: "SQMOD_Radio",
      data: {
        item_id: options.stationID,
      },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("webradio-store", "addFavorite failed", error);
      },
    });
  });
}

export function removeFavourite(options) {
  return new Promise(function (resolve, reject) {
    if (isUndefined(options.stationID)) {
      Vue.logger.info(
        "webradio-store",
        "removeFavorite failed, given stationID id invalid"
      );
      reject();
      return;
    }
    Vue.onesocket.send({
      function: "removeMyFavouritesManually",
      module: "SQMOD_Radio",
      data: {
        item_id: options.stationID,
      },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        Vue.logger.error("webradio-store", "removeFavorite failed", error);
        reject(error);
      },
    });
  });
}

export function playRadioChannel(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "playRadioChannel",
      module: "SQMOD_RemotePlayer",
      data: { ...options },
      onMessage: (data) => {
        resolve(data);
      },
      onError: (error) => {
        reject(error);
        Vue.logger.error("api-epg", "Failed to play epg channel", error);
      },
    });
  });
}
