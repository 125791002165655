import Vue from "vue";

export function fetchWeather(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "fetchWeather",
      module: "SQMOD_Weather",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-weather", "Failed to fetch weather data", error);
      },
    });
  });
}
