<template>
  <v-select
    :outlined="outlined"
    class="no-arrow"
    dense
    flat
    :solo="solo"
    hide-details
    :disabled="items.length === 0"
    return-object
    :items="items"
    v-model="selectedLanguage"
    :loading="status === 'fetching'"
    item-text="display_name"
    :label="showLabel ? $t('app.language') : ''"
  >
    <template slot="selection" slot-scope="data">
      <template>
        <v-list-item-avatar v-show="showImg" tile>
          <img :src="$config.managerMedia + data.item.image" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-html="data.item.display_name"
          ></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
    <template slot="item" slot-scope="data">
      <template>
        <v-list-item-avatar v-show="showImg" tile>
          <img :src="$config.managerMedia + data.item.image" />
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title
            v-html="data.item.display_name"
          ></v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-select>
</template>

<script>
import { mapActions, mapState } from "vuex";

export default {
  name: "LanguageSelection",
  methods: {
    ...mapActions("language", ["getLanguageList", "setLanguage"]),
    ...mapActions("session", ["reAuthenticate"]),
  },
  computed: {
    ...mapState({
      items: ({ language }) => language.languageList,
      status: ({ language }) => language.status,
      authResult: ({ session }) => session.authResult,
    }),
    selectedLanguage: {
      get() {
        return this.$store.state.language.selectedLanguage;
      },
      set(val) {
        this.$ga.event({
          eventCategory: "Language",
          eventAction: "change",
          eventLabel: `from ${this.$store.state.language.selectedLanguage.lang_key} to ${val.lang_key}`,
          eventValue: 1,
        });
        this.$emit("change", val);
        this.$store.dispatch("language/setLanguage", {
          lang_key: val.lang_key,
        });
      },
    },
  },
  props: {
    outlined: {
      type: Boolean,
      default: false,
    },
    showLabel: {
      type: Boolean,
      default: false,
    },
    showImg: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
