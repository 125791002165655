import Vue from "vue";
import find from "lodash/find";

export default {
  getData({ commit, state }, { id, basePath }) {
    commit("REQUEST_DATA", id);

    const contentPageDataUrl =
      basePath + "Custom_" + state.id + ".json?ts=" + new Date().getTime();
    const contentPageScriptUrl =
      basePath + "script_" + state.id + ".js?ts=" + new Date().getTime();
    const contentPageTemplateUrl =
      basePath + "html_" + state.id + ".html?ts=" + new Date().getTime();
    const contentPageStyleUrl =
      basePath + "style_" + state.id + ".css?ts=" + new Date().getTime();

    Promise.all([
      Vue.http.get(contentPageDataUrl),
      Vue.http.get(contentPageScriptUrl),
      Vue.http.get(contentPageTemplateUrl),
      Vue.http.get(contentPageStyleUrl),
    ])
      .then((responseArr) => {
        const config = responseArr[2].body;
        const data = responseArr[0].body;
        const style = responseArr[3].body;
        const script = `"use strict";${responseArr[1].bodyText}`;

        const { type, dataMap } = config;

        // map the data e.g. Header to title for older Template items
        if (dataMap) {
          if (type === "singlelist") {
            const { list } = data;
            list.map((item) => {
              Object.keys(item).map((key) => {
                if (dataMap[key]) {
                  item[dataMap[key]] = item[key];
                }
              });
              return item;
            });
            data.list = list;
          } else if (type === "doublelist") {
            const { list } = data;
            const { subList = {} } = dataMap;
            list.map((item) => {
              Object.keys(item).map((key) => {
                if (dataMap[key]) {
                  item[dataMap[key]] = item[key];
                }
              });
              if (item.sublist && item.sublist.length > 0) {
                item.sublist.map((item) => {
                  Object.keys(item).map((key) => {
                    if (subList[key]) {
                      item[subList[key]] = item[key];
                    }
                  });
                  return item;
                });
              }
              return item;
            });
            data.list = list;
          } else {
            Object.keys(dataMap).map((key) => {
              if (dataMap[key]) {
                data[dataMap[key]] = data[key];
              }
            });
          }
        }

        commit("SUCCESS_REQUEST_DATA", {
          template: config,
          script,
          style,
          data,
          config,
        });
      })
      .catch((error) => {
        commit("FAILURE_REQUEST_DATA");
      });
  },
  getBannerData({ commit }, { id, basePath }) {
    commit("RESET_BANNER_DATA");
    commit("REQUEST_BANNER_DATA");

    const contentPageDataUrl =
      basePath + "Custom_" + id + ".json?ts=" + new Date().getTime();
    const contentPageScriptUrl =
      basePath + "script_" + id + ".js?ts=" + new Date().getTime();
    const contentPageTemplateUrl =
      basePath + "html_" + id + ".html?ts=" + new Date().getTime();
    const contentPageStyleUrl =
      basePath + "style_" + id + ".css?ts=" + new Date().getTime();

    Promise.all([
      Vue.http.get(contentPageDataUrl),
      Vue.http.get(contentPageScriptUrl),
      Vue.http.get(contentPageTemplateUrl),
      Vue.http.get(contentPageStyleUrl),
    ])
      .then((responseArr) => {
        const config = responseArr[2].body;
        const data = responseArr[0].body;

        if (config.type === "banner") {
          commit("SUCCESS_REQUEST_BANNER_DATA", data);
        }
      })
      .catch((error) => {
        commit("FAILURE_REQUEST_BANNER_DATA");
      });
  },
  async resolvePageId({ commit, rootState, state }) {
    const { authResult, skinId } = rootState.session;
    const pageId = state.requestedPageId;
    commit("RESOLVE_PAGE_ID");
    if (!authResult.skin?.project_id || !skinId || !pageId) {
      commit("FAILURE_RESOLVE_PAGE_ID");
      return;
    }
    const lang_key =
      this._vm.$cookies.get("lang-key") || rootState.session.browserLang;
    const basePath =
      this._vm.$config.managerSkins +
      authResult.skin.project_id +
      "/" +
      skinId +
      "/v2/oneapp/jscp/";
    const resolveUrl =
      basePath + "Page_" + pageId + ".json?ts=" + new Date().getTime();
    Vue.http
      .get(resolveUrl)
      .then((response) => {
        const { list } = response.body;
        const selectedLanguageObject = find(list, { lang_key });
        const defaultObject = find(list, { default: true });

        if (!selectedLanguageObject && !defaultObject) {
          commit("FAILURE_RESOLVE_PAGE_ID");
        } else {
          // Prefer contentpage in the selected language,
          // but show default if the page is not available in
          // that language.
          const { window_id } = selectedLanguageObject
            ? selectedLanguageObject
            : defaultObject;
          commit("SUCCESS_RESOLVE_PAGE_ID", window_id);
          return;
        }
      })
      .catch(() => {
        commit("FAILURE_RESOLVE_PAGE_ID");
        return;
      });
  },
};
