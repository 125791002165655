import Vue from "vue";
import { getTvMessages, mark, deleteMessage } from "@/api/tv-message";
import Push from "push.js";
import filter from "lodash/filter";

export default {
  async getTvMessages({ state, commit }) {
    commit("REQUEST_TV_MESSAGE");
    commit("SET_LOADING", true, { root: true });
    try {
      commit("SUCCESS_REQUEST_TV_MESSAGE", await getTvMessages());
      commit(
        "SET_UNREAD_MESSAGES",
        filter(state.items, { viewed: false }).length
      );
    } catch (e) {
      commit("FAILURE_REQUEST_TV_MESSAGE");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  async markAsRead({ commit, dispatch }, { messageId }) {
    commit("REQUEST_TV_MESSAGE_MARK_AS_READ");
    commit("SET_LOADING", true, { root: true });
    try {
      await mark({
        message_id: parseInt(messageId),
        mark_as_status: "true",
      });
      commit("SUCCESS_REQUEST_TV_MESSAGE_MARK_AS_READ");
    } catch (e) {
      commit("FAILURE_REQUEST_TV_MESSAGE_MARK_AS_READ");
    } finally {
      commit("SET_LOADING", false, { root: true });
      dispatch("getTvMessages");
    }
  },
  async markAsUnread({ commit, dispatch }, { messageId }) {
    commit("REQUEST_TV_MESSAGE_MARK_AS_UNREAD");
    commit("SET_LOADING", true, { root: true });
    try {
      await mark({ message_id: parseInt(messageId), mark_as_status: "false" });
      commit("SUCCESS_REQUEST_TV_MESSAGE_MARK_AS_UNREAD");
    } catch (e) {
      commit("FAILURE_REQUEST_TV_MESSAGE_MARK_AS_UNREAD");
    } finally {
      commit("SET_LOADING", false, { root: true });
      dispatch("getTvMessages");
    }
  },
  async deleteMessage({ commit, dispatch }, { id }) {
    commit("REQUEST_TV_MESSAGE_DELETE");
    commit("SET_LOADING", true, { root: true });
    try {
      await deleteMessage({ id, mark_as_status: false });
      commit("SUCCESS_REQUEST_TV_MESSAGE_DELETE");
    } catch (e) {
      commit("FAILURE_REQUEST_TV_MESSAGE_DELETE");
    } finally {
      commit("SET_LOADING", false, { root: true });
      dispatch("getTvMessages");
    }
  },
  async newTvMessage({ state, commit, dispatch }, { text }) {
    commit("NEW_TV_MESSAGE", { text });
    await dispatch("getTvMessages");
    dispatch(
      "dialog/openDialog",
      {
        title: Vue.i18n.translate("tvmessages.newMessage"),
        text: text,
        confirmation: true,
        confirmationFn: () => {
          filter(state.items, { text: text }).map(async (item) => {
            await dispatch("markAsRead", { messageId: item.message_id });
          });
        },
      },
      { root: true }
    );
    Push.config({
      serviceWorker: "./service-worker.js", // Sets a custom service worker script
    });
    Push.create("ONEapp", {
      body: text,
      icon: "./favicon.ico",
      onclick: function () {
        window.focus();
        this.close();
      },
    });
  },
};
