export default {
  SET_VIDEO_URL(state, value) {
    state.url = value;
  },
  SET_PLAY_STATE(state, value) {
    state.status = value;
  },
  SET_RESUME_TIME(state, value) {
    state.resumeTime = value;
  },
};
