import Vue from "vue";
import VueResource from "vue-resource";
import { API_PUBLIC_ROOT } from "@/config";

Vue.use(VueResource);

Vue.http.options.crossOrigin = true;
Vue.http.options.credentials = false;
Vue.http.interceptors.push((request, next) => {
  request.headers = request.headers || {};
  next();
});

export const ThemeResource = Vue.resource(
  API_PUBLIC_ROOT + "v1/oneapp/theme{/themeId}"
);
export const OneappWebcheckinEnabledResource = Vue.resource(
  API_PUBLIC_ROOT + "v1/oneapp/webcheckin{/propertyId}/enabled"
);

export const MailResource = Vue.resource(
  "https://demo06.onesquare.tv/email/send",
  {},
  {},
  {
    emulateJSON: false,
  }
);

export const SettingsResource = Vue.resource(
  API_PUBLIC_ROOT + "v1/property{/propertyId}/oneapp/settings"
);

export const ONEKeyResource = Vue.resource(
  API_PUBLIC_ROOT + "v2/property{/propertyId}/onekey/session",
  {},
  {},
  {
    emulateJSON: false,
  }
);
