export default () => {
  return {
    statusAirports: "",
    statusArrivals: "",
    statusDepartures: "",
    airports: [],
    departures: [],
    arrivals: [],
    totalArrivalPages: 0,
    totalDeparturePages: 0,
    selectedAirport: undefined,
    limit: "50",
    page: 1,
    searchTerm: undefined,
  };
};
