export default {
  REQUEST_LOCATION_DATA: function (state) {
    state.status = "fetching";
  },
  FAILURE_REQUEST_LOCATION_DATA: function (state) {
    state.data = undefined;
    state.date = undefined;
    state.status = "failure";
  },
  SUCCESS_REQUEST_LOCATION_DATA: function (state, { loc }) {
    state.data = loc;
    state.date = new Date();
    state.status = "success";
  },
  SET_LOCATION(state, value) {
    state.location = value;
  },
  SET_FIRST_CONFIG_LOCATION(state, value) {
    state.firstConfigLocation = value;
  },
};
