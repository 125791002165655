import { mapState, mapActions } from "vuex";
import store from "@/store";
import router from "@/router";

export default {
  install(Vue, options) {
    return new Vue({
      router,
      store,
      computed: {
        ...mapState({
          authResult: ({ session }) => session.authResult,
          skinId: ({ session }) => session.skinId,
          statusResolvePageId: ({ contentpage }) =>
            contentpage.statusResolvePageId,
          requestedPageId: ({ contentpage }) => contentpage.requestedPageId,
          resolvePageIdResult: ({ contentpage }) =>
            contentpage.resolvePageIdResult,
        }),
      },
      methods: {
        ...mapActions("contentpage", ["resolvePageId"]),
      },
      watch: {
        statusResolvePageId(val) {
          // Push to correct contentpage if the pageId has been resolved.
          if (val === "success") {
            this.$router.push({
              name: "contentpage",
              params: { cpId: this.resolvePageIdResult },
            });
            this.$store.commit("contentpage/RESET_PAGE_ID");
          }
        },
        authResult(val) {
          if (val?.skin?.project_id) {
            // Resolving the page id requires the project_id
            this.resolvePageId();
          }
        },
        skinId(val) {
          if (val) {
            // Resolving the page id requires the skinId
            this.resolvePageId();
          }
        },
        requestedPageId(val) {
          if (val) {
            this.resolvePageId();
          }
        },
      },
    });
  },
};
