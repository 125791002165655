export default {
  REQUEST_TV_MESSAGE(state) {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_TV_MESSAGE(state, { tvmessages }) {
    state.status = "success";
    state.items = tvmessages;
  },
  FAILURE_REQUEST_TV_MESSAGE(state) {
    state.status = "failure";
  },
  REQUEST_TV_MESSAGE_MARK_AS_READ(state) {
    state.statusMark = "fetching";
  },
  SUCCESS_REQUEST_TV_MESSAGE_MARK_AS_READ(state) {
    state.statusMark = "success";
  },
  FAILURE_REQUEST_TV_MESSAGE_MARK_AS_READ(state) {
    state.statusMark = "failure";
  },
  REQUEST_TV_MESSAGE_MARK_AS_UNREAD(state) {
    state.statusMark = "fetching";
  },
  SUCCESS_REQUEST_TV_MESSAGE_MARK_AS_UNREAD(state) {
    state.statusMark = "success";
  },
  FAILURE_REQUEST_TV_MESSAGE_MARK_AS_UNREAD(state) {
    state.statusMark = "failure";
  },
  REQUEST_TV_MESSAGE_DELETE(state) {
    state.statusDelete = "fetching";
  },
  SUCCESS_REQUEST_TV_MESSAGE_DELETE(state) {
    state.statusDelete = "success";
  },
  FAILURE_REQUEST_TV_MESSAGE_DELETE(state) {
    state.statusDelete = "failure";
  },
  NEW_TV_MESSAGE(state, value) {
    state.newTvMessage = value;
  },
  SET_UNREAD_MESSAGES(state, value) {
    state.unreadCount = value;
  },
};
