export const BASE_URL = process.env.NODE_ENV === "production" ? "/ONEapp/" : "";

export const API_PUBLIC_ROOT =
  process.env.NODE_ENV === "production"
    ? "https://app.cloud.at-visions.org/api/public/"
    : "https://dev-app.cloud.at-visions.org/api/public/";

export const ROOT =
  process.env.NODE_ENV === "production"
    ? "https://app.cloud.at-visions.org/"
    : "https://dev-app.cloud.at-visions.org/";
