export default () => ({
  id: undefined,
  isFetching: false,
  status: undefined,
  template: undefined,
  script: undefined,
  style: undefined,
  data: undefined,
  config: {},
  dataMap: {},
  bannerList: [],
  statusBanner: undefined,
  requestedPageId: undefined,
  resolvePageIdResult: undefined,
  statusResolvePageId: undefined,
});
