export default {
  REQUEST_IMAGES(state) {
    state.images = [];
    state.statusImages = "fetching";
  },
  SUCCESS_REQUEST_IMAGES(state, value) {
    state.images = value;
    state.statusImages = "success";
  },
  FAILURE_REQUEST_IMAGES(state) {
    state.images = [];
    state.statusImages = "failure";
  },
};
