import Vue from "vue";

export function getEpgData(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "loadEPGContentOfItemId",
      module: "SQMOD_LiveTV",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-epg", "Failed to fetch epg data", error);
      },
    });
  });
}

export function playEPGChannel(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "playChannel",
      module: "SQMOD_RemotePlayer",
      data: { ...options },
      onMessage: (data) => {
        resolve(data);
      },
      onError: (error) => {
        reject(error);
        Vue.logger.error("api-epg", "Failed to play epg channel", error);
      },
    });
  });
}
