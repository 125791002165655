import Vue from "vue";
import each from "lodash/each";
import throttle from "lodash/throttle";
import find from "lodash/find";
import { getChannels } from "@/api/tv";
import { getEpgData, playEPGChannel } from "@/api/epg";
import { getUTCTimestampFromServer } from "@/api/util";
import { getDateFromUTCTimestamp, formatDate } from "@/util/helpers";

export default {
  getChannels({ state, commit }, options = {}) {
    return new Promise(async (resolve) => {
      commit("REQUEST_CHANNELS");
      commit("SET_LOADING", true, { root: true });
      if (!options.forceReload && state.allChannels.length > 0) {
        commit("SUCCESS_REQUEST_CHANNELS", state.allChannels);
        commit("SET_LOADING", false, { root: true });
        resolve(state.allChannels);
        return;
      }
      try {
        let allChannels = await getChannels(options);
        each(allChannels, (value) => {
          // fill events with empty array to have it reactive when fetching epg events
          value.events = [];
          // for "loading" purposes
          value.isFetchingEvents = false;
          // dont reload channels when the start_time is the same as the already fetchedStartTime
          value.fetchedStartTime = 0;
          value.fetchedEndTime = 0;
          value.isFetchingCurrentEvent = false;
          value.status = null;
          value.currentEvent = null;
        });
        commit("SUCCESS_REQUEST_CHANNELS", allChannels);
        commit("SET_LOADING", false, { root: true });
        resolve(state.allChannels);
      } catch (e) {
        commit("FAILURE_REQUEST_CHANNELS");
        commit("SET_LOADING", false, { root: true });
        resolve([]);
      }
    });
  },
  async getEpgData(
    { commit, dispatch, state },
    { item_id, start_time, end_time }
  ) {
    await dispatch("getChannels");
    start_time = await getUTCTimestampFromServer({});
    commit("REQUEST_EPG_DATA", { item_id });
    try {
      const epgData = await getEpgData({
        item_id: parseInt(item_id),
        start_time,
        end_time,
        limit: state.limit,
      });
      each(epgData.events, (event) => {
        if (event.type !== "placeholder") {
          event.show_end_time = formatDate(
            getDateFromUTCTimestamp({
              utcTimeInMillis: event.end_timestamp,
              tz: state.timezone,
            }),
            Vue.onevisionCMSConfig[
              "epg_synopsis_window_date" + Vue.onevisionCMSConfig.format24Or12
            ]
          );
          event.show_start_time = formatDate(
            getDateFromUTCTimestamp({
              utcTimeInMillis: event.start_timestamp,
              tz: state.timezone,
            }),
            Vue.onevisionCMSConfig[
              "epg_synopsis_window_date" + Vue.onevisionCMSConfig.format24Or12
            ]
          );

          event.startDate = formatDate(
            getDateFromUTCTimestamp({
              utcTimeInMillis: event.start_timestamp,
              tz: state.timezone,
            }),
            "%a, %w %B %Y"
          );
        }
      });
      commit("SUCCESS_REQUEST_EPG_DATA", {
        item_id,
        value: epgData,
        start_time,
        end_time,
      });
    } catch (e) {
      commit("FAILURE_REQUEST_EPG_DATA", { item_id });
    }
  },
  async getAllEpgDataFromNow({ commit, dispatch, state }) {
    await dispatch("getChannels");

    await new Promise((resolve) => {
      setTimeout(resolve, 300);
    });

    commit("SET_LOADING", true, {
      root: true,
    });
    const start_time = await getUTCTimestampFromServer({});

    for (const value of state.allChannels) {
      try {
        commit("REQUEST_EPG_DATA_FROM_NOW", { item_id: value.id });
        commit("SUCCESS_REQUEST_EPG_DATA_FROM_NOW", {
          item_id: value.id,
          value: await getEpgData({
            item_id: value.id,
            start_time,
            limit: 1,
          }),
        });
        await new Promise((resolve) => {
          setTimeout(resolve, 300);
        });
      } catch (e) {
        commit("FAILURE_REQUEST_EPG_DATA_FROM_NOW", {
          item_id: value.id,
        });
      }
    }

    commit("SET_LOADING", false, {
      root: true,
    });
  },
  async getEpgDataFromNow({ commit, dispatch, rootState }, { item_id }) {
    await dispatch("getChannels");

    const channel = rootState.epg.allChannels.find(
      (item) => item.id === parseInt(item_id)
    );
    if (channel && channel.status === "success") {
      return;
    }

    commit("REQUEST_EPG_DATA_FROM_NOW", { item_id });
    try {
      const start_time = await getUTCTimestampFromServer({});
      const epgData = await getEpgData({ item_id, start_time, limit: 1 });
      commit("SUCCESS_REQUEST_EPG_DATA_FROM_NOW", {
        item_id,
        value: epgData,
        start_time,
        end_time: start_time,
      });
    } catch (e) {
      commit("FAILURE_REQUEST_EPG_DATA_FROM_NOW", { item_id });
    }
  },
  async playEPGChannel(
    { commit },
    { room_ip_id = "", start_channel_id, category_id = "0" }
  ) {
    commit("PLAY_CHANNEL");
    try {
      commit(
        "SUCCESS_PLAY_CHANNEL",
        await playEPGChannel({ room_ip_id, start_channel_id, category_id })
      );
    } catch (e) {
      commit("FAILURE_PLAY_CHANNEL");
    }
  },
};
