import { search } from "@/api/search";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import filter from "lodash/filter";

export default {
  async getSearchResults({
    commit,
    getters,
    state,
    rootState,
    rootGetters,
    dispatch,
  }) {
    const { device_id = undefined } = rootState.session.authResult;
    commit("SET_LOADING", true, { root: true });
    commit("REQUEST_SEARCH");
    try {
      commit(
        "SUCCESS_REQUEST_SEARCH",
        await search({
          room_ip_id: device_id,
          lang_id: rootGetters["language/getId"].toString(),
          search_term: state.searchTerm,
          type_filter: state.typeFilter.toString(),
          type_filter_delimiter: state.typeFilterDelimiter.toString(),
          skin_id: rootState.session.skinId,
        })
      );
      commit(
        "SET_ALL_TV_ITEMS",
        await dispatch("epg/getChannels", {}, { root: true })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_SEARCH");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
  setType({ commit, getters }, { idx = 0, type = "" }) {
    if (type.length > 0) {
      commit("SET_TYPE", find(getters.types, { type }) || {});
      commit("SET_TYPE_IDX", findIndex(getters.types, { type }));
    } else {
      commit("SET_TYPE", getters.types[idx] || {});
      commit("SET_TYPE_IDX", idx);
    }
  },
};
