import { getBillData } from "@/api/bill-preview";

export default {
  async getBillData({ commit }, { reservation_nr }) {
    commit("REQUEST_BILL_DATA");
    try {
      const payload = await getBillData({ reservation_nr });
      if (payload.status === "OK") {
        commit("SUCCESS_REQUEST_BILL_DATA", payload.root.items);
      } else {
        commit("FAILURE_REQUEST_BILL_DATA");
      }
    } catch (e) {
      commit("FAILURE_REQUEST_BILL_DATA");
    }
  },
};
