import Vue from "vue";

export function getDepartures(options) {
  options.options.type = "departure";
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getFlights",
      module: "SQMOD_FlightInfo",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-epg", "Failed to fetch flight data", error);
      },
    });
  });
}

export function getArrivals(options) {
  options.options.type = "arrival";
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getFlights",
      module: "SQMOD_FlightInfo",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-epg", "Failed to fetch flight data", error);
      },
    });
  });
}

export function getAirports(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getAirports",
      module: "SQMOD_FlightInfo",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-epg", "Failed to fetch flight data", error);
      },
    });
  });
}
