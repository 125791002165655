<template>
  <div>
    <v-list-item v-if="authResult.checkin && authResult.checkin[0]">
      <v-list-item-avatar v-if="authResult.checkin[0].name">
        <checked-in-person-avatar />
      </v-list-item-avatar>
      <v-list-item-content>
        <v-list-item-title v-if="authResult.checkin[0].name">{{
          authResult.checkin[0].name
        }}</v-list-item-title>
        <v-list-item-title v-else>{{ $t("login.room") }}</v-list-item-title>
        <v-list-item-subtitle v-if="authResult.room">{{
          authResult.room
        }}</v-list-item-subtitle>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapState } from "vuex";
import CheckedInPersonAvatar from "@/components/CheckedInPersonAvatar";

export default {
  components: {
    CheckedInPersonAvatar,
  },
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
    }),
  },
};
</script>
