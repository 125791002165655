import isString from "lodash/isString";
import isArray from "lodash/isArray";
import Vue from "vue";

export function getMultiImagesFromFolderPattern(options) {
  return new Promise((resolve, reject) => {
    var images = [];
    if (!isString(options.folder_pattern)) {
      Vue.logger.info(
        "picture-pool-gallery",
        "getImages failed, folderPattern is not a string"
      );
      reject();
      return;
    }
    Vue.onesocket.send({
      function: "getMultiImagesFromFolderPattern",
      module: "SQMOD_GlobalFunctions",
      data: { ...options },
      onMessage: function (data) {
        var payload = data.payload;
        if (payload.status === "OK") {
          images = payload.root.images;
          resolve(data.payload.root.images);
        } else {
          reject(data);
        }
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("picture-pool-gallery", "failed to get images", error);
      },
    });
  });
}
