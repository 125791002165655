export default () => ({
  status: "not_registered",
  authResult: {},
  checkinData: undefined,
  skinData: {},
  configStatus: undefined,
  currencyConfig: {
    symbol: "",
    thousandsSeparator: "",
    fractionCount: "",
    fractionSeparator: "",
    symbolPosition: "",
    symbolSpacing: "",
    avoidEmptyDecimals: undefined,
  },
  onesocketStatus: undefined,
  onesocketConnected: false,
  initialized: false,
  isCheckedIn: undefined,
  mode: undefined,
  isWalkin: false,
  browserLang: undefined,
  loginData: {
    room: undefined,
    name: undefined,
    checkin: undefined,
    userId: undefined,
  },
  skinId: undefined,
  themeId: undefined,
  propertyId: undefined,
  hasWalkInContent: false,
  ready: false,
  isWebcheckinEnabled: true,
  statusIsWebcheckinEnabled: false,
  settings: undefined,
  settingsStatus: "",
  magicLeft: 25,
  magicBottom: 25,
  magicButtonInterval: undefined,
  magicButtonShow: false,
  onekeySession: undefined,
  onekeySessionStatus: "",
});
