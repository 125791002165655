import { getMultiImagesFromFolderPattern } from "@/api/picture-pool-gallery";

export default {
  async getImages(
    { commit, rootState },
    {
      project_id = rootState.session.authResult.skin.project_id,
      folder_pattern,
    }
  ) {
    commit("REQUEST_IMAGES");
    commit("SET_LOADING", true, { root: true });
    try {
      commit(
        "SUCCESS_REQUEST_IMAGES",
        await getMultiImagesFromFolderPattern({ project_id, folder_pattern })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_IMAGES");
    } finally {
      commit("SET_LOADING", false, { root: true });
    }
  },
};
