import router from "@/router";
import { fetchWeather } from "@/api/weather";
import { wait } from "../../../util/helpers";

export default {
  setFirstLocation({ commit }) {
    commit("SET_FIRST_CONFIG_LOCATION", this._vm.$config.locations[0]);
  },
  async loadWeatherData({ dispatch, commit, rootState, state }) {
    try {
      commit("REQUEST_LOCATION_DATA");
      // show loading after once loaded to have the UI showing a loading spinner, else the user would not know that he reloaded
      if (state.status === "success") {
        await wait(1000);
      }

      dispatch("setFirstLocation");
      commit("SET_LOCATION", {
        city: router.currentRoute.query.city
          ? router.currentRoute.query.city
          : state.firstConfigLocation.city,
        country: router.currentRoute.query.country
          ? router.currentRoute.query.country
          : state.firstConfigLocation.country,
        iso: router.currentRoute.query.iso
          ? router.currentRoute.query.iso
          : state.firstConfigLocation.countryISO3166,
      });

      const { city, country, iso } = state.location;

      commit(
        "SUCCESS_REQUEST_LOCATION_DATA",
        await fetchWeather({
          city,
          country,
          countryISO3166: iso,
          lang: rootState.language.key,
        })
      );
    } catch (e) {
      console.error(e.message);
      commit("FAILURE_REQUEST_LOCATION_DATA");
    }
  },
};
