import { MailResource } from "./resources";
import router from "@/router";

export function postConfirmationMail({
  sender,
  content,
  subject,
  emailTo = router.currentRoute.query.email,
}) {
  MailResource.save({}, { sender, content, subject, emailTo }).then(
    (response) => {
      console.log(response);
    },
    (response) => {
      console.log(response);
    }
  );
}
