import { getTheme } from "@/api/theme";

export default {
  async getTheme({ commit }, { themeId }) {
    commit("REQUEST_THEME");
    try {
      commit(
        "SUCCESS_REQUEST_THEME",
        await getTheme({
          themeId,
        })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_THEME");
    }
  },
};
