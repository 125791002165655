import Vue from "vue";

export function search(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "getSearchResultsByTermAndRoomIpId",
      module: "SQMOD_Search",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
        Vue.logger.error("api-search", "Failed to fetch data", error);
      },
    });
  });
}
