import findIndex from "lodash/findIndex";

export default {
  REQUEST_EVENT_START(state) {
    state.statusEventStart = "request";
    state.eventNumberIterator++;
  },
  SUCCESS_REQUEST_EVENT_START(state) {
    state.statusEventStart = "success";
  },
  FAILURE_REQUEST_EVENT_START(state) {
    state.statusEventStart = "failure";
  },
  REQUEST_EVENT_END(state) {
    state.statusEventEnd = "request";
  },
  SUCCESS_REQUEST_EVENT_END(state) {
    state.statusEventEnd = "success";
  },
  FAILURE_REQUEST_EVENT_END(state) {
    state.statusEventEnd = "failure";
  },
  ADD_TO_EVENT_LIST(state, event) {
    state.eventList.push(event);
  },
  REMOVE_FROM_EVENT_LIST(state, event) {
    const listIndex = findIndex(state.eventList, function (element) {
      return (
        element.event_value === event.event_value &&
        element.event_type === event.event_type
      );
    });
    if (listIndex !== -1) {
      state.eventList.splice(listIndex, 1);
    }
  },
};
