import Vue from "vue";
import { BASE_URL } from "@/config";
import { OneappWebcheckinEnabledResource } from "./resources";

export function getUTCTimestampFromServer({ host = Vue.http.options.root }) {
  return new Promise((resolve, reject) => {
    if (process.env.NODE_ENV === "production") {
      Vue.http
        .get("https://" + host + BASE_URL + "/scripts/getUTCTimestamp.php")
        .then(
          (response) => {
            resolve(parseInt(response.body));
          },
          (response) => {
            let date = new Date();
            reject(
              parseInt(
                Date.UTC(
                  date.getUTCFullYear(),
                  date.getUTCMonth(),
                  date.getUTCDate(),
                  date.getUTCHours(),
                  date.getUTCMinutes(),
                  date.getUTCSeconds(),
                  date.getUTCMilliseconds()
                ) / 1000
              )
            );
          }
        );
    } else {
      let date = new Date();
      resolve(
        parseInt(
          Date.UTC(
            date.getUTCFullYear(),
            date.getUTCMonth(),
            date.getUTCDate(),
            date.getUTCHours(),
            date.getUTCMinutes(),
            date.getUTCSeconds(),
            date.getUTCMilliseconds()
          ) / 1000
        )
      );
    }
  });
}

export function sendDeviceDetails({
  firmware,
  os_type,
  onesquare_version,
  skin_version,
}) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "transmitFirmware",
      module: "SQMOD_SystemSettings",
      data: {
        firmware,
        os_type,
        onesquare_version,
        skin_version,
      },
      onMessage() {
        resolve();
      },
      onError: (error) => {
        reject();
      },
    });
  });
}

export function isWebcheckinEnabled({ propertyId }) {
  return OneappWebcheckinEnabledResource.get({ propertyId });
}
