export default () => ({
  status: "",
  result: [],
  searchTerm: "",
  typeFilter: "",
  typeFilterDelimiter: "",
  type: {},
  typeIdx: 0,
  allTvItems: [],
});
