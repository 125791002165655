<template>
  <v-bottom-navigation
    v-if="$store.getters.bottomNavItems.length > 1"
    :dark="theme.toolbarDark"
    :light="!theme.toolbarDark"
    @click.prevent
    @click.native.prevent
    v-model="activeBottomNav"
    color="primary"
    :style="{
      background: theme.toolbarBackground,
    }"
    app
    grow
  >
    <bottom-nav-button
      v-for="item in $store.getters.bottomNavItems"
      :key="item.text"
      :to="item.to"
      :text="item.text"
      :icon="item.icon"
      :badgeCnt="item.badgeCnt"
      style="background: transparent"
    ></bottom-nav-button>
  </v-bottom-navigation>
</template>

<script>
import BottomNavButton from "@/components/BottomNavButton";
import { mapState } from "vuex";

export default {
  components: {
    BottomNavButton,
  },
  data: () => ({
    activeBottomNav: undefined,
  }),
  computed: {
    ...mapState({
      theme: ({ theme }) => theme.theme,
    }),
  },
  watch: {
    "$route.name"() {
      this.activeBottomNav = undefined;
    },
  },
};
</script>
