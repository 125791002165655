import { ONEKeyResource } from "./resources";

export function getSession({ propertyId, room, vendor, device, expireAt }) {
  return new Promise((resolve, reject) => {
    ONEKeyResource.save(
      { propertyId },
      { room, vendor, device, expireAt }
    ).then(
      (response) => {
        resolve(response.body);
      },
      (error) => {
        reject(error);
      }
    );
  });
}
