<template>
  <v-avatar size="34" color="primary">
    <span
      v-if="
        authResult.checkin &&
        authResult.checkin[0] &&
        authResult.checkin[0].name &&
        authResult.checkin[0].name.length > 0
      "
      class="white--text headline"
      >{{ authResult.checkin[0].name.charAt(0).toUpperCase() }}</span
    >
    <v-icon v-else color="white">mdi-account-circle</v-icon>
  </v-avatar>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      authResult: ({ session }) => session.authResult,
    }),
  },
};
</script>
