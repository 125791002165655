export default {
  FETCHING_GET_ROOMCONTROL(state) {
    state.statusGet = "fetching";
  },
  SUCCESS_GET_ROOMCONTROL(state, { items }) {
    state.statusGet = "success";
    state.items = items;
  },
  FAILURE_GET_ROOMCONTROL(state) {
    state.statusGet = "failure";
  },
  FETCHING_SET_ROOMCONTROL(state) {
    state.statusSet = "fetching";
  },
  SUCCESS_SET_ROOMCONTROL(state) {
    state.statusSet = "success";
  },
  FAILURE_SET_ROOMCONTROL(state) {
    state.statusSet = "failure";
  },
  SET_STATE(state, value = {}) {
    state.state = value;
  },
  SET_STATE(state, value = {}) {
    state.state = value;
  },
};
