<template>
  <v-dialog v-model="dialog">
    <v-system-bar
      :dark="toolbarDark"
      :style="{ 'background-color': theme.toolbarBackground }"
      height="38"
      window
    >
      <v-spacer></v-spacer>
      <v-btn class="ma-0" icon @click.native.stop="dialog = false">
        <v-icon medium class="ma-0">mdi-close</v-icon>
      </v-btn>
    </v-system-bar>
    <d-player :options="options" ref="player" @play="play"></d-player>
    <v-alert type="error" :value="!videoOk">{{
      $t("notification.media.error")
    }}</v-alert>
  </v-dialog>
</template>
<script>
import { mapState, mapActions } from "vuex";
import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";
import Color from "color";
import trim from "lodash/trim";

export default {
  components: {
    "d-player": VueDPlayer,
  },
  data() {
    return {
      options: {
        video: {
          url: "",
        },
      },
      dialog: false,
      player: undefined,
      videoOk: true,
    };
  },
  computed: {
    ...mapState({
      status: ({ appplayer }) => appplayer.status,
      videoUrl: ({ appplayer }) => appplayer.url,
      resumeTime: ({ appplayer }) => appplayer.resumeTime,
      theme: ({ theme }) => theme.theme,
    }),
    toolbarDark() {
      if (this.theme.toolbarBackground) {
        return Color(trim(this.theme.toolbarBackground)).isDark();
      }
    },
  },
  methods: {
    ...mapActions("appplayer", [
      "setVideoUrl",
      "setPlayState",
      "setResumeTime",
    ]),
    play() {
      this.player.seek(this.resumeTime ? this.resumeTime : 0);
      // dont allow webfullscreen as it is not working on iphones
      this.player.on("webfullscreen", () => {
        setTimeout(() => {
          this.player.fullScreen.request("browser");
        }, 0);
      });
    },
  },
  watch: {
    dialog(val) {
      this.$nextTick(() => {
        this.player = this.$refs.player.dp;

        if (val) {
          this.videoOk = true;
          this.player.switchVideo({ url: this.videoUrl });
          // this.player.fullScreen.request('web')
          this.player.on("error", () => {
            this.videoOk = false;
          });

          setTimeout(() => {
            this.player.play();
            setTimeout(() => {
              this.play();
              setTimeout(() => {
                this.player.play();
              });
            });
          });
        } else {
          this.setResumeTime({
            time: this.player.video.currentTime,
          });
          this.player.pause();
          this.setPlayState({
            state: "stopped",
          });
        }
      });
    },
    status(val) {
      this.dialog = val === "play";
    },
  },
};
</script>
