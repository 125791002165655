import { sendDeviceDetails } from "@/api/util";

export default {
  reboot({ commit }) {
    commit("SET_COMMAND", { value: "reboot" });
  },
  powerOff({ commit }) {
    commit("SET_COMMAND", { value: "off" });
  },
  powerOn({ commit }) {
    commit("SET_COMMAND", { value: "on" });
  },
  setVolume({ commit }, value = 0) {
    commit("SET_VOLUME", value);
  },
  setSource({ commit }, { source, port = 1 }) {
    commit("SET_SOURCE", { source, port });
  },
  startApp({ commit }, { name }) {
    commit("SET_APP", { name });
  },
  async updateDeviceDetails(
    { commit },
    { firmware = "", os_type = "", onesquare_version = "", skin_version = "" }
  ) {
    commit("REQUEST_DEVICE_DETAILS");
    try {
      await sendDeviceDetails({
        firmware,
        os_type,
        onesquare_version,
        skin_version,
      });
      commit("SUCCESS_REQUEST_DEVICE_DETAILS");
    } catch (e) {
      commit("FAILURE_REQUEST_DEVICE_DETAILS");
    }
  },
  async setDate({ commit }, { date = new Date() }) {
    commit("UPDATE_TIME", date);
  },
};
