import Vue from "vue";

export function sendCmd(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "control",
      module: "SQMOD_RemoteControl",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
      },
    });
  });
}
