import Vue from "vue";
import Vuex from "vuex";
import modules from "./modules";
import { getRoute } from "@/util/routes";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  modules,
  state: {
    loading: true,
    mediaHeight: 0,
    mediaHeightText: "0px",
    mediaWidth: 0,
    breakpoint: undefined,
    landingPage: undefined,
    showCheckoutConfirmation: false,
    showDemoCheckoutDone: false,
    landingPageId: "",
    showUserMenu: false,
    showQrCodeReader: false,
    onQrDecode: () => {},
    intervalCheckInValidCheck: undefined,
  },
  getters: {
    moreNavItem: (state, getters, rootState) => ({
      to: { name: "moreNavItems" },
      text: Vue.i18n.translate("app.more"),
      icon: "mdi-dots-horizontal",
      badgeCnt: rootState.tvmessage.unreadCount + rootState.shop.cartQuantity,
    }),
    allNavItems: (state, getters, rootState) => {
      const ret = [];
      const { mode, settings = {} } = rootState.session;
      const { onekey = {} } = settings;
      const { saltoUseShip = false, vendor } = onekey;
      const { noTv, statusRequestTvs } = rootState.remoteControl;
      const { unreadCount, items } = rootState.tvmessage;
      const { cartQuantity } = rootState.shop;

      if (state.landingPage) {
        ret.push({
          to: getRoute({ item: state.landingPage, mode }),
          text: state.landingPage.title,
          icon: "mdi-home",
        });
      }

      if (
        (mode === "demo" || mode === "demo-use-pms") &&
        rootState.session.status !== "not_registered"
      ) {
        ret.push({
          to: { name: "mobileKey" },
          text: Vue.i18n.translate("app.mobileKey"),
          icon: "mdi-key",
        });
        ret.push({
          to: { name: "demoRoomControl" },
          text: Vue.i18n.translate("app.roomControl"),
          icon: "mdi-home-thermometer",
        });
      } else if (
        mode !== "walkin" &&
        mode !== "walkin-only" &&
        ((saltoUseShip && vendor === "salto") || vendor === "assa_abloy") &&
        rootState.session.status !== "not_registered"
      ) {
        ret.push({
          to: { name: "mobileKey" },
          text: Vue.i18n.translate("app.mobileKey"),
          icon: "mdi-key",
        });
      }

      if (
        mode !== "walkin" &&
        mode !== "walkin-only" &&
        !noTv &&
        statusRequestTvs === "success"
      ) {
        ret.push({
          to: { name: "remoteControl" },
          text: Vue.i18n.translate("app.remoteControl"),
          icon: "mdi-remote",
        });
      }

      if (
        mode !== "walkin" &&
        mode !== "walkin-only" &&
        rootState.roomControl.items.length > 0 &&
        rootState.roomControl.statusGet === "success"
      ) {
        ret.push({
          to: { name: "roomControl" },
          text: Vue.i18n.translate("app.roomControl"),
          icon: "mdi-home-thermometer",
        });
      }

      if (cartQuantity > 0) {
        ret.push({
          to: { name: "shopCart" },
          text: Vue.i18n.translate("shop.cart"),
          icon: "mdi-cart",
          disabled: cartQuantity < 1,
          badgeCnt: cartQuantity,
        });
      }

      if (items.length > 0 || mode === "demo" || mode === "demo-use-pms") {
        ret.push({
          to: { name: "tvMessage" },
          text: Vue.i18n.translate("tvmessages.header"),
          icon: "mdi-email",
          disabled: items.length < 1,
          badgeCnt: unreadCount,
        });
      }

      return ret;
    },
    bottomNavItems: (state, getters) => {
      if (state.breakpoint.lgAndUp) {
        return [...getters.allNavItems];
      } else if (state.breakpoint.smAndUp) {
        if (getters.allNavItems.length > 4) {
          return [...getters.allNavItems.slice(0, 3), ...[getters.moreNavItem]];
        }

        return getters.allNavItems;
      } else {
        if (getters.allNavItems.length > 3) {
          return [...getters.allNavItems.slice(0, 2), ...[getters.moreNavItem]];
        }

        return getters.allNavItems;
      }
    },
    moreNavItems: (state, getters) => {
      if (state.breakpoint.lgAndUp) {
        return getters.allNavItems;
      } else if (state.breakpoint.smAndUp) {
        return getters.allNavItems.slice(3, getters.allNavItems.length + 1);
      } else {
        return getters.allNavItems.slice(2, getters.allNavItems.length + 1);
      }
    },
  },
  actions: {
    startup: ({ dispatch }) => {
      // all startup functions should come here
    },
    calculateMediaHeight({ commit }, { breakpoint }) {
      // mobile phone landscape or ipad portrait
      commit("SET_BREAKPOINT", breakpoint);
      if ((breakpoint.smAndDown && breakpoint.height < 450) || breakpoint.xs) {
        commit("SET_MEDIA_HEIGHT", 275);
      } else if (breakpoint.sm || breakpoint.md || breakpoint.lg) {
        commit("SET_MEDIA_HEIGHT", 425);
      } else {
        commit("SET_MEDIA_HEIGHT", 500);
      }
    },
    demoCheckout({ commit }) {
      commit("SET_SHOW_DEMO_CHECKOUT_DONE", true);
    },
    openQrCodeReader({ commit }, onDecode) {
      commit("SET_ON_QR_CODE_DECODE", onDecode);
      commit("SET_SHOW_QR_CODE_READER", true);
    },
    closeQrCodeReader({ commit }) {
      commit("SET_ON_QR_CODE_DECODE", () => {});
      commit("SET_SHOW_QR_CODE_READER", false);
    },
    initIntervalCheckInValidCheck(
      { commit, rootState },
      { checkIntervalTimeInMinutes = 10 }
    ) {
      commit(
        "SET_INTERVAL_CHECK_IN_VALID_CHECK",
        setInterval(() => {
          const { mode } = rootState.session;
          if (
            router.currentRoute.name !== "login" &&
            mode !== "walkin" &&
            mode !== "walkin-only" &&
            mode !== "demo" &&
            mode !== "demo-use-pms"
          ) {
            this._vm.$onesocket.send({
              function: "authenticate",
              module: "SQMOD_Auth",
              data: {},
              onMessage: async (data) => {
                if (
                  data.payload.root.checkin &&
                  data.payload.root.checkin.length > 0
                ) {
                  return;
                } else {
                  await router.app.$store.dispatch("session/deleteLoginData");
                  window.location.reload();
                }
              },
            });
          }
        }, 600 * checkIntervalTimeInMinutes)
      );
    },
  },
  mutations: {
    SET_LOADING(state, value) {
      state.loading = value === true;
    },
    SET_MEDIA_HEIGHT(state, value) {
      state.mediaHeight = value;
      state.mediaHeightText = value + "px";
      state.mediaWidth = window.innerWidth;
    },
    SET_BREAKPOINT(state, value) {
      // the framework property of vuetify's breakpoint is circular, so we need to remove it from the copy
      value.framework = undefined;
      state.breakpoint = JSON.parse(JSON.stringify(value));
    },
    SET_LANDING_PAGE(state, value) {
      state.landingPage = value;
      try {
        state.landingPageId = value.onclick.substr(7);
      } catch (error) {
        console.error(error.message);
      }
    },
    SET_SHOW_CHECKOUT_CONFIRMATION(state, value) {
      state.showCheckoutConfirmation = value;
    },
    SET_SHOW_DEMO_CHECKOUT_DONE(state, value) {
      state.showDemoCheckoutDone = value;
    },
    SET_SHOW_USER_MENU(state, value) {
      state.showUserMenu = value;
    },
    SET_SHOW_QR_CODE_READER(state, value) {
      state.showQrCodeReader = value;
    },
    SET_ON_QR_CODE_DECODE(state, value) {
      state.onQrDecode = value;
    },
    SET_INTERVAL_CHECK_IN_VALID_CHECK(state, value) {
      clearInterval(state.intervalCheckInValidCheck);
      state.intervalCheckInValidCheck = value;
    },
  },
  strict: process.env.NODE_ENV !== "production", // strict mode is for debugging purpose only!
});
