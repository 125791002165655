import { sendCmd } from "@/api/remote-control";
import { getRoomsByRoomnumber, getPowerStatus } from "@/api/room-information";
import Vue from "vue";

/*
 * This function will remove all devices called like for example ipad or bath.
 * The remove_special_devices_called value can be adapted in the CMS
 */
const removeSpecialDevices = function ({ arr, state }) {
  for (var i = 0, len = arr.length; i < len; i++) {
    for (var j = 0, len2 = state.specialDevicesCalled.length; j < len2; j++) {
      if (state.specialDevicesCalled[j].length !== 0) {
        // check if the remove_special_devices_called is found in the description of the device and when its found just remove this item from the arr
        if (
          typeof arr[i] !== "undefined" &&
          typeof arr[i].description === "string" &&
          arr[i].description
            .toLowerCase()
            .indexOf(state.specialDevicesCalled[j]) !== -1
        ) {
          arr.splice(i, 1);
          i--;
        }
      }
    }
  }
};

export default {
  async getTvs({ state, commit }) {
    commit("REQUEST_TVS");

    if (state.tvs.length > 0) {
      commit("SUCCESS_REQUEST_TVS", state.tvs);
      return;
    }

    try {
      let tvs = await getRoomsByRoomnumber({ room: state.roomNr });
      tvs = tvs || [];

      removeSpecialDevices({ arr: tvs.rooms, state });
      tvs.rooms.forEach((item) => {
        let { name = item.description.replace(".iptv", "") } = item;
        if (name.includes("lr")) {
          name = Vue.i18n.translate("remoteControl.livingRoom");
        } else if (name.includes("br")) {
          name = Vue.i18n.translate("remoteControl.bedRoom");
        } else if (name.includes("bath")) {
          name = Vue.i18n.translate("remoteControl.bathRoom");
        }

        item.name = name;
        return item;
      });
      commit("SUCCESS_REQUEST_TVS", tvs.rooms);
      commit("SET_ONLY_ONE_TV", state.tvs.length === 1);
      commit("SET_NO_TV", state.tvs.length === 0);

      if (state.tvs.length === 1) {
        commit("SET_SELECTED_TV", state.tvs[0]);
      }
    } catch (e) {
      commit("FAILURE_REQUEST_TVS");
    }
  },
  async sendCmd({ commit, state, dispatch }, { cmd }) {
    if (cmd === "tvOff") {
      const powerState = await dispatch("getPowerStatus", {
        room_id: state.selectedTv.id,
      });
      cmd = parseInt(powerState) === 1 ? cmd : "tvOn";
    }
    commit("REQUEST_SEND_COMMAND");
    try {
      await sendCmd({
        room_id: state.selectedTv.id,
        room_ip: state.selectedTv.ip,
        command: cmd,
      });
      commit("SUCCESS_REQUEST_SEND_COMMAND", cmd);
    } catch (e) {
      commit("FAILURE_REQUEST_SEND_COMMAND");
    }
  },
  async getPowerStatus({ commit }, { room_id }) {
    try {
      const state = await getPowerStatus({ room_id });
      return state.powerStatus;
    } catch (e) {
      commit("FAILURE_REQUEST_POWER_STATUS");
    }
  },
};
