import Vue from "vue";
import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import each from "lodash/each";
import chunk from "lodash/chunk";

const systemValues = [
  "APP_LOGIN_TYPE",
  "HIDE_BILL_ITEM_TIME",
  "OPEN_LINK_NEW_TAB",
  "CURRENCY",
  "CURRENCY_SHORT",
  "price_decimal_precision",
  "CURRENCY_POSITION",
  "OMC_SKIN",
  "VOD_DIR",
  "TV_FAVOURITES_MIN_TIME",
  "TV_FAVOURITES_ENABLE",
  "PVR_MAX_RECORDING_DURATION",
  "MOD_DIR",
  "PVR_SMB_MEDIA_PATH",
  "ONESQUARE_SECURITY_LEVEL",
  "mngr_mini_epg_display_duration",
  "mngr_mini_epg_enabled",
  "WAKEUP_DEFAULT_FILE",
  "media_server_manager_fokuson_server_url",
  "media_server_manager_fokuson_shop_id",
  "WAKEUP_START_VOLUME",
  "WAKEUP_VOLUME_STEP",
  "WAKEUP_TIME_BETWEEN_STEPS",
  "WAKEUP_SNOOZE_MINUTES",
  "WAKEUP_MAX_VOLUME",
  "SHOW_ALREADY_BOUGHT",
  "onesquare_fallback_language_key",
  "onesquare_language",
  "onesquare_latest_url",
  "onesquare_square_skins_path",
  "onesquare_media_url",
  "onesquare_multi_image_url",
  "onesquare_home_url",
  "onesquare_application_debug",
  "onesquare_application_debug_level",
  "onesquare_log_to_server_error_log",
  "onesquare_log_to_debug_console",
  "onesquare_current_channel_on_restart",
  "onesquare_external_port",
  "onesquare_websocket",
  "onesquare_websocket_ip",
  "onesquare_websocket_port",
  "onesquare_signage_url",
  "onesquare_signage_user",
  "onesquare_signage_pwd",
  "onesquare_signage_psn",
  "onesquare_signage_scn",
  "onesquare_video_player_x",
  "onesquare_video_player_y",
  "onesquare_video_player_preview_x",
  "onesquare_video_player_preview_y",
  "onesquare_drm_enabled",
  "onesquare_drm_type",
  "onesquare_drm_type_nr",
  "onesquare_drm_vendor_name",
  "onesquare_drm_server_ip",
  "onesquare_drm_server_port",
  "onesquare_wake_up_am_pm",
  "onesquare_enable_direct_play",
  "onesquare_direct_play_module",
  "onesquare_weather_widget_refresh_time",
  "onesquare_weather_unit",
  "onesquare_change_location_image",
  "onesquare_change_source_enabled",
  "onesquare_onclick_delimiter",
  "onesquare_date_time_zone",
  "onesquare_instant_power",
  "onesquare_enable_soft_ap",
  "onesquare_auto_refresh",
  "onesquare_refresh_time",
  "onesquare_fallback_skin",
  "onesquare_fallback_no_network_connection_enabled",
  "onesquare_fallback_no_network_connection",
  "onesquare_vod_coverflow_enabled",
  "onesquare_vod_use_scaled_images",
  "onesquare_vod_language_display_type",
  "onesquare_tv_channel_control",
  "onesquare_epg_channel_page_size",
  "onesquare_scroll_speed",
  "onesquare_epg_pvr_enabled",
  "onesquare_flight_data_page_size",
  "onesquare_onesocket_connection_ip",
  "onesquare_port",
  "onesquare_scroll_speed_horizontal",
  "onesquare_scroll_speed_vertical",
  "onesquare_scroll_forward_timeout",
  "onesquare_scroll_backward_timeout",
  "onesquare_scroll_backward_time",
  "onesquare_use_channel_map",
  "onesquare_mod_seperator",
  "onesquare_sleeptimer_steps",
  "onesquare_sleeptimer_max",
  "onesquare_show_loading_tv_group",
  "onesquare_show_loading_tv_all",
  "onesquare_tv_control_enabled",
  "onesquare_tv_control_protocol",
  "onesquare_tv_control_source",
  "onesquare_fokus_on_enabled",
  "onesquare_fokus_on_server_ip",
  "onesquare_fokus_on_proxy",
  "onesquare_fokus_on_authentication_url",
  "onesquare_fokus_on_rental_url",
  "onesquare_fokus_on_auth_pin",
  "onesquare_fokus_on_request_type",
  "onesquare_fokus_on_play_timeout",
  "onesquare_epg_synopsis_window",
  "onesquare_bill_item_date_format12",
  "onesquare_bill_item_date_format24",
  "onesquare_bill_item_time_format12",
  "onesquare_bill_item_time_format24",
  "onesquare_epg_timeline_date_format12",
  "onesquare_epg_timeline_date_format24",
  "onesquare_clock_date_format12",
  "onesquare_clock_date_format24",
  "onesquare_clock_time_format12",
  "onesquare_clock_time_format24",
  "onesquare_flight_info_date_format12",
  "onesquare_flight_info_date_format24",
  "onesquare_retail_opening_closing_time24",
  "onesquare_retail_opening_closing_time12",
  "onesquare_epg_synopsis_window_date24",
  "onesquare_epg_synopsis_window_date12",
  "onesquare_wake_up_time_format12",
  "onesquare_wake_up_time_format24",
  "onesquare_wake_up_widget_time_format12",
  "onesquare_wake_up_widget_time_format24",
  "onesquare_reservation_assistant_date_format12",
  "onesquare_reservation_assistant_date_format24",
  "onesquare_reservation_assistant_time_format12",
  "onesquare_reservation_assistant_time_format24",
  "onesquare_login_devices",
  "onesquare_autostart",
  "onesquare_weather_locations",
  "onesquare_default_player",
  "onesquare_module_player_tv",
  "onesquare_module_player_vod",
  "onesquare_module_player_webradio",
  "onesquare_module_player_skin",
  "onesquare_module_player_welcome_screen",
  "onesquare_module_player_websocket",
  "onesquare_module_player_epg",
  "onesquare_module_player_wake_up",
  "onesquare_module_player_one_play",
  "onesquare_module_player_mod",
  "onesquare_module_player_pvr",
  "onesquare_vod_setup_empty_duration",
  "onesquare_vod_setup_empty_actors",
  "onesquare_vod_setup_empty_year",
  "onesquare_weather_cf_seperator",
  "onesquare_rss_title_text_seperator",
  "onesquare_rss_item_endtag",
  "onesquare_daily_reboot_enabled",
  "onesquare_reboot_time",
  "onesquare_reboot_fallback_time",
  "onesquare_reboot_random_offset_in_minutes",
  "onesquare_video_player_preview_position_x",
  "onesquare_video_player_preview_position_y",
  "LATE_CHECKOUT_START_TIME",
  "LATE_CHECKOUT_END_TIME",
  "SHOW_FOLIO_ON_CHECKOUT",
  "DISABLE_CHECKOUT_ON_EARLY_CHECKOUT",
  "CHECKOUT_TYPE",
  "CHECKOUT_MAIL_ADDRESSES",
  "APP_VOD_PATH",
  "onesquare_tv_preview_enabled",
  "onesquare_tv_preview_start_delay",
  "onesquare_tv_preview_width",
  "onesquare_tv_preview_height",
  "onesquare_tv_preview_position_x",
  "onesquare_tv_preview_position_y",
  "SHOP_AMOUNT_INDICATOR",
  "onesquare_remove_special_devices_called",
  "USE_INTERFACE_FOR_VOD",
  "onesquare_wakeup_default_file",
  "TV_TYPE",
  "radio_favourites_enabled",
  "onesquare_background_music_stop_on_keypress",
  "onesquare_background_music_volume",
  "onesquare_enable_snooze",
  "GREETTIME_1",
  "GREETTIME_2",
  "GREETTIME_3",
  "PARENTAL_CONTROL_PASS",
  "PARENTAL_CONTROL_TYPE",
  "onesquare_onesocket_receive_timeout",
  "onesquare_tv_message_date_format12",
  "onesquare_tv_message_date_format24",
  "onesquare_bang_olufsen_shortcut_config",
  "search_vod_type",
  "shop_additional_message_enabled",
];
export default new Vue({
  data: function () {
    return {
      initialized: undefined,
      errorCode: undefined,
      managerUrl: "http://manager.iptv",
      managerMedia: "http://manager.iptv/ONEvisionCMS/media/",
      managerSkins: "http://manager.iptv/square_skins/",
      managerWelcomescreen: "http://manager.iptv/square_ws/",
      mediaUrl: "http://media.iptv/",
      appMoviePath: "http://media.iptv/AppMovies/",
      // Shall the _CONFIG values be overwritten by database values
      // when set to false, _CONFIG values are not set via database
      // ATTENTION this should only be set to false when necessary
      overwriteWithDatabaseValues: true,
      // Overwrite value to activate touch events ( touchscreens )
      touchDeviceOverwrite: false,
      // device type (eg.: lg, samsung, auto=detect from http header), is set in HTML-Builder.php.
      device: "unknown",
      device_platform: "unknown",
      // fallback language must exist!
      /** @override */
      fallbackLanguageKey: "en",
      // should be the same value as fallbackLanguageKey
      // e.g. fallbackLanguageKey: en
      // language: english
      /** @override */
      language: "english",
      // will be set during startup
      language_key: "en",
      // available languages
      // will be set during startup
      languages: [],
      // will be set during startup
      LANGUAGE_ID: "",
      // start with special module (eg.: tv, vod)
      // this value will be overwritten by profile setting in CMS
      // Startup Source
      /** @override */
      autostart: "",
      // will be set during startup
      roomid: 0,
      // will be set during startup
      OMC_ROOM_ID: 0,
      // will be set during startup
      room: "",
      // first checked in guest
      // will be set during startup
      guestName: "",
      // default value for ONEsquare latest link
      /** @override */
      LATEST_URL: "/ONEsquare/ONEsquareLatest/index.php",
      // fallback currency
      /** @override */
      currency_long: "EUR",
      /** @override */
      currency_short: "€",
      /** @override */
      currency_position: "Front",
      /** @override */
      currency_thousand_separator: ".",
      /** @override */
      price_decimal_precision: 2,
      /** @override */
      currency_fraction_separator: ",",
      /** @override */
      skin: "skin.default",
      // default value for ONEsquare installed skins directory
      /** @override */
      skins: "../../square_skins",
      // default value for media directory
      /** @override */
      media_url: "../../ONEvisionCMS/media/",
      // default value for multi image url directory
      /** @override */
      multi_image_url: "../../../../ONEvisionCMS/media/",
      // default value for ONEsquare url
      // will be used for reloading ONEsquare
      /** @override */
      homeURL: "http://manager.iptv/ONEsquare/index.php",
      // en-disable debug mode
      /** @override */
      debug: false,
      // sets the debug level
      // @see debug.js
      // levels:
      // will be set in window.load function in app.js
      //     FATAL: 10,
      //     ERROR: 20,
      //     WARN: 30,
      //     INFO: 40,
      //     DEBUG: 50
      /** @override */
      debug_level: 40,
      // when debug value is true and
      // logToServerErrorLog is set true
      // ONEsquare will log to server error_log
      /** @override */
      log_to_server_error_log: false,
      // when debug value is true and
      // logToDebugConsole is set true
      // ONEsquare will log to debug console
      /** @override */
      log_to_debug_console: false,
      // when set to true
      // playCachedChannel in app.js will be called on power on/off
      /** @override */
      current_channel_on_restart: false,
      /** @override */
      bill_item_date_format12: "%d/%m/%y",
      /** @override */
      bill_item_date_format24: "%d/%m/%y",
      /** @override */
      bill_item_time_format12: "%I:%M:%S %p",
      /** @override */
      bill_item_time_format24: "%I:%M:%S",
      // defines autostart hdmi port
      /** @override */
      external_port: 1,
      // en-disable Websocket ONEinstant
      /** @override */
      websocket: false,
      // ONEinstant websocket ip
      /** @override */
      websocket_ip: null,
      // ONEinstant websocket port
      /** @override */
      websocket_port: null,
      /** @override */
      signage_url: "",
      /** @override */
      signage_user: "",
      /** @override */
      signage_pwd: "",
      /** @override */
      signage_psn: "",
      /** @override */
      signage_scn: "",
      /** @override */
      epg_timeline_date_format12: "%I:%M %p",
      /** @override */
      epg_timeline_date_format24: "%H:%M",
      /** @override */
      clock_date_format12: "%a, %w %B %Y %p",
      /** @override */
      clock_date_format24: "%a, %w %B %Y",
      /** @override */
      clock_time_format12: "%I:%M %p",
      /** @override */
      clock_time_format24: "%H:%M",
      /** @override */
      flight_info_date_format12: "%m-%w %I:%M %p",
      /** @override */
      flight_info_date_format24: "%m-%w %H:%M",
      /** @override */
      retail_opening_closing_time24: "%H:%M",
      /** @override */
      retail_opening_closing_time12: "%I:%M %p",
      /** @override */
      epg_synopsis_window_date24: "%H:%M",
      /** @override */
      epg_synopsis_window_date12: "%I:%M %p",
      // default player x size
      /** @override */
      videoPlayerX: 1280,
      // default player y size
      /** @override */
      videoPlayerY: 720,
      // default player preview x size for EPG
      /** @override */
      videoPlayerPreviewX: 382,
      // default player preview y size for EPG
      /** @override */
      videoPlayerPreviewY: 215,
      // default player preview x position for EPG
      /** @override */
      video_player_preview_position_x: 0,
      // default player preview y position for EPG
      /** @override */
      video_player_preview_position_y: 0,
      /** @override */
      drm_enabled: false,
      // encoding type for encrypted streams
      // possible values: VERIMATRIX, PROIDIOM
      /** @override */
      drm_type: "VERIMATRIX",
      /** @override */
      drm_type_nr: 3,
      /** @override */
      drm_vendor_name: "atvisions",
      /** @override */
      drm_server_ip: "vks.verimatrix.com",
      /** @override */
      drm_server_port: "12686",
      /** @override */
      wake_up_time_format12: "%a, %w. %B %Y - %I:%M %p",
      /** @override */
      wake_up_time_format24: "%a, %w. %B %Y - %H:%M",
      /** @override */
      wake_up_widget_time_format12: "%a, %w. %B - %I:%M",
      /** @override */
      wake_up_widget_time_format24: "%a, %w. %B - %H:%M",
      /** @override */
      reservation_assistant_date_format12: "%a, %w. %B %Y",
      /** @override */
      reservation_assistant_date_format24: "%a, %w. %B %Y",
      /** @override */
      reservation_assistant_time_format12: "%I:%M %p",
      /** @override */
      reservation_assistant_time_format24: "%H:%M",
      /** @override */
      wakeUpAmPm: false,
      // directplay module enabled/disabled
      /** @override */
      ENABLE_DIRECTPLAY: true,
      // direct play navigate to tv all or tv group -> can be "group" or "all" in init
      // after this if person navigatet last to group he will jump into group on global directplay
      /** @override */
      directPlayModule: "all",
      // how often should the weather widget refresh,
      // time in ms, default 15min
      /** @override */
      weatherWidgetRefreshTime: 900000,
      // weatherunit can be C, F, CF or FC
      /** @override */
      weatherUnit: "C",
      // locations for selection must be an Array with objects location objects must have a city and country,
      // country ISO3166 is optional and visibilizes the country short to the user on selection button
      /** @override */
      locations: [
        {
          city: "Vienna",
          country: "Austria",
          countryISO3166: "AT",
        },
        {
          city: "San Francisco",
          country: "United States",
          countryISO3166: "USA",
        },
      ],
      // shall the image change on location change
      /** @override */
      changeLocationImage: false,
      // setting this option to false will disable source changes with changesource.js
      /** @override */
      changeSourceEnabled: true,
      // Delimiter for parameters in "onclick"-events. This is needed for backwards compatibility (it was "/" before adding samsung browser).
      /** @override */
      onclick_delimiter: "|",
      // check Datetimezone at http://php.net/manual/de/timezones.php needs to be entered when updating tv time automatically
      /** @override */
      dateTimeZone: "Europe/Berlin",
      // instantPower property shall the tv start warm into application 1: warm start enabled / 0: normal start must be string type
      /** @override */
      instantPower: "0",
      // possible values 1,0
      /** @override */
      enableSoftAP: 0,
      // autorefresh for refreshing the browser automatically. setting this option to false will disable autorefresh
      /** @override */
      autorefresh: false,
      // refresh Time to perform an autorefresh in seconds
      /** @override */
      refreshTime: 0,
      // fallback skin for language
      /** @override */
      fallbackSkin: "skin.default",
      // Param is set to true to enable fallback function when Network is disconnected
      // true (enabled), false (disabled)
      // see fallbackNoNetworkConnection parameter to set the fallback action when enabled
      /** @override */
      fallbackNoNetworkConnectionEnabled: false,
      // Fallback param setting for no network action
      // default (showing msg "NO NETWORK CONNECTION!")
      // watiting for connection...) 2. coax (switching to coax only mode (Antenna),
      // when network is connected jumping back to ONEsquare)
      /** @override */
      fallbackNoNetworkConnection: "coax",
      /** @override */
      VOD_DIR: null,
      // enables, disables coverflow in vod module
      /** @override */
      vodCoverflowEnabled: false,
      // use scaled vod images
      // important to name images like scale-[name of original image].jpg
      // you have to scale the moviecovers via scale.py or gimp (http://wiki.at-visions.org/display/OSQ/VOD)
      // then copy images to ONEvisionCMS/media/mediacontent/.
      /** @override */
      vodUseScaledImages: false,
      // defines language presentation (text / image)
      /** @override */
      vodLanguageDisplayType: "image",
      // Show already bought image on bought Movies
      // possible values: "True", "False"
      /** @override */
      SHOW_ALREADY_BOUGHT: "True",
      // automatically add tv channel to favourites list parameter in seconds (60s: default)
      /** @override */
      autoTVChannelFavourite: 120,
      // FOR LG ONLY hcap property (tv_channel_control)
      // "0" => Only Application is allowed to change TV channels
      // "1" => Only TV is allowed to change TV channels
      // possible values: "0", "1"
      /** @override */
      tvChannelControl: "0",
      /** @override */
      TV_FAVOURITES_ENABLE: false,
      // Defines how many channels are shown per epg page
      // dont use number 0
      /** @override */
      EPGchannelPageSize: 7,
      // Defines the speed for autoscroll max speed is 1
      // used by autoscroll.js
      // the higher the slower
      /** @override */
      scrollSpeed: 40,
      // EPG_PVR_ENABLED: true, false
      /** @override */
      EPG_PVR_ENABLED: false,
      // Maximum PVR recording duration in EPG
      /** @override */
      PVR_MAX_RECORDING_DURATION: 0,
      // PVR samba path media folder
      /** @override */
      PVR_SMB_MEDIA_PATH: "",
      // flight data amount to be shown per page
      /** @override */
      flightDataPageSize: 10,
      // Defines which date format should be taken for language
      date_format_language_mapping: {
        24: ["de"],
        12: ["en"],
        en: "12",
        default: "24",
      },
      // default connection url to ONEsquare service
      /** @override */
      ONEsocketConnectionIp: document.location.host,
      // default connection port to ONEsquare service
      /** @override */
      ONEsocketConnectionPort: "8004",
      /** @override */
      onesocket_receive_timeout: 60000,
      // the higher the faster
      /** @override */
      scrollSpeedHorizontal: 30,
      /** @override */
      scrollSpeedVertical: 10,
      /** @override */
      scrollForwardTimeout: 3000,
      /** @override */
      scrollBackwardTimeout: 5000,
      /** @override */
      scrollBackwardTime: 300,
      // which module shall use internal, remote player
      // possible values remote/internal
      // only when device is touch
      /** @override */
      MODULE_PLAYER: {
        TV: "internal",
        VOD: "internal",
        WEBRADIO: "internal",
        SKIN: "internal",
        WELCOMESCREEN: "internal",
        WEBSOCKET: "internal",
        EPG: "internal",
        WAKEUP: "internal",
        ONEPLAY: "internal",
        MOD: "internal",
        PVR: "internal",
      },
      // default player for all modules
      // will be overwritten by MODULE_PLAYER configuration
      /** @override */
      DEFAULT_PLAYER: "internal",
      // wether to use the LG channelmap
      /** @override */
      useChannelmap: false,
      /** @override */
      MOD_DIR: null,
      // seperator shown in Music on demand
      /** @override */
      MOD_SEPERATOR: "/",
      // sleeptimer_steps: value of minute steps
      /** @override */
      sleeptimer_steps: 15,
      // sleeptimer_max: maximum allowed value
      /** @override */
      sleeptimer_max: 180,
      // defines which devices will be directed to a login screen
      // no login devices will be directed to the TV configuration window
      // when no ip found in system
      /** @override */
      LOGIN_DEVICES: ["applemobile", "unknown"],
      // 1 : security level 1. delete personal information in TV when turning TV off.
      // 2 : security level 2. maintain personal information in TV when turning TV off.
      // (onesquare_security_level)
      /** @override */
      SECURITY_LEVEL: "1",
      // devicetype is set in HTML-Builder.php. Used to determine if device supports touch and in createDOM in utils.js
      DEVICE_TYPE: "unknown",
      /** @override */
      MNGR_MINI_EPG_ENABLED: "false",
      /** @override */
      MNGR_MINI_EPG_DISPLAY_DURATION: "0",
      /** @override */
      onesquare_wakeup_default_file:
        "http://" +
        document.location.hostname +
        "/ONEsquare/resources/wakeup/default.mp3",
      // {boolean} true - show loading container in tv group module when loading media group channels
      // {boolean} false - dont wait for channel logos to load and show them immediately in tv group module
      /** @override */
      show_loading_tv_group: true,
      // {boolean} true - show loading container in tv all module when loading all channels
      // {boolean} false - dont wait for channel logos to load and show them immediately in tv all module
      /** @override */
      show_loading_tv_all: true,
      // possible values true, false
      /** @override */
      tv_control_enabled: false,
      // possible values :
      // serialXpress3
      // samsung_2_3
      /** @override */
      tv_control_protocol: "serialXpress3",
      // tv control source defines which source
      // will be switched to
      // STB must be connected to the source
      /** @override */
      tv_control_source: "hdmi1",
      // FokusOn VOD support for Arris
      /** @override */
      fokus_on_enabled: false,
      // System Value for FokusOn Server IP | media_server_manager_fokuson_server_url
      // fokus_on_server_ip is a fallback value
      /** @override */
      fokus_on_server_ip: "",
      /** @override */
      fokus_on_proxy: "",
      /** @override */
      fokus_on_authentication_url: "",
      /** @override */
      fokus_on_rental_url: "",
      /** @override */
      fokus_on_auth_pin: "",
      /** @override */
      fokus_on_request_type: "",
      /** @override */
      fokus_on_play_timeout: 3000,
      /** @override */
      media_server_manager_fokuson_shop_id: "",
      /** @override */
      media_server_manager_fokuson_server_url: "",
      /** @override */
      WAKEUP_START_VOLUME: 40,
      /** @override */
      WAKEUP_VOLUME_STEP: 5,
      /** @override */
      WAKEUP_TIME_BETWEEN_STEPS: 2,
      /** @override */
      WAKEUP_SNOOZE_MINUTES: 5,
      /** @override */
      WAKEUP_MAX_VOLUME: 100,
      // possible values true, false
      // en - disable epg synopsis window
      /** @override */
      epg_synopsis_window: false,
      // defines the default value for empty movie metadata
      // setting the value to null will remove the field completely
      // setting the value to "---" will be for empty year metadata, "Year: ---"
      /** @override */
      vod_setup: {
        duration: null,
        year: null,
        actors: null,
      },
      /** @override */
      weather_cf_seperator: "/",
      /** @override */
      rss_title_text_seperator: " - ",
      /** @override */
      rss_item_endtag: " +++ ",
      // when enabled and instant power set to 1 or 2
      // debug info will log :
      //     REBOOT INFO : startRebootInterval, reboot scheduled for 5:27
      //     REBOOT INFO : startRebootInterval, fallback reboot scheduled for 11:12
      // working on devices: LG, SAMSUNG
      /** @override */
      onesquare_daily_reboot_enabled: false,
      // when should the reboot happen?
      // timeformat: HH:MM), must be before fallbacktime
      /** @override */
      onesquare_reboot_time: "05:15",
      // when reboot was not possible, when should we try to reboot again?
      // timeformat: HH:MM
      /** @override */
      onesquare_reboot_fallback_time: "11:00",
      // a minute value between 0 and onesquare_reboot_random_offset_in_minutes
      // will be added to the reboot time
      /** @override */
      onesquare_reboot_random_offset_in_minutes: 15,
      /** @override */
      late_checkout_start_time: "00:00",
      /** @override */
      late_checkout_end_time: "00:00",
      /** @override */
      show_folio_on_checkout: true,
      /** @override */
      disable_checkout_on_early_checkout: true,
      /** @override */
      checkout_type: "Email",
      /** @override */
      checkout_mail_addresses: "",
      /** @override */
      app_vod_path: "",
      /** @override */
      tv_preview_enabled: false,
      /** @override */
      tv_preview_start_delay: 3,
      /** @override */
      tv_preview_width: 100,
      /** @override */
      tv_preview_height: 100,
      /** @override */
      tv_preview_position_x: 0,
      /** @override */
      tv_preview_position_y: 0,
      /** @override */
      shop_amount_indicator: "x",
      /** @override */
      remove_special_devices_called: ["tablet", "mobile", "phone"],
      /** @override */
      use_interface_for_vod: true,
      /** @override */
      tv_type: "iptv",
      /** @override */
      radio_favourites_enabled: false,
      backgroundMusicStopOnKeypress: true,
      backgroundMusicVolume: 10,
      greetingWidgetTriggerValue1: undefined,
      greetingWidgetTriggerValue2: undefined,
      greetingWidgetTriggerValue3: undefined,
      /** @override */
      parental_control_pass: undefined,
      /** @override */
      parental_control_type: "roomnumber",
      /** @override */
      enableSnooze: false,
      /** @override */
      tv_message_date_format12: "YYYY-MM-DD h:mm A",
      tv_message_date_format24: "YYYY-MM-DD HH:mm",
      /** @override */
      bang_olufsen_shortcut_config:
        "80:tvOpen|all;86:openVideoOnDemandAll;85:extern;81:webradioOpen;82:webradioOpen",
      /** @override */
      search_vod_type: "all",
      APP_LOGIN_TYPE: "lastname",
      HIDE_BILL_ITEM_TIME: false,
      OPEN_LINK_NEW_TAB: false,
      shop_additional_message_enabled: false,
      media_pool_item_play_path: "/media_pool/",
    };
  },
  computed: {
    format24Or12: function () {
      return this.$config.date_format_language_mapping[
        this.$config.language_key.toLowerCase()
      ]
        ? "12"
        : "24";
    },
  },
  methods: {
    install: function () {
      const _this = this;
      Object.defineProperty(Vue.prototype, "$config", {
        get() {
          return _this;
        },
      });
      Vue.onevisionCMSConfig = this;
    },
    init: function () {
      const _this = this;
      return new Promise((resolve, reject) => {
        try {
          _this.managerUrl = "";
          _this.managerMedia = _this.managerUrl + "/ONEvisionCMS/media/";
          _this.managerSkins = _this.managerUrl + "/square_skins/";
          _this.managerWelcomescreen = _this.managerUrl + "/square_ws/";
          _this.loadConfig().then(
            function () {
              resolve();
            },
            function (cause) {
              _this.$logger.error("config", "Failed to initialize config");
              reject(cause);
            }
          );
        } catch (e) {
          _this.initialized = false;
          _this.$logger.error(
            "config",
            "Failed to initialize config",
            e.message
          );
          reject(e);
        }
      });
    },
    loadConfig: function () {
      const _this = this;
      return new Promise((resolve, reject) => {
        const systemValuesChunks = chunk(systemValues, 50);
        const promiseArray = [];
        each(systemValuesChunks, function (el, index) {
          promiseArray.push(
            _this.$http.get(
              "/ONEapp/api/php_helpers/initialisation/Json_operator.php",
              {
                params: {
                  systemvalues: el,
                  cmdArr: ["getSystemValues"],
                },
              }
            )
          );
        });
        Promise.all(promiseArray).then(
          function (response) {
            try {
              each(response, function (el, index) {
                if (el.body.success) {
                  if (
                    !isUndefined(el.body) &&
                    !isUndefined(el.body[0].getSystemValues)
                  ) {
                    // TODO: use faster jason parser
                    _this.setValues(
                      JSON.parse(el.body[0].getSystemValues.root)
                    );
                  } else {
                    _this.initialized = false;
                    _this.errorCode = "4000-3";
                    _this.$logger.error(
                      "config",
                      "4000-3 returned config is empty"
                    );
                    reject(new Error("4000-3 returned config is empty"));
                    return false;
                  }
                } else {
                  _this.initialized = false;
                  _this.errorCode = "4000-4";
                  _this.$logger.error(
                    "config",
                    "4000-4 returned config is invalid"
                  );
                  reject(new Error("4000-4 returned config is invalid"));
                  return false;
                }
              });
              _this.initialized = true;
              resolve();
            } catch (error) {
              _this.initialized = false;
              _this.errorCode = "4000-1";
              _this.$logger.error(
                "config",
                "4000-1 Failed to parse config",
                error.message
              );
              reject(error);
            }
          },
          function (error) {
            _this.errorCode = "4000-2";
            _this.$logger.error(
              "config",
              "4000-2 Failed to fetch config",
              error.message
            );
            reject(error);
          }
        );
      });
    },
    setValues: function (systemValues) {
      var _this = this;
      each(systemValues, function (value, key) {
        switch (key) {
          case "APP_LOGIN_TYPE":
            _this.setConfigValue(
              "APP_LOGIN_TYPE",
              value,
              "APP_LOGIN_TYPE",
              false,
              false
            );
            break;
          case "HIDE_BILL_ITEM_TIME":
            _this.setConfigValue(
              "HIDE_BILL_ITEM_TIME",
              value,
              "HIDE_BILL_ITEM_TIME",
              true,
              false
            );
          case "OPEN_LINK_NEW_TAB":
            _this.setConfigValue(
              "OPEN_LINK_NEW_TAB",
              value,
              "OPEN_LINK_NEW_TAB",
              true,
              false
            );
          case "onesquare_application_debug":
            _this.setConfigValue(
              "debug",
              value,
              "onesquare_application_debug",
              true,
              false
            );
            break;
          case "onesquare_application_debug_level":
            _this.setConfigValue(
              "debug_level",
              value,
              "onesquare_application_debug_level",
              false,
              false
            );
            break;
          case "onesquare_log_to_server_error_log":
            _this.setConfigValue(
              "log_to_server_error_log",
              value,
              "onesquare_log_to_server_error_log",
              true,
              false
            );
            break;
          case "onesquare_log_to_debug_console":
            _this.setConfigValue(
              "log_to_debug_console",
              value,
              "onesquare_log_to_debug_console",
              true,
              false
            );
            break;
          case "onesquare_fallback_language_key":
            _this.setConfigValue(
              "fallbackLanguageKey",
              value,
              "onesquare_fallback_language_key",
              false,
              false
            );
            break;
          case "onesquare_language":
            _this.setConfigValue(
              "language",
              value,
              "onesquare_language",
              false,
              false
            );
            break;
          case "onesquare_latest_url":
            _this.setConfigValue(
              "LATEST_URL",
              value,
              "onesquare_latest_url",
              false,
              false
            );
            break;
          case "oneCURRENCY_SHORTsquare_square_skins_path":
            _this.setConfigValue(
              "skins",
              value,
              "onesquare_square_skins_path",
              false,
              false
            );
            break;
          case "onesquare_media_url":
            _this.setConfigValue(
              "media_url",
              value,
              "onesquare_media_url",
              false,
              false
            );
            break;
          case "onesquare_multi_image_url":
            _this.setConfigValue(
              "multi_image_url",
              value,
              "onesquare_multi_image_url",
              false,
              false
            );
            break;
          case "onesquare_home_url":
            _this.setConfigValue(
              "homeURL",
              value,
              "onesquare_home_url",
              false,
              false
            );
            break;
          case "onesquare_current_channel_on_restart":
            _this.setConfigValue(
              "current_channel_on_restart",
              value,
              "onesquare_current_channel_on_restart",
              true,
              false
            );
            break;
          case "onesquare_external_port":
            _this.setConfigValue(
              "external_port",
              value,
              "onesquare_external_port",
              false,
              false
            );
            break;
          case "onesquare_websocket":
            _this.setConfigValue(
              "websocket",
              value,
              "onesquare_websocket",
              true,
              false
            );
            break;
          case "onesquare_websocket_ip":
            _this.setConfigValue(
              "websocket_ip",
              value,
              "onesquare_websocket_ip",
              false,
              false
            );
            break;
          case "onesquare_websocket_port":
            _this.setConfigValue(
              "websocket_port",
              value,
              "onesquare_websocket_port",
              false,
              false
            );
            break;
          case "onesquare_signage_url":
            _this.setConfigValue(
              "signage_url",
              value,
              "onesquare_signage_url",
              false,
              false
            );
            break;
          case "onesquare_signage_user":
            _this.setConfigValue(
              "signage_user",
              value,
              "onesquare_signage_user",
              false,
              false
            );
            break;
          case "onesquare_signage_pwd":
            _this.setConfigValue(
              "signage_pwd",
              value,
              "onesquare_signage_pwd",
              false,
              false
            );
            break;
          case "onesquare_signage_psn":
            _this.setConfigValue(
              "signage_psn",
              value,
              "onesquare_signage_psn",
              false,
              false
            );
            break;
          case "onesquare_signage_scn":
            _this.setConfigValue(
              "signage_scn",
              value,
              "onesquare_signage_scn",
              false,
              false
            );
            break;
          case "onesquare_video_player_x":
            _this.setConfigValue(
              "videoPlayerX",
              value,
              "onesquare_video_player_x",
              false,
              true
            );
            break;
          case "onesquare_video_player_y":
            _this.setConfigValue(
              "videoPlayerY",
              value,
              "onesquare_video_player_y",
              false,
              true
            );
            break;
          case "onesquare_video_player_preview_x":
            _this.setConfigValue(
              "videoPlayerPreviewX",
              value,
              "onesquare_video_player_preview_x",
              false,
              true
            );
            break;
          case "onesquare_video_player_preview_y":
            _this.setConfigValue(
              "videoPlayerPreviewY",
              value,
              "onesquare_video_player_preview_y",
              false,
              true
            );
            break;
          case "onesquare_drm_enabled":
            _this.setConfigValue(
              "drm_enabled",
              value,
              "onesquare_drm_enabled",
              true,
              false
            );
            break;
          case "onesquare_drm_type":
            _this.setConfigValue(
              "drm_type",
              value,
              "onesquare_drm_type",
              false,
              false
            );
            break;
          case "onesquare_drm_type_nr":
            _this.setConfigValue(
              "drm_type_nr",
              value,
              "onesquare_drm_type_nr",
              false,
              true
            );
            break;
          case "onesquare_drm_vendor_name":
            _this.setConfigValue(
              "drm_vendor_name",
              value,
              "onesquare_drm_vendor_name",
              false,
              false
            );
            break;
          case "onesquare_drm_server_ip":
            _this.setConfigValue(
              "drm_server_ip",
              value,
              "onesquare_drm_server_ip",
              false,
              false
            );
            break;
          case "onesquare_drm_server_port":
            _this.setConfigValue(
              "drm_server_port",
              value,
              "onesquare_drm_server_port",
              false,
              false
            );
            break;
          case "onesquare_wake_up_am_pm":
            _this.setConfigValue(
              "wakeUpAmPm",
              value,
              "onesquare_wake_up_am_pm",
              true,
              false
            );
            break;
          case "onesquare_enable_direct_play":
            _this.setConfigValue(
              "ENABLE_DIRECTPLAY",
              value,
              "onesquare_enable_direct_play",
              true,
              false
            );
            break;
          case "onesquare_direct_play_module":
            _this.setConfigValue(
              "directPlayModule",
              value,
              "onesquare_direct_play_module",
              false,
              false
            );
            break;
          case "onesquare_weather_widget_refresh_time":
            _this.setConfigValue(
              "weatherWidgetRefreshTime",
              value,
              "onesquare_weather_widget_refresh_time",
              false,
              true
            );
            break;
          case "onesquare_weather_unit":
            _this.setConfigValue(
              "weatherUnit",
              value,
              "onesquare_weather_unit",
              false,
              false
            );
            break;
          case "onesquare_weather_locations":
            _this.setConfigValue(
              "locations",
              value,
              "onesquare_weather_locations",
              false,
              false
            );
            _this.setWeatherLocationsFromSystemValue();
            break;
          case "onesquare_change_location_image":
            _this.setConfigValue(
              "changeLocationImage",
              value,
              "onesquare_change_location_image",
              true,
              false
            );
            break;
          case "onesquare_change_source_enabled":
            _this.setConfigValue(
              "changeSourceEnabled",
              value,
              "onesquare_change_source_enabled",
              true,
              false
            );
            break;
          case "onesquare_onclick_delimiter":
            _this.setConfigValue(
              "onclick_delimiter",
              value,
              "onesquare_onclick_delimiter",
              false,
              false
            );
            break;
          case "onesquare_date_time_zone":
            _this.setConfigValue(
              "dateTimeZone",
              value,
              "onesquare_date_time_zone",
              false,
              false
            );
            break;
          case "onesquare_instant_power":
            _this.setConfigValue(
              "instantPower",
              value,
              "onesquare_instant_power",
              false,
              false
            );
            break;
          case "onesquare_enable_soft_ap":
            _this.setConfigValue(
              "enableSoftAP",
              value,
              "onesquare_enable_soft_ap",
              false,
              true
            );
            break;
          case "onesquare_auto_refresh":
            _this.setConfigValue(
              "autorefresh",
              value,
              "onesquare_auto_refresh",
              true,
              false
            );
            break;
          case "onesquare_refresh_time":
            _this.setConfigValue(
              "refreshTime",
              value,
              "onesquare_refresh_time",
              false,
              true
            );
            break;
          case "onesquare_fallback_skin":
            _this.setConfigValue(
              "fallbackSkin",
              value,
              "onesquare_fallback_skin",
              false,
              false
            );
            break;
          case "onesquare_fallback_no_network_connection_enabled":
            _this.setConfigValue(
              "fallbackNoNetworkConnectionEnabled",
              value,
              "onesquare_fallback_no_network_connection_enabled",
              true,
              false
            );
            break;
          case "onesquare_fallback_no_network_connection":
            _this.setConfigValue(
              "fallbackNoNetworkConnection",
              value,
              "onesquare_fallback_no_network_connection",
              false,
              false
            );
            break;
          case "onesquare_vod_coverflow_enabled":
            _this.setConfigValue(
              "vodCoverflowEnabled",
              value,
              "onesquare_vod_coverflow_enabled",
              true,
              false
            );
            break;
          case "onesquare_vod_use_scaled_images":
            _this.setConfigValue(
              "vodUseScaledImages",
              value,
              "onesquare_vod_use_scaled_images",
              true,
              false
            );
            break;
          case "onesquare_vod_language_display_type":
            _this.setConfigValue(
              "vodLanguageDisplayType",
              value,
              "onesquare_vod_language_display_type",
              false,
              false
            );
            break;
          case "onesquare_tv_channel_control":
            _this.setConfigValue(
              "tvChannelControl",
              value,
              "onesquare_tv_channel_control",
              false,
              false
            );
            break;
          case "onesquare_epg_channel_page_size":
            _this.setConfigValue(
              "EPGchannelPageSize",
              value,
              "onesquare_epg_channel_page_size",
              false,
              true
            );
            break;
          case "onesquare_scroll_speed":
            _this.setConfigValue(
              "scrollSpeed",
              value,
              "onesquare_scroll_speed",
              false,
              true
            );
            break;
          case "onesquare_epg_pvr_enabled":
            _this.setConfigValue(
              "EPG_PVR_ENABLED",
              value,
              "onesquare_epg_pvr_enabled",
              true,
              false
            );
            break;
          case "onesquare_flight_data_page_size":
            _this.setConfigValue(
              "flightDataPageSize",
              value,
              "onesquare_flight_data_page_size",
              false,
              true
            );
            break;
          case "onesquare_onesocket_connection_ip":
            _this.setConfigValue(
              "ONEsocketConnectionIp",
              value,
              "onesquare_onesocket_connection_ip",
              false,
              false
            );
            _this.ONEsocketConnectionIp = _this.checkEmptyIp(
              _this.ONEsocketConnectionIp
            );
            break;
          case "onesquare_port":
            _this.setConfigValue(
              "ONEsocketConnectionPort",
              value,
              "onesquare_port",
              false,
              false
            );
            break;
          case "onesquare_horizontal_scroll_speed":
            _this.setConfigValue(
              "scrollSpeedHorizontal",
              value,
              "onesquare_horizontal_scroll_speed",
              false,
              true
            );
            break;
          case "onesquare_vertical_scroll_speed":
            _this.setConfigValue(
              "scrollSpeedVertical",
              value,
              "onesquare_vertical_scroll_speed",
              false,
              true
            );
            break;
          case "onesquare_scroll_forward_timeout":
            _this.setConfigValue(
              "scrollForwardTimeout",
              value,
              "onesquare_scroll_forward_timeout",
              false,
              true
            );
            break;
          case "onesquare_scroll_backward_timeout":
            _this.setConfigValue(
              "scrollBackwardTimeout",
              value,
              "onesquare_scroll_backward_timeout",
              false,
              true
            );
            break;
          case "onesquare_scroll_backward_time":
            _this.setConfigValue(
              "scrollBackwardTime",
              value,
              "onesquare_scroll_backward_time",
              false,
              true
            );
            break;
          case "onesquare_use_channel_map":
            _this.setConfigValue(
              "useChannelMap",
              value,
              "onesquare_use_channel_map",
              true,
              false
            );
            break;
          case "onesquare_mod_seperator":
            _this.setConfigValue(
              "MOD_SEPERATOR",
              value,
              "onesquare_mod_seperator",
              false,
              false
            );
            break;
          case "onesquare_sleeptimer_steps":
            _this.setConfigValue(
              "sleeptimer_steps",
              value,
              "onesquare_sleeptimer_steps",
              false,
              true
            );
            break;
          case "onesquare_sleeptimer_max":
            _this.setConfigValue(
              "sleeptimer_max",
              value,
              "onesquare_sleeptimer_max",
              false,
              true
            );
            break;
          case "onesquare_show_loading_tv_group":
            _this.setConfigValue(
              "show_loading_tv_group",
              value,
              "onesquare_show_loading_tv_group",
              true,
              false
            );
            break;
          case "onesquare_show_loading_tv_all":
            _this.setConfigValue(
              "show_loading_tv_all",
              value,
              "onesquare_show_loading_tv_all",
              true,
              false
            );
            break;
          case "onesquare_tv_control_enabled":
            _this.setConfigValue(
              "tv_control_enabled",
              value,
              "onesquare_tv_control_enabled",
              true,
              false
            );
            break;
          case "onesquare_tv_control_protocol":
            _this.setConfigValue(
              "tv_control_protocol",
              value,
              "onesquare_tv_control_protocol",
              false,
              false
            );
            break;
          case "onesquare_tv_control_source":
            _this.setConfigValue(
              "tv_control_source",
              value,
              "onesquare_tv_control_source",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_enabled":
            _this.setConfigValue(
              "fokus_on_enabled",
              value,
              "onesquare_fokus_on_enabled",
              true,
              false
            );
            break;
          case "onesquare_fokus_on_server_ip":
            _this.setConfigValue(
              "fokus_on_server_ip",
              value,
              "onesquare_fokus_on_server_ip",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_proxy":
            _this.setConfigValue(
              "fokus_on_proxy",
              value,
              "onesquare_fokus_on_proxy",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_authentication_url":
            _this.setConfigValue(
              "fokus_on_authentication_url",
              value,
              "onesquare_fokus_on_authentication_url",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_rental_url":
            _this.setConfigValue(
              "fokus_on_rental_url",
              value,
              "onesquare_fokus_on_rental_url",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_auth_pin":
            _this.setConfigValue(
              "fokus_on_auth_pin",
              value,
              "onesquare_fokus_on_auth_pin",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_request_type":
            _this.setConfigValue(
              "fokus_on_request_type",
              value,
              "onesquare_fokus_on_request_type",
              false,
              false
            );
            break;
          case "onesquare_fokus_on_play_timeout":
            _this.setConfigValue(
              "fokus_on_play_timeout",
              value,
              "onesquare_fokus_on_play_timeout",
              false,
              true
            );
            break;
          case "onesquare_epg_synopsis_window":
            _this.setConfigValue(
              "epg_synopsis_window",
              value,
              "onesquare_epg_synopsis_window",
              true,
              false
            );
            break;
          case "onesquare_bill_item_date_format12":
            _this.setConfigValue(
              "bill_item_date_format12",
              value,
              "onesquare_bill_item_date_format12",
              false,
              false
            );
            break;
          case "onesquare_bill_item_date_format24":
            _this.setConfigValue(
              "bill_item_date_format24",
              value,
              "onesquare_bill_item_date_format24",
              false,
              false
            );
            break;
          case "onesquare_bill_item_time_format12":
            _this.setConfigValue(
              "bill_item_time_format12",
              value,
              "onesquare_bill_item_time_format12",
              false,
              false
            );
            break;
          case "onesquare_bill_item_time_format24":
            _this.setConfigValue(
              "bill_item_time_format24",
              value,
              "onesquare_bill_item_time_format24",
              false,
              false
            );
            break;
          case "onesquare_epg_timeline_date_format12":
            _this.setConfigValue(
              "epg_timeline_date_format12",
              value,
              "onesquare_epg_timeline_date_format12",
              false,
              false
            );
            break;
          case "onesquare_epg_timeline_date_format24":
            _this.setConfigValue(
              "epg_timeline_date_format24",
              value,
              "onesquare_epg_timeline_date_format24",
              false,
              false
            );
            break;
          case "onesquare_clock_date_format12":
            _this.setConfigValue(
              "clock_date_format12",
              value,
              "onesquare_clock_date_format12",
              false,
              false
            );
            break;
          case "onesquare_clock_date_format24":
            _this.setConfigValue(
              "clock_date_format24",
              value,
              "onesquare_clock_date_format24",
              false,
              false
            );
            break;
          case "onesquare_clock_time_format12":
            _this.setConfigValue(
              "clock_time_format12",
              value,
              "onesquare_clock_time_format12",
              false,
              false
            );
            break;
          case "onesquare_clock_time_format24":
            _this.setConfigValue(
              "clock_time_format24",
              value,
              "onesquare_clock_time_format24",
              false,
              false
            );
            break;
          case "onesquare_flight_info_date_format12":
            _this.setConfigValue(
              "flight_info_date_format12",
              value,
              "onesquare_flight_info_date_format12",
              false,
              false
            );
            break;
          case "onesquare_flight_info_date_format24":
            _this.setConfigValue(
              "flight_info_date_format24",
              value,
              "onesquare_flight_info_date_format24",
              false,
              false
            );
            break;
          case "onesquare_retail_opening_closing_time12":
            _this.setConfigValue(
              "retail_opening_closing_time12",
              value,
              "onesquare_retail_opening_closing_time12",
              false,
              false
            );
            break;
          case "onesquare_retail_opening_closing_time24":
            _this.setConfigValue(
              "retail_opening_closing_time24",
              value,
              "onesquare_retail_opening_closing_time24",
              false,
              false
            );
            break;
          case "onesquare_epg_synopsis_window_date12":
            _this.setConfigValue(
              "epg_synopsis_window_date12",
              value,
              "onesquare_epg_synopsis_window_date12",
              false,
              false
            );
            break;
          case "onesquare_epg_synopsis_window_date24":
            _this.setConfigValue(
              "epg_synopsis_window_date24",
              value,
              "onesquare_epg_synopsis_window_date24",
              false,
              false
            );
            break;
          case "onesquare_wake_up_time_format12":
            _this.setConfigValue(
              "wake_up_time_format12",
              value,
              "onesquare_wake_up_time_format12",
              false,
              false
            );
            break;
          case "onesquare_wake_up_time_format24":
            _this.setConfigValue(
              "wake_up_time_format24",
              value,
              "onesquare_wake_up_time_format24",
              false,
              false
            );
            break;
          case "onesquare_wake_up_widget_time_format12":
            _this.setConfigValue(
              "wake_up_widget_time_format12",
              value,
              "onesquare_wake_up_widget_time_format12",
              false,
              false
            );
            break;
          case "onesquare_wake_up_widget_time_format24":
            _this.setConfigValue(
              "wake_up_widget_time_format24",
              value,
              "onesquare_wake_up_widget_time_format24",
              false,
              false
            );
            break;
          case "onesquare_reservation_assistant_date_format12":
            _this.setConfigValue(
              "reservation_assistant_date_format12",
              value,
              "onesquare_reservation_assistant_date_format12",
              false,
              false
            );
            break;
          case "onesquare_reservation_assistant_date_format24":
            _this.setConfigValue(
              "reservation_assistant_date_format24",
              value,
              "onesquare_reservation_assistant_date_format24",
              false,
              false
            );
            break;
          case "onesquare_reservation_assistant_time_format12":
            _this.setConfigValue(
              "reservation_assistant_time_format12",
              value,
              "onesquare_reservation_assistant_time_format12",
              false,
              false
            );
            break;
          case "onesquare_reservation_assistant_time_format24":
            _this.setConfigValue(
              "reservation_assistant_time_format24",
              value,
              "onesquare_reservation_assistant_time_format24",
              false,
              false
            );
            break;
          case "onesquare_login_devices":
            _this.setConfigValue(
              "LOGIN_DEVICES",
              value,
              "onesquare_login_devices",
              false,
              false
            );
            _this.setLoginDevices(_this.LOGIN_DEVICES);
            break;
          case "onesquare_autostart":
            _this.setConfigValue(
              "autostart",
              value,
              "onesquare_autostart",
              false,
              false
            );
            break;
          case "onesquare_default_player":
            _this.setConfigValue(
              "DEFAULT_PLAYER",
              value,
              "onesquare_default_player",
              false,
              false
            );
            break;
          case "onesquare_module_player_tv":
            _this.setConfigValue(
              "MODULE_PLAYER.TV",
              value,
              "onesquare_module_player_tv",
              false,
              false
            );
            break;
          case "onesquare_module_player_vod":
            _this.setConfigValue(
              "MODULE_PLAYER.VOD",
              value,
              "onesquare_module_player_vod",
              false,
              false
            );
            break;
          case "onesquare_module_player_webradio":
            _this.setConfigValue(
              "MODULE_PLAYER.WEBRADIO",
              value,
              "onesquare_module_player_webradio",
              false,
              false
            );
            break;
          case "onesquare_module_player_skin":
            _this.setConfigValue(
              "MODULE_PLAYER.SKIN",
              value,
              "onesquare_module_player_skin",
              false,
              false
            );
            break;
          case "onesquare_module_player_welcome_screen":
            _this.setConfigValue(
              "MODULE_PLAYER.WELCOMESCREEN",
              value,
              "onesquare_module_player_welcome_screen",
              false,
              false
            );
            break;
          case "onesquare_module_player_websocket":
            _this.setConfigValue(
              "MODULE_PLAYER.WEBSOCKET",
              value,
              "onesquare_module_player_websocket",
              false,
              false
            );
            break;
          case "onesquare_module_player_epg":
            _this.setConfigValue(
              "MODULE_PLAYER.EPG",
              value,
              "onesquare_module_player_epg",
              false,
              false
            );
            break;
          case "onesquare_module_player_wake_up":
            _this.setConfigValue(
              "MODULE_PLAYER.WAKEUP",
              value,
              "onesquare_module_player_wake_up",
              false,
              false
            );
            break;
          case "onesquare_module_player_one_play":
            _this.setConfigValue(
              "MODULE_PLAYER.ONEPLAY",
              value,
              "onesquare_module_player_one_play",
              false,
              false
            );
            break;
          case "onesquare_module_player_mod":
            _this.setConfigValue(
              "MODULE_PLAYER.MOD",
              value,
              "onesquare_module_player_mod",
              false,
              false
            );
            break;
          case "onesquare_module_player_pvr":
            _this.setConfigValue(
              "MODULE_PLAYER.PVR",
              value,
              "onesquare_module_player_pvr",
              false,
              false
            );
            break;
          case "onesquare_vod_setup_empty_duration":
            _this.setConfigValue(
              "vod_setup.duration",
              value,
              "onesquare_vod_setup_empty_duration",
              false,
              false
            );
            break;
          case "onesquare_vod_setup_empty_actors":
            _this.setConfigValue(
              "vod_setup.actors",
              value,
              "onesquare_vod_setup_empty_actors",
              false,
              false
            );
            break;
          case "onesquare_vod_setup_empty_year":
            _this.setConfigValue(
              "vod_setup.year",
              value,
              "onesquare_vod_setup_empty_year",
              false,
              false
            );
            break;
          case "CURRENCY":
            _this.setConfigValue(
              "currency_long",
              value,
              "CURRENCY",
              false,
              false
            );
            break;
          case "CURRENCY_SHORT":
            _this.setConfigValue(
              "currency_short",
              value,
              "CURRENCY_SHORT",
              false,
              false
            );
            break;
          case "CURRENCY_POSITION":
            _this.setConfigValue(
              "currency_position",
              value,
              "CURRENCY_POSITION",
              false,
              false
            );
            break;
          case "price_decimal_precision":
            _this.setConfigValue(
              "price_decimal_precision",
              value,
              "price_decimal_precision",
              false,
              false
            );
            break;
          case "TV_FAVOURITES_ENABLE":
            _this.setConfigValue(
              "TV_FAVOURITES_ENABLE",
              value,
              "TV_FAVOURITES_ENABLE",
              true,
              false
            );
            break;
          case "TV_FAVOURITES_MIN_TIME":
            _this.setConfigValue(
              "autoTVChannelFavourite",
              value,
              "TV_FAVOURITES_MIN_TIME",
              false,
              false
            );
            break;
          case "SHOW_ALREADY_BOUGHT":
            _this.setConfigValue(
              "SHOW_ALREADY_BOUGHT",
              value,
              "SHOW_ALREADY_BOUGHT",
              true,
              false
            );
            break;
          case "PVR_MAX_RECORDING_DURATION":
            _this.setConfigValue(
              "PVR_MAX_RECORDING_DURATION",
              value,
              "PVR_MAX_RECORDING_DURATION",
              false,
              false
            );
            break;
          case "PVR_SMB_MEDIA_PATH":
            _this.setConfigValue(
              "PVR_SMB_MEDIA_PATH",
              value,
              "PVR_SMB_MEDIA_PATH",
              false,
              false
            );
            break;
          case "mngr_mini_epg_display_duration":
            _this.setConfigValue(
              "MNGR_MINI_EPG_DISPLAY_DURATION",
              value,
              "mngr_mini_epg_display_duration",
              false,
              false
            );
            break;
          case "mngr_mini_epg_enabled":
            _this.setConfigValue(
              "MNGR_MINI_EPG_ENABLED",
              value,
              "mngr_mini_epg_enabled",
              false,
              false
            );
            break;
          case "WAKEUP_DEFAULT_FILE":
            _this.setConfigValue(
              "WAKEUP_DEFAULT_FILE",
              value,
              "WAKEUP_DEFAULT_FILE",
              false,
              false
            );
            break;
          case "MOD_DIR":
            _this.setConfigValue("MOD_DIR", value, "MOD_DIR", false, false);
            break;
          case "VOD_DIR":
            _this.setConfigValue("VOD_DIR", value, "VOD_DIR", false, false);
            break;
          case "ONESQUARE_SECURITY_LEVEL":
            _this.setConfigValue(
              "ONESQUARE_SECURITY_LEVEL",
              value,
              "ONESQUARE_SECURITY_LEVEL",
              false,
              false
            );
            break;
          case "media_server_manager_fokuson_server_url":
            _this.setConfigValue(
              "media_server_manager_fokuson_server_url",
              value,
              "media_server_manager_fokuson_server_url",
              false,
              false
            );
            break;
          case "media_server_manager_fokuson_shop_id":
            _this.setConfigValue(
              "media_server_manager_fokuson_shop_id",
              value,
              "media_server_manager_fokuson_shop_id",
              false,
              false
            );
            break;
          case "WAKEUP_START_VOLUME":
            _this.setConfigValue(
              "WAKEUP_START_VOLUME",
              value,
              "WAKEUP_START_VOLUME",
              false,
              false
            );
            break;
          case "WAKEUP_VOLUME_STEP":
            _this.setConfigValue(
              "WAKEUP_VOLUME_STEP",
              value,
              "WAKEUP_VOLUME_STEP",
              false,
              false
            );
            break;
          case "WAKEUP_TIME_BETWEEN_STEPS":
            _this.setConfigValue(
              "WAKEUP_TIME_BETWEEN_STEPS",
              value,
              "WAKEUP_TIME_BETWEEN_STEPS",
              false,
              false
            );
            break;
          case "WAKEUP_SNOOZE_MINUTES":
            _this.setConfigValue(
              "WAKEUP_SNOOZE_MINUTES",
              value,
              "WAKEUP_SNOOZE_MINUTES",
              false,
              false
            );
            break;
          case "WAKEUP_MAX_VOLUME":
            _this.setConfigValue(
              "WAKEUP_MAX_VOLUME",
              value,
              "WAKEUP_MAX_VOLUME",
              false,
              false
            );
            break;
          case "onesquare_weather_cf_seperator":
            _this.setConfigValue(
              "weather_cf_seperator",
              value,
              "onesquare_weather_cf_seperator",
              false,
              false
            );
            break;
          case "onesquare_rss_title_text_seperator":
            _this.setConfigValue(
              "rss_title_text_seperator",
              value,
              "onesquare_rss_title_text_seperator",
              false,
              false
            );
            break;
          case "onesquare_rss_item_endtag":
            _this.setConfigValue(
              "rss_item_endtag",
              value,
              "onesquare_rss_item_endtag",
              false,
              false
            );
            break;
          case "onesquare_daily_reboot_enabled":
            _this.setConfigValue(
              "onesquare_daily_reboot_enabled",
              value,
              "onesquare_daily_reboot_enabled",
              true,
              false
            );
            break;
          case "onesquare_reboot_time":
            _this.setConfigValue(
              "onesquare_reboot_time",
              value,
              "onesquare_reboot_time",
              false,
              false
            );
            break;
          case "onesquare_reboot_fallback_time":
            _this.setConfigValue(
              "onesquare_reboot_fallback_time",
              value,
              "onesquare_reboot_fallback_time",
              false,
              false
            );
            break;
          case "onesquare_reboot_random_offset_in_minutes":
            _this.setConfigValue(
              "onesquare_reboot_random_offset_in_minutes",
              value,
              "onesquare_reboot_random_offset_in_minutes",
              false,
              true
            );
            break;
          case "onesquare_video_player_preview_position_x":
            _this.setConfigValue(
              "video_player_preview_position_x",
              value,
              "onesquare_video_player_preview_position_x",
              false,
              true
            );
            break;
          case "onesquare_video_player_preview_position_y":
            _this.setConfigValue(
              "video_player_preview_position_y",
              value,
              "onesquare_video_player_preview_position_y",
              false,
              true
            );
            break;
          case "LATE_CHECKOUT_START_TIME":
            _this.setConfigValue(
              "late_checkout_start_time",
              value,
              "LATE_CHECKOUT_START_TIME",
              false,
              false
            );
            break;
          case "LATE_CHECKOUT_END_TIME":
            _this.setConfigValue(
              "late_checkout_end_time",
              value,
              "LATE_CHECKOUT_END_TIME",
              false,
              false
            );
            break;
          case "SHOW_FOLIO_ON_CHECKOUT":
            _this.setConfigValue(
              "show_folio_on_checkout",
              value,
              "SHOW_FOLIO_ON_CHECKOUT",
              true,
              false
            );
            break;
          case "DISABLE_CHECKOUT_ON_EARLY_CHECKOUT":
            _this.setConfigValue(
              "disable_checkout_on_early_checkout",
              value,
              "DISABLE_CHECKOUT_ON_EARLY_CHECKOUT",
              true,
              false
            );
            break;
          case "DISABLE_CHECKOUT_ON_EARLY_CHECKOUT":
            _this.setConfigValue(
              "disable_checkout_on_early_checkout",
              value,
              "DISABLE_CHECKOUT_ON_EARLY_CHECKOUT",
              true,
              false
            );
            break;
          case "CHECKOUT_MAIL_ADDRESSES":
            _this.setConfigValue(
              "checkout_mail_addresses",
              value,
              "CHECKOUT_MAIL_ADDRESSES",
              false,
              false
            );
            break;
          case "APP_VOD_PATH":
            _this.setConfigValue(
              "app_vod_path",
              value,
              "APP_VOD_PATH",
              false,
              false
            );
            break;
          case "onesquare_tv_preview_enabled":
            _this.setConfigValue(
              "tv_preview_enabled",
              value,
              "onesquare_tv_preview_enabled",
              true,
              false
            );
            break;
          case "onesquare_tv_preview_start_delay":
            _this.setConfigValue(
              "tv_preview_start_delay",
              value,
              "onesquare_tv_preview_start_delay",
              false,
              true
            );
            break;
          case "onesquare_tv_preview_width":
            _this.setConfigValue(
              "tv_preview_width",
              value,
              "onesquare_tv_preview_width",
              false,
              true
            );
            break;
          case "onesquare_tv_preview_height":
            _this.setConfigValue(
              "tv_preview_height",
              value,
              "onesquare_tv_preview_height",
              false,
              true
            );
            break;
          case "onesquare_tv_preview_position_x":
            _this.setConfigValue(
              "tv_preview_position_x",
              value,
              "onesquare_tv_preview_position_x",
              false,
              true
            );
            break;
          case "onesquare_tv_preview_position_y":
            _this.setConfigValue(
              "tv_preview_position_y",
              value,
              "onesquare_tv_preview_position_y",
              false,
              true
            );
            break;
          case "shop_amount_indicator":
            _this.setConfigValue(
              "SHOP_AMOUNT_INDICATOR",
              value,
              "shop_amount_indicator",
              false,
              false
            );
            break;
          case "onesquare_remove_special_devices_called":
            _this.setConfigValue(
              "remove_special_devices_called",
              value,
              "onesquare_remove_special_devices_called",
              false,
              false
            );
            _this.setRemoveSpecialDevices(_this.remove_special_devices_called);
            break;
          case "USE_INTERFACE_FOR_VOD":
            _this.setConfigValue(
              "use_interface_for_vod",
              value,
              "USE_INTERFACE_FOR_VOD",
              true,
              false
            );
            break;
          case "onesquare_wakeup_default_file":
            _this.setConfigValue(
              "onesquare_wakeup_default_file",
              value,
              "onesquare_wakeup_default_file",
              false,
              false
            );
            break;
          case "TV_TYPE":
            _this.setConfigValue("tv_type", value, "TV_TYPE", false, false);
            break;
          case "radio_favourites_enabled":
            _this.setConfigValue(
              "radio_favourites_enabled",
              value,
              "radio_favourites_enabled",
              true,
              false
            );
            break;
          case "onesquare_background_music_stop_on_keypress":
            _this.setConfigValue(
              "backgroundMusicStopOnKeypress",
              value,
              "onesquare_background_music_stop_on_keypress",
              true,
              false
            );
            break;
          case "onesquare_background_music_volume":
            _this.setConfigValue(
              "backgroundMusicVolume",
              value,
              "onesquare_background_music_volume",
              false,
              true
            );
            break;
          case "onesquare_enable_snooze":
            _this.setConfigValue(
              "enableSnooze",
              value,
              "onesquare_enable_snooze",
              true,
              false
            );
            break;
          case "GREETTIME_1":
            _this.setConfigValue(
              "greetingWidgetTriggerValue1",
              value,
              "GREETTIME_1",
              false,
              false
            );
            break;
          case "GREETTIME_2":
            _this.setConfigValue(
              "greetingWidgetTriggerValue2",
              value,
              "GREETTIME_2",
              false,
              false
            );
            break;
          case "GREETTIME_3":
            _this.setConfigValue(
              "greetingWidgetTriggerValue3",
              value,
              "GREETTIME_3",
              false,
              false
            );
            break;
          case "PARENTAL_CONTROL_TYPE":
            _this.setConfigValue(
              "parental_control_type",
              value,
              "PARENTAL_CONTROL_TYPE",
              false,
              false
            );
            break;
          case "PARENTAL_CONTROL_PASS":
            _this.setConfigValue(
              "parental_control_pass",
              value,
              "PARENTAL_CONTROL_PASS",
              false,
              false
            );
            break;
          case "onesquare_onesocket_receive_timeout":
            _this.setConfigValue(
              "onesocket_receive_timeout",
              value,
              "onesquare_onesocket_receive_timeout",
              false,
              true
            );
            break;
          case "onesquare_tv_message_date_format12":
            _this.setConfigValue(
              "tv_message_date_format12",
              value,
              "onesquare_tv_message_date_format12",
              false,
              false
            );
            break;
          case "onesquare_tv_message_date_format24":
            _this.setConfigValue(
              "tv_message_date_format24",
              value,
              "onesquare_tv_message_date_format24",
              false,
              false
            );
            break;
          case "onesquare_bang_olufsen_shortcut_config":
            _this.setConfigValue(
              "bang_olufsen_shortcut_config",
              value,
              "onesquare_bang_olufsen_shortcut_config"
            );
            break;
          case "search_vod_type":
            _this.setConfigValue("search_vod_type", value, "search_vod_type");
            break;
          case "shop_additional_message_enabled":
            _this.setConfigValue(
              "shop_additional_message_enabled",
              value,
              "shop_additional_message_enabled",
              true
            );
            break;
          case "media_pool_item_play_path":
            _this.setConfigValue(
              "media_pool_item_play_path",
              value,
              "media_pool_item_play_path"
            );
            break;

          default:
            // statements_def
            break;
        }
      });
    },
    setConfigValue: function (
      internalKey,
      value,
      cmsKey,
      isBoolean,
      isInteger
    ) {
      if (!isEmpty(value)) {
        if (isBoolean) {
          this.$data[internalKey] = value == "true";
        } else if (isInteger) {
          this.$data[internalKey] = parseInt(value);
        } else {
          this.$data[internalKey] = value;
        }
      }
    },
    checkEmptyIp: function (ip) {
      return isEmpty(ip) ? Vue.http.options.root : ip;
    },
    /**
     * Split the weather locations string received from database
     * and return array of locations
     * Format must be valid
     *
     * @param str
     */
    setWeatherLocationsFromSystemValue: function () {
      var str = this.locations;
      if (typeof str === "string") {
        // lets get all locations
        var locationsArr = str.split("|");
        var locationStr = "";
        var locationProps;
        this.locations = [];
        for (var i = 0; i < locationsArr.length; i++) {
          locationStr = locationsArr[i];
          if (typeof locationStr === "string" && locationStr.length) {
            locationProps = locationStr.split(",");
            if (locationProps.length === 3) {
              var location = {};
              location.city = locationProps[0];
              location.country = locationProps[1];
              location.countryISO3166 = locationProps[2];
              this.locations.push(location);
            } else
              _this.$logger.warn(
                "config",
                "setWeatherLocationsFromSystemValue, wrong number of location properties!"
              );
          }
        }
      }
    },
    /**
     *  set login devices array from database
     *  string must be comma seperated for each login device
     *
     *  @param str
     */
    setLoginDevices: function (str) {
      var loginDevicesArr = [];
      if (typeof str === "string") loginDevicesArr = str.split(",");
      this.LOGIN_DEVICES = loginDevicesArr;
    },
    /**
     *  set remove devices array which are named like the special strings from database
     *  string must be comma seperated for each description
     *
     *  @param str
     */
    setRemoveSpecialDevices: function (str) {
      var removeSpecialDevicesArr = [];
      if (typeof str === "string") removeSpecialDevicesArr = str.split(",");
      this.remove_special_devices_called = removeSpecialDevicesArr;
    },
  },
});
