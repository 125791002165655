export default {
  MUTATE_WEEKDAYS(state, value) {
    state.weekdays = value;
  },
  POST_MAIL: (state) => {
    state.status = "fetching";
  },
  SUCCESS_POST_MAIL: (state) => {
    state.status = "success";
    state.canceled = true;
  },
  FAILURE_POST_MAIL: (state) => {
    state.status = "failure";
    state.canceled = false;
  },
  POST_TV_MESSAGE: (state) => {
    state.messageStatus = "fetching";
  },
  SUCCESS_POST_TV_MESSAGE: (state) => {
    state.messageStatus = "success";
  },
  FAILURE_POST_TV_MESSAGE: (state) => {
    state.messageStatus = "failure";
  },
  TOGGLE_SCHEDULE(state) {
    state.showSchedule = !state.showSchedule;
  },
};
