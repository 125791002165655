import each from "lodash/each";
import router from "@/router";
import Vue from "vue";

export default {
  getLanguageList: function ({ commit, rootState }) {
    const _this = this;

    return new Promise((resolve, reject) => {
      commit("REQUEST_LANGUAGE_LIST");
      this._vm.$onesocket.send({
        function: "getActiveLanguages",
        module: "SQMOD_SystemSettings",
        data: {},
        onMessage: function (data) {
          if (data.payload.status === "OK") {
            commit(
              "SUCCESS_REQUEST_LANGUAGE_LIST",
              data.payload.root.languages
            );

            each(data.payload.root.languages, (item) => {
              if (item.lang_key === _this._vm.$cookies.get("lang-key")) {
                commit("SET_SELECTED_LANGUAGE", item);
                return false;
              } else if (
                item.lang_key === rootState.session.browserLang ||
                item.lang_key === rootState.session.browserLang.substring(0, 2)
              ) {
                commit("SET_SELECTED_LANGUAGE", item);
              }
            });

            resolve();
          } else {
            commit("FAILURE_REQUEST_LANGUAGE_LIST");
            _this._vm.$logger.error(
              "language-store",
              "Invalid response from getActiveLanguages"
            );
            reject();
          }
        },
        onError: function (error) {
          commit("FAILURE_REQUEST_LANGUAGE_LIST");
          _this._vm.$logger.error(
            "language-store",
            "Failed to fetch getActiveLanguages",
            error
          );
        },
      });
    });
  },
  async setLanguage({ commit, dispatch, rootState }, { room, lang_key }) {
    if (!lang_key) {
      lang_key =
        this._vm.$cookies.get("lang-key") ||
        rootState.session.browserLang ||
        router.currentRoute.query.lang.toLowerCase();
    }

    const _this = this;

    if (rootState.language.key === lang_key) {
      return;
    }

    try {
      if (rootState.language.languageList.length < 1) {
        await dispatch("getLanguageList");
      }

      const languageByLangKey = rootState.language.languageList.filter(
        (item) => {
          return item.lang_key.toLowerCase() === lang_key.toLowerCase();
        }
      );

      try {
        Kommunicate.updateUserLanguage(lang_key);
      } catch (error) {}
      if (languageByLangKey && languageByLangKey.length > 0) {
        this._vm.$cookies.set("lang-key", lang_key);
        this._vm.$onesocket.send({
          function: "setLanguageOfRoom",
          module: "SQMOD_SystemSettings",
          data: {
            room: room,
            language: languageByLangKey[0].language,
          },
          onMessage: function (data) {
            if (data.payload.status === "OK") {
              commit("SUCCESS_SET_LANGUAGE", lang_key.toLowerCase());
              dispatch("weather/loadWeatherData", {}, { root: true });
              if (
                router.currentRoute &&
                router.currentRoute.name !== "login" &&
                rootState.language.selectedLanguage != null &&
                rootState.language.selectedLanguage.lang_key !== lang_key
              ) {
                commit("contentpage/RESET", null, { root: true });
                dispatch("session/reAuthenticate", null, { root: true });
              }
            } else {
              commit("FAILURE_SET_LANGUAGE", lang_key.toLowerCase());
              _this._vm.$logger.error(
                "language-store",
                "Invalid response from setLanguageOfRoom"
              );
            }
          },
          onError: function (error) {
            commit("FAILURE_SET_LANGUAGE", lang_key.toLowerCase());
            _this._vm.$logger.error(
              "language-store",
              "Failed to fetch setLanguageOfRoom",
              error
            );
          },
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  },
  deleteCookies() {
    this._vm.$cookies.remove("lang-key");
  },
};
