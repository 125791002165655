export default () => ({
  status: undefined,
  date: undefined,
  statusUpdateDeviceDetails: undefined,
  command: undefined,
  host: undefined,
  network: {
    ipAddress: undefined,
    macAddress: undefined,
    subnetMask: undefined,
    gatewayAddress: undefined,
  },
  volume: undefined,
  app: undefined,
  source: {
    source: "TV",
    port: undefined,
  },
  power: undefined,
  details: {
    firmware: "",
    os_type: "",
    onesquare_version: "",
    skin_version: "onesquareX",
  },
  screen: {
    width: undefined,
    height: undefined,
  },
  updateTimeInterval: undefined,
});
