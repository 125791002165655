export default {
  REQUEST_MOVIES(state) {
    state.statusMovies = "fetching";
    state.isFetchingMovies = true;
  },
  SUCCESS_REQUEST_MOVIES(state, value) {
    state.isFetchingMovies = false;
    state.statusMovies = "success";
    state.allMovies = value;
  },
  FAILURE_REQUEST_MOVIES(state) {
    state.isFetchingMovies = false;
    state.statusMovies = "failure";
    state.allMovies = [];
  },
  REQUEST_GENRES(state) {
    state.statusGenres = "fetching";
    state.isFetchingGenres = true;
  },
  SUCCESS_REQUEST_GENRES(state, value) {
    state.statusGenres = "success";
    state.allGenres = value.genres;
    state.isFetchingGenres = false;
  },
  FAILURE_REQUEST_GENRES(state) {
    state.statusGenres = "failure";
    state.isFetchingGenres = false;
    state.allGenres = [];
  },
  REQUEST_PACKAGES(state) {
    state.statusPackages = "fetching";
    state.isFetchingPackages = true;
  },
  FAILURE_REQUEST_PACKAGES(state) {
    state.statusPackages = "failure";
    state.isFetchingPackages = false;
    state.allPackages = [];
  },
  SUCCESS_REQUEST_PACKAGES(state, value) {
    state.statusPackages = "success";
    state.isFetchingPackages = false;
    state.allPackages = value;
  },
  REQUEST_PACKAGE_MOVIES(state) {
    state.isFetchingPackageMovies = true;
    state.statusPackageMovies = "fetching";
  },
  SUCCESS_REQUEST_PACKAGE_MOVIES(state, value) {
    state.isFetchingPackageMovies = false;
    state.statusPackageMovies = "success";
    state.moviesOfPackage = value;
  },
  FAILURE_REQUEST_PACKAGE_MOVIES(state) {
    state.isFetchingPackageMovies = false;
    state.statusPackageMovies = "failure";
    state.moviesOfPackage = [];
  },
  REQUEST_MOVIE_DETAILS(state) {
    state.isFetchingMovieDetails = true;
    state.statusMovieDetails = "fetching";
  },
  SUCCESS_REQUEST_MOVIE_DETAILS(state, value) {
    state.isFetchingMovieDetails = false;
    state.statusMovieDetails = "success";
    state.movieDetails = value;
  },
  FAILURE_REQUEST_MOVIE_DETAILS(state) {
    state.isFetchingMovieDetails = false;
    state.statusMovieDetails = "failure";
    state.movieDetails = {};
  },
  REQUEST_BUY_PACKAGE(state) {
    state.statusBuyProcess = "fetching";
  },
  SUCCESS_REQUEST_BUY_PACKAGE(state) {
    state.statusBuyProcess = "success";
  },
  FAILURE_REQUEST_BUY_PACKAGE(state) {
    state.statusBuyProcess = "failure";
  },
  REQUEST_ADD_RESUME_TIME(state) {
    state.statusResumeTime = "fetching";
  },
  SUCCESS_REQUEST_ADD_RESUME_TIME(state) {
    state.statusResumeTime = "success";
  },
  FAILURE_REQUEST_ADD_RESUME_TIME(state) {
    state.statusResumeTime = "failure";
  },
  REQUEST_VOUCHERS(state) {
    state.statusVouchers = "fetching";
  },
  SUCCESS_REQUEST_VOUCHERS(state, value) {
    state.statusVouchers = "success";
    state.vouchers = value;
  },
  FAILURE_REQUEST_VOUCHERS(state) {
    state.statusVouchers = "failure";
    state.vouchers = [];
  },
  SET_SHOW_FILTER(state, value) {
    state.showFilter = value;
  },
  REQUEST_PACKAGE_BY_ID(state) {
    state.statusPackageById = "fetching";
  },
  SUCCESS_REQUEST_PACKAGE_BY_ID(state, value) {
    state.statusPackageById = "success";
    state.packageById = value.packages[0];
  },
  FAILURE_REQUEST_PACKAGE_BY_ID(state) {
    state.statusPackageById = "failure";
  },
  SET_BUY_DIALOG(state, value) {
    state.buyDialogState = value;
  },
};
