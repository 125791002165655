import filter from "lodash/filter";

export default {
  //only works in landingpage
  filteredList: (state) => {
    return filter(state.data.list, (item) => {
      return item.Header || item.Description || item.Image;
    });
  },
};
