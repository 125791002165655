import axios from "axios";

export default {
  async getRoomControl({ commit, rootState }) {
    commit("FETCHING_GET_ROOMCONTROL");
    const { propertyId, authResult } = rootState.session;

    try {
      let request = await axios.get(
        `/api/public/v2/property/${propertyId}/oneapp/roomcontrol`
      );
      const helper = request.data.assignment.filter((el) => {
        var room = el.room;

        if (room.includes("lr")) {
          room = room.replace("lr", "");
        } else if (room.includes("br")) {
          room = room.replace("br", "");
        } else if (room.includes("bath")) {
          room = room.replace("bath", "");
        }

        return room === authResult.checkin[0].room ? true : false;
      });
      var assignment = helper[0];
      if (helper.length > 1) {
        for (var x = 1; x < helper.length; x++) {
          assignment.assignedCommandIds.push(helper[x].assignedCommandIds);
        }
      }
      assignment.assignedCommandIds = assignment.assignedCommandIds.flat();
      if (!assignment) {
        commit("SUCCESS_GET_ROOMCONTROL", { items: [] });
        return;
      }
      const allowedCommandMap = assignment.assignedCommandIds.reduce(
        (acc, el) => {
          acc[el] = request.data.command.filter((cmd) => cmd.id === el)[0];
          return acc;
        },
        {}
      );

      const parentGroups = (request.data.group || [])
        .filter(
          (el) => !el.parentGroupId || el.parentGroupId.indexOf("00000") === 0
        ) // either empty or zeros
        .map((el) => {
          const command = el.assignedCommandIds
            .map((el) => allowedCommandMap[el])
            .filter((el) => el);
          const assignedCommandIds = command.map((el) => el.id);
          return { ...el, assignedCommandIds, command };
        });
      const childGroups = (request.data.group || [])
        .filter(
          (el) => el.parentGroupId && el.parentGroupId.indexOf("00000") !== 0
        )
        .map((el) => {
          const command = el.assignedCommandIds
            .map((el) => allowedCommandMap[el])
            .filter((el) => el);
          const assignedCommandIds = command.map((el) => el.id);
          return { ...el, assignedCommandIds, command };
        })
        .filter((el) => el.assignedCommandIds);

      const items = parentGroups
        .map((el) => {
          const child = childGroups
            .filter((child) => child.parentGroupId === el.id)
            .filter((el) => el.assignedCommandIds.length);
          return { ...el, child };
        })
        .filter((el) => el.child.length || el.assignedCommandIds.length);

      // new format:
      // parent-group
      // -> child-groups
      // --> commands
      commit("SUCCESS_GET_ROOMCONTROL", { items });
      commit("SET_STATE", request.data.state);
    } catch (error) {
      commit("FAILURE_GET_ROOMCONTROL");
      console.error(error);
    }
  },
  async setRoomControl({ rootState, commit }, { item }) {
    commit("FETCHING_SET_ROOMCONTROL");
    const { propertyId } = rootState.session;
    const room = rootState.session.authResult.room;
    const newState = { ...rootState.roomControl.state };
    newState[`state.${room}-${item.id}`] = item.value;
    try {
      await axios.post(
        `/api/public/v2/property/${propertyId}/oneapp/roomcontrol`,
        { room, command: item }
      );

      commit("SUCCESS_SET_ROOMCONTROL");
      commit("SET_STATE", newState);
    } catch (error) {
      commit("FAILURE_SET_ROOMCONTROL");
      console.error(error);
    }
  },
};
