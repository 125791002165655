import Vue from "vue";

export default {
  SET_STATUS(state, value) {
    state.status = value;
  },
  SET_COMMAND(state, value) {
    state.command = value;
  },
  SET_HOST(state, value) {
    Vue.http.options.root = value;
    state.host = value;
  },
  SET_SCREEN(state, { width = 0, height = 0 }) {
    state.width = width;
    state.height = height;
  },
  SET_APP(state, value) {
    state.app = value;
  },
  SET_SOURCE(state, value) {
    state.source = value;
  },
  UPDATE_TIME(state, value) {
    state.date = new Date(value);
  },
  SET_NETWORK(
    state,
    {
      ipAddress = "none",
      macAddress = "aa:bb:cc:dd:ee:ff",
      subnetMask = "none",
      gatewayAddress = "none",
    }
  ) {
    state.network = {
      ipAddress,
      macAddress,
      subnetMask,
      gatewayAddress,
    };
  },
  SET_KEY_TABLE(state, value) {
    state.keyTable = value;
  },
  SET_POWER_STATE(state, value) {
    state.power = value;
  },
  SET_VOLUME(state, value) {
    state.volume = value;
  },
  REQUEST_DEVICE_DETAILS(state) {
    state.statusUpdateDeviceDetails = "fetching";
  },
  SUCCESS_REQUEST_DEVICE_DETAILS(state) {
    state.statusUpdateDeviceDetails = "success";
  },
  FAILURE_REQUEST_DEVICE_DETAILS(state) {
    state.statusUpdateDeviceDetails = "failure";
  },
};
