import router from "@/router";

export default {
  REQUEST_SEARCH: (state) => {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_SEARCH: (state, value = []) => {
    router.push({ query: { q: state.searchTerm } });
    state.status = "success";
    state.result = value;
  },
  FAILURE_REQUEST_SEARCH: (state) => {
    state.status = "failure";
    state.result = [];
  },
  SET_SEARCH_TERM: (state, value = "") => {
    state.searchTerm = value;
  },
  SET_TYPE: (state, { type = "" }) => {
    router.push({ query: { "q-type": type } });
    state.type = type;
  },
  SET_TYPE_IDX: (state, value) => {
    state.typeIdx = value;
  },
  SET_ALL_TV_ITEMS: (state, value) => {
    state.allTvItems = value;
  },
};
