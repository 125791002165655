<template>
  <v-btn v-bind="$attrs" @click.prevent @click.native.prevent>
    <span>{{ text }}</span>
    <v-badge v-if="badgeCnt > 0" :value="badgeCnt">
      <template v-slot:badge>{{ badgeCnt }}</template>
      <v-icon>{{ icon }}</v-icon>
    </v-badge>
    <v-icon v-else-if="icon.length > 0">{{ icon }}</v-icon>
  </v-btn>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
    icon: {
      type: String,
      default: "",
    },
    badgeCnt: {
      type: Number,
      default: 0,
    },
  },
};
</script>
