export default {
  RESET: (state) => {
    state.template = undefined;
    state.script = undefined;
    state.style = undefined;
    state.data = undefined;
    state.config = {};
  },
  REQUEST_DATA(state, value) {
    state.status = "fetching";
    state.id = value;
  },
  SUCCESS_REQUEST_DATA(state, { template, script, style, data, config = {} }) {
    state.template = template;
    state.script = script;
    state.style = style;
    state.data = data;
    state.config = config;
    state.status = "success";
  },
  FAILURE_REQUEST_DATA(state) {
    state.status = "failure";
  },
  REQUEST_BANNER_DATA: (state) => {
    state.statusBanner = "fetching";
  },
  SUCCESS_REQUEST_BANNER_DATA: (state, value) => {
    state.bannerList.push(value);
    state.statusBanner = "success";
  },
  FAILURE_REQUEST_BANNER_DATA: (state) => {
    state.bannerList = [];
    state.statusBanner = "failure";
  },
  RESET_BANNER_DATA: (state) => {
    state.bannerList = [];
  },
  REQUEST_PAGE_ID: (state, value) => {
    state.requestedPageId = value;
  },
  RESOLVE_PAGE_ID: (state) => {
    state.statusResolvePageId = "fetching";
  },
  SUCCESS_RESOLVE_PAGE_ID: (state, value) => {
    state.statusResolvePageId = "success";
    state.resolvePageIdResult = value;
  },
  FAILURE_RESOLVE_PAGE_ID: (state) => {
    state.statusResolvePageId = "failure";
  },
  RESET_PAGE_ID: (state) => {
    state.requestedPageId = undefined;
    state.statusResolvePageId = undefined;
    state.resolvePageIdResult = undefined;
  },
};
