import router from "@/router";
import isFunction from "lodash/isFunction";
import isObject from "lodash/isObject";
import isEmpty from "lodash/isEmpty";
import store from "@/store";
import Vue from "vue";

export function getModuleRoute(onclick) {
  try {
    const onclickSplit = onclick.split("|");
    switch (onclickSplit[0]) {
      case "tvOpen":
        if (onclickSplit[1] === "all") {
          return {
            name: "tvall",
            query: {
              onclickVal: onclickSplit[2],
            },
          };
        } else if (onclickSplit[1] === "group") {
          return {
            name: "tvgroup",
            query: {
              onclickVal: onclickSplit[2],
            },
          };
        }
        break;
      case "openWeather":
        return {
          name: "weather",
          params: {
            index: 0,
          },
        };
        break;
      case "webradioOpen":
        return {
          name: "webradio",
        };
        break;
      case "SleepTimerOpen":
        return {
          name: "sleeptimer",
        };
        break;
      case "wakeUpOpen":
        return {
          name: "wakeUp",
        };
        break;
      case "streamingDevices":
        return {
          name: "streamingdevices",
          params: {
            type: onclickSplit[1],
          },
        };
        break;
      case "billPreviewOpen":
        return {
          name: "billpreview",
        };
        break;
      case "openFlightInfo":
        return {
          name: "flightinfo",
        };
        break;
      case "languageSettingsOpen":
        return {
          name: "language",
        };
        break;
      case "openShop":
        return {
          name: "shop",
          params: {
            id: onclickSplit[1],
          },
        };
        break;
      case "OpenSkinEPG":
        return {
          name: "epg",
        };
        break;
      case "changeSource":
        store.dispatch("device/setSource", {
          source: onclickSplit[1],
          port: onclickSplit[2],
        });
        return {};
        break;
      case "openWebApp":
        store.dispatch("device/startApp", { name: onclickSplit[1] });
        return {};
        break;
      case "openScreenMirroring":
        store.dispatch("device/startApp", {
          name: "screenmirroring",
        });
        return {};
        break;
      case "tvMessagesOpen":
        return {
          name: "tvMessage",
        };
        break;
      case "billPreviewOpen":
        return {
          name: "billpreview",
        };
        break;
      case "openWebPage":
        return {
          name: "openWebPage",
          params: {
            link: onclickSplit[1],
          },
        };
        break;
      case "openTel":
        return {
          name: "openTel",
          params: {
            tel: onclickSplit[1],
          },
        };
        break;
      case "openEmail":
        return {
          name: "openEmail",
          params: {
            mailto: onclickSplit[1],
          },
        };
        break;
      case "openSearch":
        return {
          name: "search",
        };
        break;
      default:
        if (
          isFunction(router.getMatchedComponents(onclick)[0]) ||
          isObject(router.getMatchedComponents(onclick)[0]) ||
          (router.getMatchedComponents(onclick).name &&
            router.getMatchedComponents(onclick).name.length > 0)
        ) {
          return onclick;
        }

        return {};
        break;
    }
  } catch (e) {
    Vue.logger.error("getModuleRoute", e.message);
    return {};
  }
}

export function getContentpageRoute(contentpage) {
  try {
    var id = /\d+/.exec(contentpage); // cut off "Custom_"
    if (!isEmpty(id)) {
      return {
        name: "contentpage",
        params: {
          cpId: id[0],
        },
      };
    } else {
      return {};
    }
  } catch (e) {
    return {};
  }
}

export function getRoute({ item = {}, mode }) {
  if (item.isPage) {
    let contentpageRoute = getContentpageRoute(item.onclick);
    return router.getMatchedComponents(contentpageRoute).length > 0
      ? contentpageRoute
      : "";
  } else {
    const { onclick, oneapp_onclick } = item;
    if ((mode === "walkin" || mode === "walkin-only") && !isEmpty(onclick)) {
      let moduleRoute = getModuleRoute(onclick);
      return router.getMatchedComponents(moduleRoute).length > 0
        ? moduleRoute
        : "";
    } else if (!isEmpty(oneapp_onclick)) {
      let moduleRoute = getModuleRoute(oneapp_onclick);
      return router.getMatchedComponents(moduleRoute).length > 0
        ? moduleRoute
        : "";
    }

    return "";
  }
}

export function getRouteIcon(item = {}, mode) {
  const { onclick, oneapp_onclick } = item;
  if ((mode === "walkin" || mode === "walkin-only") && !isEmpty(onclick)) {
    return (
      router.resolve(getModuleRoute(onclick)).route.meta.icon ||
      "mdi-library-books"
    );
  } else if (!isEmpty(oneapp_onclick)) {
    return (
      router.resolve(getModuleRoute(oneapp_onclick)).route.meta.icon ||
      "mdi-library-books"
    );
  }

  return "";
}
