export default {
  SET_ROOM_NR(state, value) {
    state.roomNr = value;
  },
  SET_SELECTED_TV(state, value) {
    state.selectedTv = value;
  },
  SET_SPECIAL_DEVICES_CALLED(state, value) {
    state.specialDevicesCalled = value;
  },
  REQUEST_TVS(state) {
    state.statusRequestTvs = "fetching";
  },
  FAILURE_REQUEST_TVS(state) {
    state.statusRequestTvs = "failure";
  },
  SUCCESS_REQUEST_TVS(state, value) {
    state.tvs = value;
    state.statusRequestTvs = "success";
  },
  REQUEST_SEND_COMMAND(state) {
    state.statusSendCmd = "fetching";
  },
  FAILURE_REQUEST_SEND_COMMAND(state) {
    state.statusSendCmd = "failure";
  },
  SUCCESS_REQUEST_SEND_COMMAND(state) {
    state.statusSendCmd = "success";
  },
  SET_NO_TV(state, value) {
    state.noTv = value;
  },
  SET_ONLY_ONE_TV(state, value) {
    state.onlyOneTv = value;
  },
};
