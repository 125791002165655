import axios from "axios";
import router from "@/router";
const { DateTime } = require("luxon");

export default {
  async openDoor({ rootState }, { roomNumber, otpEmail } = {}) {
    const { propertyId } = rootState.session;

    const {
      room,
      reservation_nr,
      checkout,
    } = rootState.session.authResult.checkin[0];

    if (roomNumber) {
      // remove opendoor query after saving it to the roomNumber variable

      router.push({ query: { opendoor: undefined } });
    } else {
      roomNumber = room;
    }

    const { vendor, saltoUseShip } = rootState.session.settings.onekey;

    let checkoutDate = checkout + "T18:00:00.000Z"; // "T18:00:00.000Z" needed for type casting purposes.

    let today = new DateTime.fromJSDate(new Date()).toJSDate();
    let tomorrow = new DateTime.fromJSDate(new Date())
      .plus({ days: 1 })
      .toJSDate();

    if (!checkout) {
      checkoutDate = tomorrow;
    }

    if (propertyId && today.getTime() < new Date(checkoutDate).getTime()) {
      if (vendor === "salto" && saltoUseShip) {
        let sessionParams = {
          room: roomNumber,
          vendor: "salto",
          device: "android",
          reservationNumber: reservation_nr,
          expireAt: checkoutDate,
        };

        try {
          let session = await axios.post(
            `/api/public/v2/property/${propertyId}/onekey/session`,
            sessionParams
          );
          const tokenId = session.data.tokenId;

          let login = await axios.post("/api/onekey/auth/login", {
            id: tokenId,
          });
          const token = login.data.token;

          await axios.get("/api/onekey/salto-open-door", {
            headers: { Authorization: `Bearer ${token}` },
          });
        } catch (error) {
          console.error(error);
        }
      } else {
        const isIos =
          /iphone/i.test(navigator.userAgent) ||
          /ipad/i.test(navigator.userAgent) ||
          /macintosh/i.test(navigator.userAgent);
        const sessionParams = {
          room: roomNumber,
          vendor: vendor,
          device: isIos ? "ios" : "android",
          reservationNumber: reservation_nr,
          expireAt: checkoutDate,
          otpEmail: otpEmail,
        };
        try {
          const response = await axios.post(
            `/api/public/v2/property/${propertyId}/onekey/deeplink`,
            sessionParams
          );
          window.location.href = response.data.link;
        } catch (error) {
          console.error(error);
        }
      }
    }
  },
};
