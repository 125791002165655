// TODO: needs to be refactored before it can be used

import ReconnectingWebSocket from "reconnecting-websocket";
import Vue from "vue";
import find from "lodash/find";
import isEmpty from "lodash/isEmpty";
import isUndefined from "lodash/isUndefined";
import isFinite from "lodash/isFinite";
import isFunction from "lodash/isFunction";
import isNaN from "lodash/isNaN";
import isNull from "lodash/isNull";

import store from "@/store";
import { mapState, mapActions, mapMutations } from "vuex";
import router from "../router";
export default new Vue({
  store,
  data: function () {
    return {
      status: undefined,
      connected: false,
      connection: undefined,
      connections: [],
      ip: "",
      port: "",
      messageIds: new Array(),
      onMessages: new Array(),
      onCloses: new Array(),
      onErrors: new Array(),
      onTimeouts: new Array(),
      stx: String.fromCharCode(0x02),
      etx: String.fromCharCode(0x03),
      globalQueue: [],
      timeouts: [],
      receiveTimeout: 6000,
      modulePorts: {
        SQMOD_RetailSystem: 22200,
        SQMOD_SystemSettings: 22201,
        SQMOD_RemotePlayer: 22202,
        SQMOD_RoomInformation: 22203,
        SQMOD_ONEplay: 22204,
        SQMOD_GlobalFunctions: 22205,
        SQMOD_Tv_Messages: 22206,
        SQMOD_MusicOnDemand: 22207,
        SQMOD_RoomControl: 22208,
        SQMOD_VideoOnDemand: 22209,
        SQMOD_PopUp: 22210,
        SQMOD_RemoteControl: 22211,
        SQMOD_QuickCheckout: 22212,
        SQMOD_Radio: 22213,
        SQMOD_LiveTV: 22214,
        SQMOD_Search: 22215,
        SQMOD_InSingLocalEvents: 22216,
        SQMOD_Streaming: 22217,
        SQMOD_WakeUp: 22218,
        SQMOD_ReservationAssist: 22219,
        SQMOD_Auth: 22220,
        SQMOD_Weather: 22221,
        SQMOD_BillPreview: 22222,
        SQMOD_Statistic: 22223,
      },
    };
  },
  computed: {
    ...mapState({
      macAddress: ({ device }) => device.network.macAddress,
    }),
  },
  created: function () {},
  methods: {
    ...mapMutations("session", {
      setOnesocketConnected: "SET_ONESOCKET_CONNECTED",
    }),
    ...mapActions("device", ["reboot"]),
    ...mapActions("tvmessage", ["newTvMessage"]),
    install: function () {
      const _this = this;
      Object.defineProperty(Vue.prototype, "$onesocket", {
        get() {
          return _this;
        },
      });
      Vue.onesocket = this;
    },
    init: function (callback) {
      return new Promise((resolve, reject) => {
        this.ip = document.location.host;
        this.receiveTimeout = this.$config.onesocket_receive_timeout || 60000;
        // For LG NetCast models we need to add 10 to the portnumber and use
        // the HIXIE-76 websocket implementation
        if (
          this.$config.device == "lg" &&
          this.$config.device_platform == "netcast"
        ) {
          this.port = (
            parseInt(this.$config.ONEsocketConnectionPort) + 10
          ).toString();
        } else {
          this.port = this.$config.ONEsocketConnectionPort;
        }
        // automatic connect
        this.messageIds["lastMessageId"] = 0;
        // connect using reconnecting websocket and trigger callback onOpen connection
        this.connect((success) => {
          if (success) {
            this.status = "success";
            resolve();
          } else {
            this.status = "failure";
            reject(new Error("Failed to initialize onesocket"));
          }
        });
      });
    },
    /**
     * create message to send via websocket
     *
     * @param messageObject
     */
    createMessage: function (messageObject) {
      var mso = messageObject;
      var msg = "";
      if (!isEmpty(mso)) {
        // instantiate all object variables with fallback values
        var fn = !isEmpty(mso["function"]) ? mso["function"] : undefined;
        var cache_time = isFinite(mso["cache_time"]) ? mso["cache_time"] : 0;
        var message_id = isFinite(mso["message_id"])
          ? mso["message_id"]
          : ++this.messageIds["lastMessageId"];
        var module = !isEmpty(mso["module"]) ? mso["module"] : undefined;
        var messageData = !isUndefined(mso["data"]) ? mso["data"] : undefined;
        if (fn === undefined) {
          this.$logger.info("ONESOCKET", "message function is undefined!");
          return;
        } else if (module === undefined) {
          this.$logger.info("ONESOCKET", "module is undefined!");
          return;
        } else if (messageData === undefined) {
          this.$logger.info("ONESOCKET", "message data is undefined!");
          return;
        } else {
          var port = this.getPortByModule(module);
          var stringifiedData = this.getStringifiedMessageObject(messageData);
          this.messageIds["lastMessageId"] = message_id;
          this.messageIds[message_id] = true;
          // createMessage
          msg =
            this.stx +
            '{"dispatch": {' +
            '"module": "' +
            module +
            '",' +
            '"function": "' +
            fn +
            '",' +
            '"port": "' +
            port +
            '",' +
            '"cache_time": "' +
            cache_time +
            '",' +
            '"message_id": "' +
            message_id +
            '"' +
            "}," +
            '"payload": {' +
            '"status": "OK",' +
            '"root": ' +
            stringifiedData +
            "}" +
            "}" +
            this.etx;
        }
      }
      return msg;
    },
    getPortByModule: function (module) {
      var port = this.modulePorts[module];
      if (isUndefined(port)) {
        this.$logger.error("ONESOCKET", "unknown port for module " + module);
        port = 0;
      }
      return port;
    },
    /**
     * @param object
     */
    getStringifiedMessageObject: function (object) {
      // get json stringified object to pass via websocket
      return JSON.stringify(object);
    },
    startTimeout: function (message_id) {
      const _this = this;
      this.timeouts.push({
        message_id: message_id,
        t: setTimeout(function () {
          _this.sendOnTimeoutCallback(message_id, {});
        }, this.receiveTimeout),
      });
    },
    /**
     * @param msgObject
     * @param connectionObject
     */
    send: function (msgObject, connectionObject) {
      if (isUndefined(msgObject)) {
        this.$logger.info("ONESOCKET", "send triggered undefined");
      }

      var msg = this.createMessage(msgObject);
      var connection = undefined;
      connectionObject =
        connectionObject || this.connections[this.ip + ":" + this.port];
      // check if connectionObject is set to continue sending request
      if (!isUndefined(connectionObject)) {
        connection = connectionObject.connection;
      }
      if (!isUndefined(connection) && msg.length !== 0) {
        // set onMessage callback for message_id
        var message_id = this.messageIds.length - 1;
        var onMessage = msgObject.onMessage;
        if (isFunction(onMessage)) {
          var callbackObj = {
            message_id: message_id,
            onMessage: onMessage,
          };
          this.onMessages[message_id] = callbackObj;
        }
        // set onError callback for message_id
        var onError = msgObject.onError;
        if (isFunction(onError)) {
          var callbackObj = {
            message_id: message_id,
            onError: onError,
          };
          this.onErrors[message_id] = callbackObj;
        }
        // set onError callback for message_id
        var onClose = msgObject.onClose;
        if (isFunction(onClose)) {
          var callbackObj = {
            message_id: message_id,
            onClose: onClose,
          };
          this.onCloses[message_id] = callbackObj;
        }
        var onTimeout = msgObject.onTimeout;
        if (isFunction(onTimeout)) {
          var callbackObj = {
            message_id: message_id,
            onTimeout: onTimeout,
          };
          this.onTimeouts[message_id] = callbackObj;
        }
        // check if websocket connection is still legal -> proceed with sending message
        var readyState = connection.readyState;
        this.$logger.debug("ONESOCKET", "connection readyState: " + readyState);
        if (readyState === 1) {
          connection.send(msg);
          if (!isUndefined(this.onTimeouts[message_id])) {
            this.startTimeout(message_id);
          }
        } else {
          var onError = this.onErrors[message_id];
          if (onError) {
            var callbackObj = onError;
            if (!isUndefined(callbackObj)) {
              this.$logger.info(
                "ONESOCKET",
                "sendOnErrorCallback to " + message_id
              );
              callbackObj.onError(event);
            }
          }
          // put request to queue and start sending requests when websocket has successfully reconnected
          this.putToQueue(msgObject, connectionObject);
        }
        return message_id;
      } else if (msg.length !== 0) {
        // put request to queue when no connection is to ip:port is established
        this.putToQueue(msgObject, connectionObject);
      }
      return -1;
    },
    /**
     * @param sendOnMessageCallback
     */
    sendOnMessageCallback: function (message_id, event) {
      this.triggerIncomingMessage(event);
      var callbackObj = this.onMessages[message_id];
      if (!isUndefined(callbackObj)) {
        // this.$logger.info('ONESOCKET', 'sendOnMessageCallback to ' + message_id)
        callbackObj.onMessage(event);
      } else {
        this.$logger.info(
          "ONESOCKET",
          "no message callback set for message_id: " + message_id
        );
      }
      // clear message_id -> lastMessageId will be cleared when reconnecting
      this.clearMessages(message_id);
    },
    /**
     * @param sendOnErrorCallback
     */
    sendOnErrorCallback: function (message_id, event) {
      if (isFinite(message_id)) {
        var callbackObj = this.onErrors[message_id];
        if (!isUndefined(callbackObj)) {
          this.$logger.info(
            "ONESOCKET",
            "sendOnErrorCallback to " + message_id
          );
          callbackObj.onError(event);
        }
        // clear message_id -> lastMessageId will be cleared when reconnecting
        this.clearMessages(message_id);
      } else {
        const _this = this;
        this.onErrors.forEach(function (entry) {
          if (!isUndefined(entry)) {
            _this.$logger.info(
              "ONESOCKET",
              "sendOnErrorCallback to " + message_id
            );
            entry.onError(event);
          }
        });
      }
    },
    /**
     * @param sendOnErrorCallback
     */
    sendOnTimeoutCallback: function (message_id, event) {
      if (isFinite(message_id)) {
        var callbackObj = this.onTimeouts[message_id];
        if (!isUndefined(callbackObj)) {
          this.$logger.info(
            "ONESOCKET",
            "sendOnTimeoutCallback to " + message_id
          );
          callbackObj.onTimeout(event);
        }
        // clear message_id -> lastMessageId will be cleared when reconnecting
        this.clearMessages(message_id);
      } else {
        const _this = this;
        this.onTimeouts.forEach(function (entry) {
          if (!isUndefined(entry)) {
            _this.$logger.info(
              "ONESOCKET",
              "sendOnTimeoutCallback to " + message_id
            );
            _this.clearTimeout(entry.t);
            entry.onTimeout(event);
          }
        });
      }
    },
    /**
     * @param sendOnCloseCallback
     */
    sendOnCloseCallback: function (message_id, event) {
      if (isFinite(message_id)) {
        var callbackObj = this.onCloses[message_id];
        if (!isUndefined(callbackObj)) {
          this.$logger.info(
            "ONESOCKET",
            "sendOnCloseCallback to " + message_id
          );
          callbackObj.onClose(event);
        }
        // clear message_id -> lastMessageId will be cleared when reconnecting
        this.clearMessages(message_id);
      } else {
        const _this = this;
        this.onCloses.forEach(function (entry) {
          if (!isUndefined(entry)) {
            _this.$logger.info(
              "ONESOCKET",
              "sendOnCloseCallback to " + message_id
            );
            entry.onClose(event);
          }
        });
      }
    },
    /**
     * @param event
     *
     * triggers a handler to onesocketoperator for incoming messages
     */
    triggerIncomingMessage: function (event) {
      var module = event.dispatch.module;
      var fn = event.dispatch["function"];

      if (fn === "getPopUpMessages" && module === "SQMOD_Tv_Messages") {
        const message = event.payload.tvmessages[0];
        this.newTvMessage({ text: message.message });
      }
    },
    /**
     * @param msgObject
     * @param connectionObject
     */
    putToQueue: function (msgObject, connectionObject) {
      if (!isUndefined(connectionObject)) {
        this.$logger.info("ONESOCKET", "queued message!");
        connectionObject.queue.push(msgObject);
      } else {
        this.$logger.warn("ONESOCKET", "missing connectionObject");
        this.$logger.info("ONESOCKET", "put message to global queue");
        // this can happen when the connection is slowly established
        // and commands are sent when connection is not openened already
        this.globalQueue.push(msgObject);
      }
    },
    getWebsocketProtocols: function () {
      var protocols = [];
      if (this.$config.device_platform === "netcast") {
        protocols.push("Hixie-76");
      }
      return protocols;
    },
    /**
     * @param ip
     * @param port
     * @param callback
     */
    connect: function (callback) {
      const _this = this;
      this.$logger.info("ONESOCKET", "ws://" + this.ip + ":" + this.port + "/");
      // check if connection already established
      if (isUndefined(this.connections[this.ip + ":" + this.port])) {
        // conncet to serverURL
        // TODO: get mac address of client
        const protocols = this.getWebsocketProtocols();
        this.connection = new ReconnectingWebSocket(
          "ws" +
            (__SCHEME__.scheme === "https" ? "s" : "") +
            "://" +
            this.ip +
            "/ws" +
            (process.env.MODE_ENV === "tv" ? "" : "?mobile=true") +
            (!isUndefined(this.macAddress) && !isNull(this.macAddress)
              ? "?mac=" + this.macAddress
              : ""),
          protocols,
          {
            debug: false,
          }
        );
        // create new connectionObject | get by calling getConnection() function
        var connectionObject = {
          onOpen: function () {},
          onMessage: function () {},
          onClose: function () {},
          onError: function () {},
          ip: this.ip,
          port: this.port,
          connection: this.connection,
          queue: [],
        };
        this.connection.onmessage = function (event) {
          // _this.$logger.info('ONESOCKET', 'socket connection: ' + _this.ip + ':' + _this.port + ' received message!')
          var msg = _this.parseMessage(event.data);
          if (!isEmpty(msg)) {
            // If we get a message that starts with "PING"
            // we will just respond with a PONG message
            // This is needed because the HIXIE-76 websocket standard has no implicit ping handling
            if (msg.lastIndexOf("PING") === 0) {
              _this.connection.send("PONG");
            } else {
              var data = JSON.parse(msg);
              var dispatch = data.dispatch;
              // callback for send function
              _this.sendOnMessageCallback(dispatch.message_id, data);
              connectionObject.onMessage(event);
            }
          } else {
            _this.$logger.error(
              "ONESOCKET",
              "error on parsing received message from server."
            );
          }
        };
        this.connection.onclose = function (event) {
          window.location.reload();
          _this.$logger.info(
            "ONESOCKET",
            "socket connection: " +
              _this.ip +
              ":" +
              _this.port +
              " received close!"
          );
          _this.$logger.info(
            "ONESOCKET",
            "removed socket connection: " +
              _this.ip +
              ":" +
              _this.port +
              " from connections"
          );
          _this.$logger.info("ONESOCKET", "reset message_id counter.");
          _this.connected = false;
          _this.sendConnectionCallback(callback, false);
          _this.sendOnCloseCallback(null, {});
          connectionObject.onClose(event);
          _this.messageIds = [];
          _this.messageIds["lastMessageId"] = 0;
          _this.onMessages = [];
          _this.onCloses = [];
          _this.onErrors = [];
          _this.onTimeouts = [];
        };
        this.connection.onopen = function (event) {
          _this.$logger.info(
            "ONESOCKET",
            "socket connection: " + _this.ip + ":" + _this.port + " established"
          );
          _this.$logger.debug(
            "ONESOCKET",
            "added socket connection: " +
              _this.ip +
              ":" +
              _this.port +
              " to connections"
          );
          _this.connected = true;
          _this.connections[_this.ip + ":" + _this.port] = connectionObject;
          _this.sendConnectionCallback(callback, true);
          _this.processQueue(connectionObject);
          connectionObject.onOpen(event);
        };
        this.connection.onerror = function (event) {
          _this.$logger.error(
            "ONESOCKET",
            "socket connection: " + _this.ip + ":" + _this.port + "!"
          );
          var msg = _this.parseMessage(event.data);
          if (!isEmpty(msg)) {
            var data = JSON.parse(msg);
            _this.sendConnectionCallback(callback, false);
            _this.sendOnErrorCallback(null, data);
            connectionObject.onError(event);
          } else {
            _this.$logger.error(
              "ONESOCKET",
              "error on parsing received message from server."
            );
          }
        };
      } else {
        this.$logger.info(
          "ONESOCKET",
          "socket connection: " +
            _this.ip +
            ":" +
            _this.port +
            " already existent!"
        );
      }
    },
    parseMessage: function (data) {
      var msg = undefined;
      if (!isEmpty(data)) {
        if (data.indexOf(this.stx) === -1) {
          this.$logger.error("ONESOCKET", "no stx start char found!");
        } else if (data.indexOf(this.etx) === -1) {
          this.$logger.error("ONESOCKET", "no etx end char found!");
        } else {
          msg = data.substring(1, data.length - 1);
        }
      } else {
        this.$logger.error(
          "ONESOCKET",
          "message received from server is empty!"
        );
      }
      return msg;
    },
    /**
     * can be set on init function to be triggered when connecton is established
     *
     * @param callback
     * @param success
     */
    sendConnectionCallback: function (callback, success) {
      if (isFunction(callback)) {
        callback(success);
      }
    },
    /**
     * @param connectionObject
     * @private
     */
    processQueue: function (connectionObject) {
      this.processConnectionQueue(connectionObject);
      this.processGlobalQueue(connectionObject);
    },
    /**
     * websocket commands will be stored in connectionobject queue
     * when the websocket connection is lost and we are sending commands
     * to a lost websocket. After the connection is reopened the queue will be
     * processed and the actions will be triggered.
     *
     * @param connectionObject
     * @private
     */
    processConnectionQueue: function (connectionObject) {
      // get queue objects from connectionObject
      const _this = this;
      var queue = connectionObject.queue;
      var len = queue.length;
      var queueIter = 0;
      this.$logger.debug(
        "ONESOCKET",
        "processing send of queue objects : (" + len + ")"
      );
      for (var i = 0; i < len; i++) {
        queueIter = i;
        setTimeout(function () {
          _this.send(queue[queueIter], connectionObject);
          connectionObject.queue.splice(queueIter, 1);
        }, 1000);
      }
    },
    /**
     * globalQueue will be used when websocket connection is built
     * slowly. When no connection is available but we want to send commands
     * via websocket, the message is stored and will be sent to the connection
     * which is connected at first.
     *
     * @param connectionObject
     * @private
     */
    processGlobalQueue: function (connectionObject) {
      const _this = this;
      var len = this.globalQueue.length;
      var queueIter = 0;
      this.$logger.debug(
        "ONESOCKET",
        "processing send of global queue objects : (" + len + ")"
      );
      for (var i = 0; i < len; i++) {
        queueIter = i;
        setTimeout(function () {
          _this.send(_this.globalQueue[queueIter], connectionObject);
          _this.globalQueue.splice(queueIter, 1);
        }, 1500);
      }
    },
    /**
     * close websocket connection
     */
    close: function (ip, port) {
      this.$logger.info("ONESOCKET", "disconnecting " + ip + ":" + port);
      var connection = getConnectionObject(ip, port).connection;
      connection.close();
      // remove connection from array
      this.connections[ip + ":" + port] = undefined;
    },
    /**
     * @return connectionObject
     *
     * @param onOpen: function
     * @param onMessage: function
     * @param onClose: function
     * @param ip
     * @param port
     * @param connection Websocket object
     */
    getConnectionObject: function (ip, port) {
      var connectionObject = this.connections[ip + ":" + port];
      return connectionObject || null;
    },
    /**
     * get default webscocket ONEsquare connection
     */
    getDefaultConnection: function () {
      return this.connections[ip + ":" + port] || {};
    },
    isConnected: function () {
      var defaultConnection = this.getDefaultConnection();
      if (!isUndefined(defaultConnection)) {
        var connection = defaultConnection.connection;
        if (!isUndefined(connection)) {
          var readyState = connection.readyState;
          if (readyState == 1) return true;
        }
      }
      return false;
    },
    clearReceiveTimeout: function (message_id) {
      var timeout = find(this.timeouts, {
        message_id: parseInt(message_id),
      });
      if (!isUndefined(timeout)) {
        clearTimeout(timeout.t);
      }
    },
    clearMessages: function (message_id) {
      if (isFinite(message_id)) {
        parseInt(message_id);
      }
      if (!isNaN(message_id) && message_id !== 0) {
        // clear message_id -> lastMessageId will be cleared when reconnecting
        this.messageIds[message_id] = undefined;
        this.onMessages[message_id] = undefined;
        this.onErrors[message_id] = undefined;
        this.onCloses[message_id] = undefined;
        this.clearReceiveTimeout(message_id);
        this.onTimeouts[message_id] = undefined;
        try {
          delete this.messageIds[message_id];
          delete this.onMessages[message_id];
          delete this.onErrors[message_id];
          delete this.onCloses[message_id];
          delete this.onTimeouts[message_id];
        } catch (err) {
          // checked
        }
      }
    },
  },
  watch: {
    connected(val) {
      this.setOnesocketConnected(val);
      if (!val) {
        this.status = "failure";
      } else {
        this.status = "success";
      }
    },
  },
});
