import Vue from "vue";
import Router from "vue-router";
import isUndefined from "lodash/isUndefined";
const Login = () => import("@/views/Login");
const ContentPage = () => import("@/views/Contentpage");
const Messenger = () => import("@/views/Messenger");
const Maps = () => import("@/views/Maps");
const Browse = () => import("@/views/vod/Browse");
const Packages = () => import("@/views/vod/Packages");
const PackageDetail = () => import("@/views/vod/PackageDetail");
const MovieDetail = () => import("@/views/vod/MovieDetail");
const ProtectedConfirmation = () => import("@/views/vod/ProtectedConfirmation");
const RemoteControl = () => import("@/views/RemoteControl");
const RoomControl = () => import("@/views/RoomControl");
const DemoRoomControl = () => import("@/views/demo/RoomControl");
const TvMessage = () => import("@/views/TvMessage");
const OpenWebPage = () => import("@/views/OpenWebPage");
const OpenTel = () => import("@/views/OpenTel");
const OpenEmail = () => import("@/views/OpenEmail");
const Shop = () => import("@/views/shop/Shop");
const ShopProductDetail = () => import("@/views/shop/ShopProductDetail");
const ShopCart = () => import("@/views/shop/ShopCart");
const Billpreview = () => import("@/views/Billpreview");
const Epg = () => import("@/views/epg/Epg");
const EpgDetail = () => import("@/views/epg/EpgDetail");
const FlightInfo = () => import("@/views/FlightInfo");
const Webradio = () => import("@/views/Webradio");
const VoIP = () => import("@/views/VoIP");
const MobileKey = () => import("@/views/MobileKey");
const MoreNavItems = () => import("@/views/MoreNavItems");
const Search = () => import("@/views/Search");
const TermsAndConditions = () => import("@/views/TermsAndConditions");
const PrivacyPolicy = () => import("@/views/PrivacyPolicy");

import { BASE_URL } from "./config";
import { appendQueryToManifest } from "@/util/helpers";

Vue.use(Router);

const router = new Router({
  base: BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      meta: { icon: "mdi-home" },
    },
    {
      path: "/cp/:cpId",
      name: "contentpage",
      component: ContentPage,
    },
    {
      path: "/page/:pageId",
      name: "resolvePageId",
    },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    {
      path: "/demo/messenger",
      name: "messenger",
      component: Messenger,
      meta: { icon: "mdi-facebook-messenger" },
    },
    {
      path: "/demo/maps",
      name: "maps",
      component: Maps,
      meta: { icon: "mdi-map-marker" },
    },
    {
      path: "/vod/browse",
      name: "browse",
      component: Browse,
      meta: { icon: "mdi-movie" },
    },
    {
      path: "/vod/packages",
      name: "packages",
      component: Packages,
    },
    {
      path: "/vod/packagesByMovie/:movieId",
      name: "packagesByMovie",
      component: Packages,
    },
    {
      path: "/vod/packagedetail/:pckgId",
      name: "packageDetail",
      component: PackageDetail,
    },
    {
      path: "/vod/movies/:movieId",
      name: "movieDetail",
      component: MovieDetail,
    },
    {
      path: "/vod/movies/protectedConfirmation/:movieId",
      name: "protectedConfirmation",
      component: ProtectedConfirmation,
    },
    {
      path: "/room-control",
      name: "roomControl",
      component: RoomControl,
      meta: { icon: "mdi-home-thermometer" },
    },
    {
      path: "/demo/room-control",
      name: "demoRoomControl",
      component: DemoRoomControl,
      meta: { icon: "mdi-home-thermometer" },
    },
    {
      path: "/remote-control",
      name: "remoteControl",
      component: RemoteControl,
      meta: { icon: "mdi-remote" },
    },
    {
      path: "/tv-message",
      name: "tvMessage",
      component: TvMessage,
      meta: { icon: "mdi-message" },
    },
    {
      path: "/open-web-page/:link",
      name: "openWebPage",
      component: OpenWebPage,
      meta: { icon: "mdi-link" },
    },
    {
      path: "/open-tel/:tel",
      name: "openTel",
      component: OpenTel,
      meta: { icon: "mdi-phone" },
    },
    {
      path: "/open-email/:mailto",
      name: "openEmail",
      component: OpenEmail,
      meta: { icon: "mdi-email" },
    },
    {
      path: "/shop/flow/:id",
      name: "shop",
      component: Shop,
      meta: { icon: "mdi-store" },
    },
    {
      path: "/shop/cart",
      name: "shopCart",
      component: ShopCart,
    },
    {
      path: "/shop/flow/:id/product/:productId",
      name: "shopProductDetail",
      component: ShopProductDetail,
    },
    {
      path: "/billpreview",
      name: "billpreview",
      component: Billpreview,
      meta: { icon: "mdi-receipt" },
    },
    {
      path: "/epg",
      name: "epg",
      component: Epg,
      meta: { icon: "mdi-television-guide" },
    },
    {
      path: "/epg/:channelId",
      name: "epgDetail",
      component: EpgDetail,
      meta: { icon: "mdi-television-guide" },
    },
    {
      path: "/flightinfo",
      name: "flightinfo",
      component: FlightInfo,
      meta: { icon: "mdi-airplane" },
    },
    {
      path: "/webradio",
      name: "webradio",
      component: Webradio,
      meta: { icon: "mdi-radio" },
    },
    {
      path: "/demo/voip",
      name: "voip",
      component: VoIP,
      meta: { icon: "mdi-dialpad" },
    },
    {
      path: "/demo/mobilekey",
      name: "mobileKey",
      component: MobileKey,
    },
    {
      path: "/more-nav",
      name: "moreNavItems",
      component: MoreNavItems,
    },
    {
      path: "/search",
      name: "search",
      component: Search,
      meta: { icon: "mdi-magnify" },
    },
    {
      path: "/terms",
      name: "terms",
      component: TermsAndConditions,
    },
    {
      path: "/privacy",
      name: "privacy",
      component: PrivacyPolicy,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    return new Promise((resolve) => {
      setTimeout(() => {
        if (to.name === "epgDetail") {
          resolve({ x: 0, y: 0 });
        } else {
          if (savedPosition) {
            resolve(savedPosition);
          } else {
            resolve({ x: 0, y: 0 });
          }
        }
      }, 300);
    });
  },
});

router.afterEach((to, from) => {
  let value;
  let type;

  switch (to.name) {
    case "home":
      value = "HOME";
      type = "home";
      break;
    case "contentpage":
      value = to.params.cpId;
      type = "contentpage";
      break;
    case "browse":
    case "packages":
    case "packagesByMovie":
    case "packageDetail":
    case "movieDetail":
    case "protectedConfirmation":
      value = "VOD";
      type = "module";
      break;
    case "login":
      // no statistic here
      break;
    default:
      router.app.$logger.warn(
        "router",
        "failed to send start statistics for route",
        to.name
      );
      break;
  }

  router.app.$nextTick(() => {
    if (
      !isUndefined(value) &&
      !isUndefined(type) &&
      !isUndefined(router.app.$store)
    ) {
      router.app.$store.dispatch("statistic/sendEventStart", {
        event_value: value,
        event_type: type,
      });
    }
  });
});

import isEmpty from "lodash/isEmpty";

router.beforeResolve(async (to, from, next) => {
  await router.app.$nextTick();
  if (router.app && router.app.$store) {
    let query = {};
    if (
      (!to.query.name || !to.query.room || !to.query.userId) &&
      router.app.$store.state.session &&
      router.app.$store.state.session.loginData &&
      router.app.$store.state.session.loginData.name &&
      router.app.$store.state.session.loginData.name.length > 0 &&
      router.app.$store.state.session.loginData.room &&
      router.app.$store.state.session.loginData.room.length > 0 &&
      router.app.$store.state.session.loginData.userId
    ) {
      query = {
        ...query,
        name: router.app.$store.state.session.loginData.name
          ? router.app.$store.state.session.loginData.name
          : undefined,
        room: router.app.$store.state.session.loginData.room
          ? router.app.$store.state.session.loginData.room
          : undefined,
        checkin: router.app.$store.state.session.loginData.checkin
          ? router.app.$store.state.session.loginData.checkin
          : undefined,
        userId: router.app.$store.state.session.loginData.userId
          ? router.app.$store.state.session.loginData.userId
          : undefined,
      };
    }

    if (
      !to.query.room &&
      router.app.$store.state.session &&
      router.app.$store.state.session.authResult &&
      router.app.$store.state.session.authResult.room &&
      router.app.$store.state.session.authResult.room.length > 0
    ) {
      query = {
        ...query,

        room: router.app.$store.state.session.authResult.room
          ? router.app.$store.state.session.authResult.room
          : undefined,
      };
    }

    if (
      !to.query.checkin &&
      router.app.$store.state.session &&
      router.app.$store.state.session.loginData &&
      router.app.$store.state.session.loginData.checkin
    ) {
      query = {
        ...query,
        checkin: router.app.$store.state.session.loginData.checkin,
      };
    }

    if (
      (!to.query.theme || !to.query.property) &&
      router.app.$store.state.session &&
      router.app.$store.state.session.themeId &&
      router.app.$store.state.session.propertyId
    ) {
      query = {
        ...query,
        theme: router.app.$store.state.session.themeId,
        property: router.app.$store.state.session.propertyId,
      };
    }

    if (from.query.mode && !to.query.mode) {
      setTimeout(() => {
        window.location.reload();
      }, 300);
    }

    // remove search specific query params
    if (
      from.name === "search" &&
      to.name !== "search" &&
      (to.query.q || to.query["q-type"])
    ) {
      query.q = undefined;
      query["q-type"] = undefined;
    }

    if (isEmpty(query)) {
      next();
    } else {
      next({
        path: to.path,
        query: {
          ...to.query,
          ...query,
        },
      });
    }
    router.app.$store.commit("session/SET_SESSION_READY");
    return;
  }

  next();
});

router.beforeEach(async (to, from, next) => {
  var authValid = false;
  await router.app.$nextTick();
  if (router.app && router.app.$store) {
    const {
      room = undefined,
      name = undefined,
      checkin = undefined,
      userId = undefined,
      mode = undefined,
      property = undefined,
      theme = undefined,
      skin = undefined,
      groups = undefined,
      location = undefined,
      logout = undefined,
    } = to.query;

    await router.app.$store.dispatch("session/setCookies", {
      mode,
      room,
      name,
      checkin,
      userId,
      location,
      skin,
    });

    appendQueryToManifest({
      query: { room, name, checkin, userId, mode, skin },
    });
    await router.app.$store.dispatch("session/initTheme", {
      property,
      theme,
    });
    await router.app.$store.dispatch("session/getSettings", { property });

    authValid = await new Promise((resolve) => {
      if (
        to.name === "login" ||
        router.app.$store.state.session.mode === "walkin" ||
        router.app.$store.state.session.mode === "walkin-only" ||
        router.app.$store.state.session.mode === "demo"
      ) {
        resolve(true);
        return;
      }

      if (router.app.$onesocket.connected) {
        router.app.$onesocket.send({
          function: "authenticate",
          module: "SQMOD_Auth",
          data: {},
          onMessage: (data) => {
            if (
              data.payload.root.checkin &&
              data.payload.root.checkin.length > 0
            ) {
              resolve(true);
              return;
            }

            resolve(false);
          },
        });
      } else {
        resolve(true);
      }
    });

    if (!authValid) {
      if (to.name !== "login") {
        // workaround to prevent infinite loading on some devices when redirecting to login
        setTimeout(() => {
          next({ name: "login" });
        }, 0);
      } else {
        next();
      }
      return;
    }

    if (
      to.name !== "login" &&
      router.app.$store.state.session.mode &&
      !to.query.mode
    ) {
      next({
        path: to.path,
        query: {
          skin: router.app.$store.state.session.skinId,
          ...from.query,
          ...to.query,
          mode: router.app.$store.state.session.mode,
        },
      });
      return;
    }

    /* Reset the menu tree when switching between two shops */
    if (
      from.name === "shopProductDetail" ||
      (from.name === "shop" &&
        to.name === "shop" &&
        from.params.id !== to.params.id)
    ) {
      next({ query: { groups: undefined } });
      return;
    }

    if (from.name === "webradio" && to.name !== "webradio") {
      next({ query: { country: undefined } });
      return;
    }

    if (from.path.includes("/vod/") && !to.path.includes("/vod/")) {
      next({
        query: {
          filter: undefined,
          page: undefined,
          search: undefined,
        },
      });
      return;
    }

    if (
      router.app.$config &&
      router.app.$config.language_key &&
      (!to.query.lang ||
        to.query.lang !== router.app.$config.language_key.toLowerCase())
    ) {
      next({
        path: to.path,
        query: {
          ...from.query,
          ...to.query,
          mode: router.app.$store.state.session.ready
            ? router.app.$store.state.session.mode
            : from.query.mode || to.query.mode,
          lang: router.app.$config.language_key.toLowerCase(),
        },
      });
      return;
    }

    // remove groups query when not in shop
    if (
      groups &&
      to.name !== "shop" &&
      to.name !== "shopCart" &&
      to.name !== "shopProductDetail"
    ) {
      next({
        path: to.path,
        query: {
          ...from.query,
          ...to.query,
          groups: undefined,
        },
      });
    }

    if (
      router.app.$store.state.session.status === "not_registered" &&
      !authValid
    ) {
      if (
        router.app.$store.state.session.mode === "demo" ||
        to.query.mode === "demo"
      ) {
        next();
        return;
      }
      if (
        router.app.$store.state.session.mode === "walkin" ||
        to.query.mode === "walkin" ||
        router.app.$store.state.session.mode === "walkin-only" ||
        to.query.mode === "walkin-only"
      ) {
        router.app.$store.dispatch("session/setLoginData", {
          room: "walkin",
          name: undefined,
          checkin: undefined,
          userId: undefined,
        });
        await router.app.$store.dispatch("session/authenticate");

        next({ name: "home" });
        return;
      } else if (to.name === "login") {
        next();
        return;
      } else {
        next({ name: "login" });
        return;
      }
    } else if (
      router.app.$store.state.session.status === "multiple_residents" &&
      to.name !== "login"
    ) {
      next({ name: "login", query: { ...from.query, ...to.query } });
    } /* else if (
      authValid &&
      to.name !== "login" &&
      from.query.redirect &&
      to.path !== from.query.redirect
    ) {
      if (router.app.$store.state.session.status === "registered") {
        next({
          path: from.query.redirect,
          query: { ...from.query, ...to.query, redirect: undefined },
        });
        return;
      }
    }*/

    if (
      router.app.$config &&
      router.app.$config.language_key &&
      router.app.$store.state.session.skinId &&
      (!to.query.skin ||
        to.query.skin !== router.app.$store.state.session.skinId)
    ) {
      next({
        path: to.path,
        query: {
          ...from.query,
          ...to.query,
          skin: router.app.$store.state.session.skinId,
        },
      });
      return;
    }

    // Set requested page id
    if (to.name === "resolvePageId" && to.params.pageId) {
      router.app.$store.commit("contentpage/REQUEST_PAGE_ID", to.params.pageId);
    }
  }

  next();
});

// catch errors and return currentroute
function patchRouterMethod(router, methodName) {
  router["old" + methodName] = router[methodName];
  router[methodName] = async function (location) {
    return router["old" + methodName](location).catch((error = {}) => {
      const { name = "" } = error;
      if (name === "NavigationDuplicated") {
        return this.currentRoute;
      } else if (name === "Error") {
        return this.currentRoute;
      }
      throw error;
    });
  };
}

patchRouterMethod(router, "push");
patchRouterMethod(router, "replace");

export default router;
