import isArray from "lodash/isArray";
import router from "@/router";
import { set } from "vue-analytics";
import {
  appendQueryToAppleTouchIcon,
  appendQueryToManifest,
} from "@/util/helpers";

export default {
  RESET(state) {
    router.app.$cookies.remove("room");
    router.app.$cookies.remove("name");
    router.app.$cookies.remove("checkin");
    router.app.$cookies.remove("userId");
    router.app.$cookies.remove("location");
    router.app.$cookies.remove("mode");
    router.app.$cookies.remove("skin");
    state.status = "not_registered";
    state.checkinData = undefined;
    state.skinData = {};
    state.configStatus = undefined;
    state.isCheckedIn = undefined;
    state.mode = undefined;
    state.skinId = undefined;
    state.initialized = false;
    state.authResult = {};
  },
  SET_STATUS(state, value) {
    state.status = value;
  },
  SET_AUTH_RESULT(state, value) {
    state.authResult = value;
    const { checkin } = state.authResult;
    if (checkin && typeof checkin === "object") {
      state.authResult.checkin[0].residents = checkin[0].residents.filter(
        (checkin) => {
          return checkin.permanent === 1 ? false : true;
        }
      );
    }
    // when skinId is set from query param, dont take it from the login
    if (!state.skinId) {
      state.skinId = state.authResult.skin.id
        ? state.authResult.skin.id.toString()
        : state.authResult.skin.id;
    }
  },
  SET_CHECKIN_DATA(state, value) {
    state.checkinData = value;

    if (state.mode === "demo") {
      state.checkinData[0].name = router.currentRoute.query.name;
      state.checkinData[0].room = router.currentRoute.query.room;
    }

    state.isCheckedIn =
      isArray(state.checkinData) && state.checkinData.length > 0;

    if (value && value[0]) {
      set({ userId: value[0].id });
    }
  },
  SET_SKIN_DATA(state, value) {
    state.skinData = value;
  },
  MAIN_SET_INITIALIZED(state, value) {
    state.initialized = value;
  },
  SUCCESS_INIT_CONFIG(state) {
    state.configStatus = "success";
  },
  SET_CURRENCY_CONFIG(state, config) {
    state.currencyConfig = {
      symbol: config.currency_short,
      thousandsSeparator: ",",
      fractionCount: config.price_decimal_precision,
      fractionSeparator: ".",
      symbolPosition: config.currency_position.toLowerCase(),
      symbolSpacing: true,
      avoidEmptyDecimals: undefined,
    };
  },
  SUCCESS_INIT_ONESOCKET(state) {
    state.onesocketStatus = "success";
  },
  SET_MODE(state, value = router.currentRoute.query.mode) {
    state.mode = value;
  },
  SET_SKIN(state, value = router.currentRoute.query.skin) {
    state.skinId = value ? value.toString() : value;
  },
  SET_LOGIN_DATA(
    state,
    {
      room = undefined,
      name = undefined,
      checkin = undefined,
      userId = undefined,
      id = undefined,
    }
  ) {
    state.loginData.room = room;
    state.loginData.name = name;
    state.loginData.userId = userId || id;
    state.loginData.id = userId || id;
    state.loginData.checkin = checkin;

    if (
      state.mode !== "demo" &&
      (router.app.$route.query.name !== name ||
        router.app.$route.query.room !== room ||
        router.app.$route.query.checkin !== checkin ||
        router.app.$route.query.userId !== userId)
    ) {
      router.push({
        query: {
          ...router.app.$route.query,
          name: name ? name : undefined,
          room: room ? room : undefined,
          checkin: checkin ? checkin : undefined,
          userId: userId ? userId : id ? id : undefined,
        },
      });
    }
  },
  SET_BROWSER_LANG(state, value) {
    state.browserLang = value;
  },
  SET_THEME_ID(state, value = router.currentRoute.query.theme) {
    state.themeId = value;
    appendQueryToAppleTouchIcon({ query: { themeId: value } });
    appendQueryToManifest({ query: { themeId: value } });
    router.app.$cookies.set("themeId", value, 1);
  },
  SET_PROPERTY_ID(state, value = router.currentRoute.query.property) {
    state.propertyId = value;
    appendQueryToAppleTouchIcon({ query: { propertyId: value } });
    appendQueryToManifest({ query: { propertyId: value } });
    router.app.$cookies.set("propertyId", value, 1);
  },
  SET_WALKIN_CONTENT: (state, value) => {
    state.hasWalkInContent = value.hasWalkInContent;
  },
  SET_SESSION_READY: (state) => {
    state.ready = true;
  },
  REQUEST_IS_WEBCHECKIN_ENABLED: (state) => {
    state.statusIsWebcheckinEnabled = "fetching";
  },
  SUCCESS_IS_WEBCHECKIN_ENABLED: (state, { body }) => {
    state.statusIsWebcheckinEnabled = "success";
    state.isWebcheckinEnabled = body.enabled;
  },
  FAILURE_IS_WEBCHECKIN_ENABLED: (state) => {
    state.statusIsWebcheckinEnabled = "fetching";
    state.isWebcheckinEnabled = false;
  },
  SET_ONESOCKET_CONNECTED: (state, value) => {
    state.onesocketConnected = value;
  },
  REQUEST_SETTINGS: (state) => {
    state.settingsStatus = "fetching";
  },
  SUCCESS_SETTINGS: (state, value) => {
    state.settingsStatus = "success";
    state.settings = value.body.settings;
  },
  FAILURE_SETTINGS: (state) => {
    state.settingsStatus = "failure";
  },
  INIT_MAGIC_BUTTON: (state) => {
    state.magicButtonShow = !state.magicButtonShow;
    state.magicLeft = Math.floor(Math.random() * (35 - 25)) + 25;
    state.magicBottom = Math.floor(Math.random() * (72 - 20)) + 20;
  },
  RESET_MAGIC_BUTTON: (state) => {
    state.magicButtonShow = false;
    clearInterval(state.magicButtonInterval);
  },
  SET_MAGIC_BUTTON_INTERVAL: (state, value) => {
    state.magicButtonInterval = value;
  },
  SET_IS_WALKIN: (state, value) => {
    state.isWalkin = value;
  },
  FETCH_ONEKEY_SESSION: (state) => {
    state.onekeySessionStatus = "fetching";
  },
  SUCCESS_ONEKEY_SESSION: (state, value) => {
    state.onekeySessionStatus = "success";
    state.onekeySession = value;
  },
  FAILURE_ONEKEY_SESSION: (state) => {
    state.onekeySessionStatus = "failure";
  },
};
