export default () => ({
  items: [],
  playingStation: undefined,
  favouriteGroup: [],
  favorites: [],
  stations: [],
  sound: undefined,
  selectedCountry: {},
  playChannel: "",
  statusChannels: undefined,
  statusFavorites: undefined,
});
