import Vue from "vue";

export function getBillData(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "requestBillData",
      module: "SQMOD_BillPreview",
      data: {
        ...options,
      },
      onMessage: function (data) {
        resolve(data.payload);
      },
      onError: function (error) {
        reject(error);
      },
    });
  });
}
