import each from "lodash/each";

const dateFormat = function (dateString, timeString) {
  /** adding 20 to get year of 21st century * */
  var year = 20 + dateString.substring(0, 2);
  var month = dateString.substring(2, 4);
  var day = dateString.substring(4, 6);

  var hours = timeString.substring(0, 2);
  var minutes = timeString.substring(2, 4);
  var seconds = timeString.substring(4, 6);

  var date = new Date(year, --month, day, hours, minutes, seconds);
  return date;
};

export default {
  REQUEST_BILL_DATA(state) {
    state.status = "fetching";
  },
  SUCCESS_REQUEST_BILL_DATA(state, value) {
    state.status = "success";
    state.items = value;
    state.sum = 0;
    each(state.items, (element) => {
      element.dateObj = dateFormat(element.date, element.time);
      state.sum += parseFloat(element.amount);
    });
  },
  FAILURE_REQUEST_BILL_DATA(state) {
    state.status = "failure";
  },
};
