import isEmpty from "lodash/isEmpty";
import find from "lodash/find";
import { sendEventStart, sendEventEnd } from "@/api/statistic";

export default {
  async sendEventStart({ commit, state, dispatch }, event = {}) {
    if (state.eventList.length > 0) {
      const lastEvent = state.eventList[state.eventList.length - 1];
      if (
        lastEvent.event_type === event.event_type &&
        lastEvent.event_value === event.event_value
      ) {
        return;
      } else {
        dispatch("sendEventEnd", {
          event_value: lastEvent.event_value,
          event_type: lastEvent.event_type,
        });
      }
    }

    try {
      event.event_number = state.eventNumberIterator;
      await sendEventStart(event);
      commit("SUCCESS_REQUEST_EVENT_START");
      commit("ADD_TO_EVENT_LIST", event);
    } catch (e) {
      commit("FAILURE_REQUEST_EVENT_START");
    }
  },
  async sendEventEnd({ commit, state }, event = {}) {
    const eventInList = find(state.eventList, function (element) {
      return (
        element.event_value === event.event_value &&
        element.event_type === event.event_type
      );
    });
    if (isEmpty(eventInList)) {
      this._vm.$logger.warn(
        "statistic-store",
        "unable to end event, given event value not in eventList",
        event.event_value,
        event.event_value
      );
      return;
    }
    commit("REQUEST_EVENT_END");

    try {
      await sendEventEnd(eventInList);
      commit("SUCCESS_REQUEST_EVENT_END");
      commit("REMOVE_FROM_EVENT_LIST", event);
    } catch (e) {
      commit("FAILURE_REQUEST_EVENT_END");
    }
  },
};
