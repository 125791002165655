import Vue from "vue";

export function sendEventStart(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "processEventStart",
      module: "SQMOD_Statistic",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
      },
    });
  });
}

export function sendEventEnd(options) {
  return new Promise((resolve, reject) => {
    Vue.onesocket.send({
      function: "processEventEnd",
      module: "SQMOD_Statistic",
      data: { ...options },
      onMessage: function (data) {
        resolve(data.payload.root);
      },
      onError: function (error) {
        reject(error);
      },
    });
  });
}
