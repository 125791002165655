import {
  getMovies,
  getGenres,
  getPackages,
  buyMovieOrPackage,
  addResumeTime,
  getVouchers,
} from "@/api/vod";

export default {
  async getMovies({ commit, dispatch }, options) {
    commit("REQUEST_MOVIES");
    try {
      commit("SUCCESS_REQUEST_MOVIES", await getMovies(options));
    } catch (e) {
      commit("FAILURE_REQUEST_MOVIES");
    }
  },
  async getGenres({ commit, dispatch }, options) {
    commit("REQUEST_GENRES");
    try {
      commit("SUCCESS_REQUEST_GENRES", await getGenres(options));
    } catch (e) {
      commit("FAILURE_REQUEST_GENRES");
    }
  },
  async getPackages({ commit, dispatch }, options) {
    commit("REQUEST_PACKAGES");
    try {
      commit("SUCCESS_REQUEST_PACKAGES", await getPackages(options));
    } catch (e) {
      commit("FAILURE_REQUEST_PACKAGES");
    }
  },
  async getPackageById({ commit, dispatch }, { id }) {
    commit("REQUEST_PACKAGE_BY_ID");
    try {
      commit(
        "SUCCESS_REQUEST_PACKAGE_BY_ID",
        await getPackages({ pckg_id: id })
      );
    } catch (e) {
      commit("FAILURE_REQUEST_PACKAGE_BY_ID");
    }
  },
  async getMoviesOfPackage({ commit, dispatch }, options) {
    commit("REQUEST_PACKAGE_MOVIES");
    try {
      commit("SUCCESS_REQUEST_PACKAGE_MOVIES", await getMovies(options));
    } catch (e) {
      commit("FAILURE_REQUEST_PACKAGE_MOVIES");
    }
  },
  async getMovieDetails({ commit, dispatch }, options) {
    commit("REQUEST_MOVIE_DETAILS");
    try {
      let movieDetails = await getMovies(options);
      commit("SUCCESS_REQUEST_MOVIE_DETAILS", movieDetails.movies[0]);
    } catch (e) {
      commit("FAILURE_REQUEST_MOVIE_DETAILS");
    }
  },
  async buyMovieOrPackage({ commit, dispatch }, options) {
    commit("REQUEST_BUY_PACKAGE");
    try {
      await buyMovieOrPackage(options);
      commit("SUCCESS_REQUEST_BUY_PACKAGE");
    } catch (e) {
      commit("FAILURE_REQUEST_BUY_PACKAGE");
    }
  },
  async addResumeTime({ commit, dispatch }, options) {
    commit("REQUEST_ADD_RESUME_TIME");
    try {
      await addResumeTime(options);
      commit("SUCCESS_REQUEST_ADD_RESUME_TIME");
    } catch (e) {
      commit("FAILURE_REQUEST_ADD_RESUME_TIME");
    }
  },
  async getVouchers({ commit, dispatch }, options) {
    commit("REQUEST_VOUCHERS");
    try {
      commit("SUCCESS_REQUEST_VOUCHERS", await getVouchers(options));
    } catch (e) {
      commit("FAILURE_REQUEST_VOUCHERS");
    }
  },
  setShowFilter: function ({ commit }, { state }) {
    commit("SET_SHOW_FILTER", state);
  },
  setBuyDialog: function ({ commit }, { state }) {
    commit("SET_BUY_DIALOG", state);
  },
};
