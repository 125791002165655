import Vue from "vue";
import isArray from "lodash/isArray";
import isEqual from "lodash/isEqual";
import router from "@/router";

export default {
  REQUEST_INFO(state) {
    state.statusInfo = "loading";
  },
  SUCCESS_REQUEST_INFO(state) {
    state.statusInfo = "success";
  },
  FAILED_REQUEST_INFO(state) {
    state.statusInfo = "failed";
  },
  REQUEST_CONTENT(state) {
    state.statusContent = "loading";
  },
  SUCCESS_REQUEST_CONTENT(state) {
    state.statusContent = "success";
  },
  FAILED_REQUEST_CONTENT(state) {
    state.statusContent = "failed";
  },
  REQUEST_TRANSMIT(state) {
    state.statusTransmit = "sending";
  },
  SUCCESS_REQUEST_TRANSMIT(state) {
    state.statusTransmit = "success";
  },
  FAILED_REQUEST_TRANSMIT(state) {
    state.statusTransmit = "failed";
  },
  SET_STATUS_PURCHASE_FLOW_OPEN(state) {
    state.statusPurchaseFlow = "open";
  },
  SET_STATUS_PURCHASE_FLOW_FINISHED(state) {
    state.statusPurchaseFlow = "finished";
  },
  SET_STATUS_PURCHASE_FLOW_CANCEL(state) {
    state.statusPurchaseFlow = "cancel";
  },
  SET_INFO(state, value) {
    let {
      opening_time = "00:00:00",
      closing_time = "00:00:00",
      isOpen = true,
    } = value;
    try {
      value.opening_time = opening_time.substring(0, opening_time.length - 3);
      value.closing_time = closing_time.substring(0, closing_time.length - 3);
    } catch (err) {
      // eslint-disable-next-line-no-console
      console.error(err);
    }
    if (!isOpen && router.currentRoute.name === "shop") {
      state.showShopClosedMessage = true;

      router.push({ name: "home" });
    }
    state.info = value;
  },
  SET_CONTENT(state, value) {
    state.content = value;
    try {
      state.title =
        value.template_data.template_items[0].item_value ||
        Vue.i18n.translate("shop.title");
    } catch (err) {
      state.title = Vue.i18n.translate("shop.title");
    }
    try {
      state.description =
        value.template_data.template_items[1].item_value || "";
    } catch (err) {
      state.description = "";
    }
  },
  SET_ACTIVE_GROUP(state, activeGroup) {
    state.activeGroup = activeGroup;
  },
  SET_PARENT_GROUP(state, parentGroup) {
    state.parentGroup = parentGroup;
  },
  SET_ACTIVE_ITEM_LIST_DATA(state, activeItemListData) {
    state.activeItemListData = activeItemListData;
  },
  SET_ITEM_QUANTITY(state, { item, quantity }) {
    item.quantity = quantity;
  },
  SET_PURCHASE_FLOW_CACHE(state, purchaseFlowItem) {
    state.purchaseFlowCacheItem = purchaseFlowItem;
  },
  CLEAR_PURCHASE_FLOW_CACHE(state) {
    state.purchaseFlowCacheItem = {};
  },
  MODIFY_PURCHASE_FLOW_SUBITEM_SELECTED(
    state,
    { groupIndex, subIndex, isSelected }
  ) {
    state.purchaseFlowCacheItem.purchase_flow[groupIndex].sub_items[
      subIndex
    ].selected = isSelected;
  },
  PURCHASE_FLOW_ADD_TO_SELECTED_LIST(state, item) {
    state.purchaseFlowCacheItem.purchase_flow_selected.push(item);
  },
  PURCHASE_FLOW_REMOVE_FROM_SELECTED_LIST(state, listIndex) {
    state.purchaseFlowCacheItem.purchase_flow_selected.splice(listIndex, 1);
  },
  SET_CART_ITEM_ID_ITERATOR(state, value) {
    state.cartItemIdIterator = value;
  },
  ADD_TO_CART_LIST(state, newCartItem) {
    newCartItem.cartId = state.cartItemIdIterator++;
    state.cartItemList.push(newCartItem);
    localStorage.setItem(
      "shop-cart-item-list",
      JSON.stringify(state.cartItemList)
    );
  },
  SET_CART_ITEM_LIST(state, value) {
    state.cartItemList = isArray(value) ? value : [];
    localStorage.setItem(
      "shop-cart-item-list",
      JSON.stringify(state.cartItemList)
    );
  },
  SET_CART_QUANTITY(state, value) {
    state.cartQuantity = value;
  },
  REMOVE_FROM_CART_LIST(state, cartItemIndex) {
    state.cartItemList.splice(cartItemIndex, 1);
    localStorage.setItem(
      "shop-cart-item-list",
      JSON.stringify(state.cartItemList)
    );
  },
  MODIFY_CART_ITEM_QUANTITY(state, { item, quantity }) {
    item.quantity = quantity;
  },
  REMOVE_CART_ITEM(state, cartItemIndex) {
    state.cartItemList.splice(cartItemIndex, 1);
    localStorage.setItem(
      "shop-cart-item-list",
      JSON.stringify(JSON.stringify(state.cartItemList))
    );
  },
  RESET_CART_LIST(state, { cartItemsToRemove }) {
    var copyCartItemList = state.cartItemList;
    state.cartItemList = [];
    copyCartItemList.forEach((item, idx) => {
      cartItemsToRemove.forEach((cItem) => {
        if (
          !state.cartItemList.includes(item) &&
          item.shopId !== cItem.shopId
        ) {
          state.cartItemList.push(item);
        }
      });
    });
    localStorage.setItem(
      "shop-cart-item-list",
      JSON.stringify(JSON.stringify(state.cartItemList))
    );
  },
  SET_SHOW_SHOP_CLOSED_MESSAGE(state, value) {
    state.showShopClosedMessage = value;
  },
  ADD_TO_SHOPS(state, value) {
    state.shops.push(value);
    localStorage.setItem("shop-cart-title-list", JSON.stringify(state.shops));
  },
  REMOVE_FROM_SHOPS(state, value) {
    var idx = state.shops.findIndex((ele) => ele.id === value);
    state.shops.splice(idx, 1);
    localStorage.setItem("shop-cart-title-list", JSON.stringify(state.shops));
  },
  SET_SHOPS(state, value) {
    state.shops = value;
    localStorage.setItem("shop-cart-title-list", JSON.stringify(state.shops));
  },
  SAVE_REMOVED_ITEM_SHOP_ID(state, value) {
    state.removedItemShopId = value;
    localStorage.setItem(
      "shop-cart-removed-item-shop-id",
      JSON.stringify(state.removedItemShopId)
    );
  },
};
