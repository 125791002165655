import Vue from "vue";
import isDate from "lodash/isDate";

import { formatDate } from "@/util/helpers";

Vue.filter(
  "formatDate",
  function (
    value,
    format = Vue.onevisionCMSConfig[
      "clock_date_format" +
        Vue.onevisionCMSConfig.date_format_language_mapping[
          Vue.onevisionCMSConfig.language_key
        ]
    ] +
      " " +
      Vue.onevisionCMSConfig[
        "clock_time_format" +
          Vue.onevisionCMSConfig.date_format_language_mapping[
            Vue.onevisionCMSConfig.language_key
          ]
      ]
  ) {
    if (!isDate(value)) {
      return "";
    }
    return formatDate(value, format);
  }
);
