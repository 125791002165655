import isUndefined from "lodash/isUndefined";
import isEmpty from "lodash/isEmpty";
import isFinite from "lodash/isFinite";
import { DateTime } from "luxon";
import isDate from "lodash/isDate";
import Vue from "vue";
import appendQuery from "append-query";

export const lodash = {
  isEmpty,
  isFinite,
  isUndefined,
};

/**
 * Creates a STYLE element with the specified content.
 * @param  {string} content  The stylesheet content.
 * @return {!Element}  The created STYLE element.
 */
export function createStyleSheet(content) {
  const style = document.createElement("style");
  const styleSheet = style.styleSheet;
  if (styleSheet) {
    styleSheet.cssText = content;
  } else {
    style.appendChild(document.createTextNode(content));
  }
  style.type = "text/css";
  return style;
}

export function formatDate(date, format, utc) {
  if (!isDate(date)) {
    return "";
  }

  format = format || "";
  if (format !== "") {
    var MMMM = null;
    var MMM = null;
    var dddd = null;
    var ddd = null;

    try {
      MMMM = _UTILS_MESSAGES.full_monthName_list;
      MMM = _UTILS_MESSAGES.short_monthName_list;
      dddd = _UTILS_MESSAGES.full_dayName_list;
      ddd = _UTILS_MESSAGES.short_dayName_list;
    } catch (err) {
      // use fallback english
      MMMM = [
        "\x00",
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];
      MMM = [
        "\x01",
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
      ];
      dddd = [
        "\x02",
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      ddd = ["\x03", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    }

    function ii(i, len) {
      var s = i + "";
      len = len || 2;
      while (s.length < len) {
        s = "0" + s;
      }
      return s;
    }

    var y = utc ? date.getUTCFullYear() : date.getFullYear();

    format = format.replace(/(^|[^\\])%Y+/g, "$1" + y);
    format = format.replace(/(^|[^\\])%y/g, "$1" + y.toString().substr(2, 2));

    var M = (utc ? date.getUTCMonth() : date.getMonth()) + 1;
    format = format.replace(/(^|[^\\])%b+/g, "$1" + MMMM[0]);
    format = format.replace(/(^|[^\\])%B/g, "$1" + MMM[0]);
    format = format.replace(/(^|[^\\])%m/g, "$1" + ii(M));

    var d = utc ? date.getUTCDate() : date.getDate();
    format = format.replace(/(^|[^\\])%A+/g, "$1" + dddd[0]);
    format = format.replace(/(^|[^\\])%a/g, "$1" + ddd[0]);
    format = format.replace(/(^|[^\\])%w/g, "$1" + ii(d));
    format = format.replace(/(^|[^\\])%d/g, "$1" + d);

    var H = utc ? date.getUTCHours() : date.getHours();
    format = format.replace(/(^|[^\\])%H+/g, "$1" + ii(H));
    format = format.replace(/(^|[^\\])%H/g, "$1" + H);

    var h = H > 12 ? H - 12 : H == 0 ? 12 : H;
    format = format.replace(/(^|[^\\])%I+/g, "$1" + ii(h));
    format = format.replace(/(^|[^\\])%i/g, "$1" + h);

    var m = utc ? date.getUTCMinutes() : date.getMinutes();
    format = format.replace(/(^|[^\\])%M+/g, "$1" + ii(m));

    var s = utc ? date.getUTCSeconds() : date.getSeconds();
    format = format.replace(/(^|[^\\])%S+/g, "$1" + ii(s));

    var f = utc ? date.getUTCMilliseconds() : date.getMilliseconds();
    format = format.replace(/(^|[^\\])%f/g, "$1" + ii(f));
    f = Math.round(f / 10);

    var T = H < 12 ? "AM" : "PM";
    format = format.replace(/(^|[^\\])%p+/g, "$1" + T);

    var tz = -date.getTimezoneOffset();
    var K = utc || !tz ? "Z" : tz > 0 ? "+" : "-";
    if (!utc) {
      tz = Math.abs(tz);
      var tzHrs = Math.floor(tz / 60);
      var tzMin = tz % 60;
      K += ii(tzHrs) + ":" + ii(tzMin);
    }
    format = format.replace(/(^|[^\\])K/g, "$1" + K);

    var day = (utc ? date.getUTCDay() : date.getDay()) + 1;
    format = format.replace(new RegExp(dddd[0], "g"), dddd[day]);
    format = format.replace(new RegExp(ddd[0], "g"), ddd[day]);

    format = format.replace(new RegExp(MMMM[0], "g"), MMMM[M]);
    format = format.replace(new RegExp(MMM[0], "g"), MMM[M]);

    format = format.replace(/\\(.)/g, "$1");
  }

  return format;
}

export function getDateFormatFromLangMapping(langKey) {
  var h24 = Vue.onevisionCMSConfig.date_format_language_mapping["24"];
  var len24H = h24.length;
  while (len24H--) {
    if (h24[len24H] == langKey) {
      return "24";
    }
  }

  var h12 = Vue.onevisionCMSConfig.date_format_language_mapping["12"];
  var len12H = h12.length;
  while (len12H--) {
    if (h12[len12H] == langKey) {
      return "12";
    }
  }

  return Vue.onevisionCMSConfig.date_format_language_mapping["default"];
}

export function getDateFromUTCTimestamp({ utcTimeInMillis, tz }) {
  var luxonDate = DateTime.fromMillis(utcTimeInMillis * 1000, { zone: tz });
  const date = new Date();
  date.setFullYear(luxonDate.c.year);
  date.setMonth(luxonDate.c.month - 1);
  date.setDate(luxonDate.c.day);
  date.setHours(luxonDate.c.hour);
  date.setMinutes(luxonDate.c.minute);
  date.setSeconds(luxonDate.c.second);
  date.setMilliseconds(luxonDate.c.millisecond);
  return date;
}

export async function wait(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function appendQueryToManifest({ query = {} }) {
  const manifest = document.querySelector('link[rel="manifest"]');
  manifest.href = appendQuery(manifest.href, query);
}

export function appendQueryToAppleTouchIcon({ query = {} }) {
  const appleTouchIcon = document.querySelector('link[rel="apple-touch-icon"]');
  appleTouchIcon.href = appendQuery(appleTouchIcon.href, query);
}
